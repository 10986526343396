/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import {
  ALL_TEXT,
  OPEN_ALARM_TEXT,
  DEVICES_BY_ROLE_TEXT,
  TOP_ENTITIES_TEXT,
  TECHNOLOGIES_TEXT,
  GROUPS_TEXT,
  DEVICES_TEXT,
} from "./TitleBarMenu";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  Collapse,
  List, ListItemButton
} from "@mineral/core";

import Resource from "./Resource";
import EntityTypes from "./../../api/entityTypes";
import { get } from "../../utils/lodash-utils";
export default class GroupDashboardMenu extends Component {
  onMenuItemSelected = (event, isChecked) => {
    console.log(event);
    console.log(isChecked);
    let stateObject = this.props.groupDashboardComponents;
    let entityType = get(this.props.selectedEntity, "type");
    if (event.target.id && event.target.id.includes(ALL_TEXT)) {
      if (isChecked) {
        stateObject = {
          ...stateObject,
          showOpenAlarms: true,
          showDevicesByRoles: true,
          showTopEntitiesContainer: true,
          showTopTechnologies:
            entityType == EntityTypes.DYNAMIC ||
            entityType == EntityTypes.STATIC ||
            entityType == EntityTypes.AUTOMATIC,
          showTopGroups: entityType == EntityTypes.CONTAINER,
          showTopDevices: true,
        };
      } else {
        stateObject = {
          ...stateObject,
          showOpenAlarms: false,
          showDevicesByRoles: false,
          showTopEntitiesContainer: false,
          showTopTechnologies: false,
          showTopGroups: false,
          showTopDevices: false,
        };
      }
    } else if (event.target.id == OPEN_ALARM_TEXT) {
      if (isChecked) {
        stateObject = { ...stateObject, showOpenAlarms: true };
      } else {
        stateObject = { ...stateObject, showOpenAlarms: false };
      }
    } else if (event.target.id == DEVICES_BY_ROLE_TEXT) {
      if (isChecked) {
        stateObject = { ...stateObject, showDevicesByRoles: true };
      } else {
        stateObject = { ...stateObject, showDevicesByRoles: false };
      }
    } else if (event.target.id == TOP_ENTITIES_TEXT) {
      if (isChecked) {
        stateObject = {
          ...stateObject,
          showTopEntitiesContainer: true,
          showTopTechnologies:
            entityType == EntityTypes.DYNAMIC ||
            entityType == EntityTypes.STATIC ||
            entityType == EntityTypes.AUTOMATIC,
          showTopGroups: entityType == EntityTypes.CONTAINER,
          showTopDevices: true,
        };
      } else {
        stateObject = {
          ...stateObject,
          showTopEntitiesContainer: false,
          showTopTechnologies: false,
          showTopGroups: false,
          showTopDevices: false,
        };
      }
    } else if (event.target.id == TECHNOLOGIES_TEXT) {
      if (isChecked) {
        stateObject = {
          ...stateObject,
          showTopTechnologies: true,
          showTopEntitiesContainer: true,
        };
      } else {
        stateObject = {
          ...stateObject,
          showTopTechnologies: false,
          showTopEntitiesContainer:
            stateObject.showTopDevices || stateObject.showTopGroups,
        };
      }
    } else if (event.target.id == GROUPS_TEXT) {
      if (isChecked) {
        stateObject = {
          ...stateObject,
          showTopGroups: true,
          showTopEntitiesContainer: true,
        };
      } else {
        stateObject = {
          ...stateObject,
          showTopGroups: false,
          showTopEntitiesContainer:
            stateObject.showTopDevices || stateObject.showTopTechnologies,
        };
      }
    } else if (event.target.id == DEVICES_TEXT) {
      if (isChecked) {
        stateObject = {
          ...stateObject,
          showTopDevices: true,
          showTopEntitiesContainer: true,
        };
      } else {
        stateObject = {
          ...stateObject,
          showTopDevices: false,
          showTopEntitiesContainer:
            stateObject.showTopGroups || stateObject.showTopTechnologies,
        };
      }
    }

    this.props.groupDashboardComponentsChanged(stateObject);
  };
  render() {
    let entityType = get(this.props.selectedEntity, "type");
    let allMenuSelected =
      this.props.groupDashboardComponents.showOpenAlarms &&
      this.props.groupDashboardComponents.showDevicesByRoles &&
      this.props.groupDashboardComponents.showTopEntitiesContainer &&
      (entityType == EntityTypes.DYNAMIC ||
      entityType == EntityTypes.STATIC ||
      entityType == EntityTypes.AUTOMATIC
        ? this.props.groupDashboardComponents.showTopTechnologies
        : true) &&
      (entityType == EntityTypes.CONTAINER
        ? this.props.groupDashboardComponents.showTopGroups
        : true) &&
      this.props.groupDashboardComponents.showTopDevices;

    let topEntityContainer =
      this.props.groupDashboardComponents.showTopTechnologies ||
      this.props.groupDashboardComponents.showTopGroups ||
      this.props.groupDashboardComponents.showTopDevices;
    //Show hide views
    let showHideText = Resource.get("Show / Hide Views");
    return (
      <div style={{width:'200px'}}>
       <div
              className="titlebar_home_menu_subheader"
              autofocus={false}
              tabIndex={-1} style={{marginLeft:'16px'}}
            >
              {showHideText}
            </div>

        <ListItemButton component="div" className="titlebar_home_menu_item" disabled={false}  onClick={() =>
                this.onMenuItemSelected({
                  target: {
                    id: ALL_TEXT}},
                     !allMenuSelected,
                 )
              }
              style={{ padding: 0 ,marginLeft:'16px'}} role="group">
          <ListItemIcon>
            <Checkbox
              id={ALL_TEXT + "_checkbox"}
             // onChange={this.onMenuItemSelected}
              checked={allMenuSelected}
              aria-labelledby={ALL_TEXT}
              color="primary"
            />
          </ListItemIcon>
          <ListItemText primary={ALL_TEXT} id={ALL_TEXT} />
        </ListItemButton>

        <ListItemButton component="div" className="titlebar_home_menu_item" disabled={false} onClick={() =>
                this.onMenuItemSelected({
                  target: {
                    id: OPEN_ALARM_TEXT,}},
                     !this.props.groupDashboardComponents.showOpenAlarms,
                 )

              }
              style={{ padding: 0 ,marginLeft:'16px'}} role="group">
          <ListItemIcon>
            <Checkbox
              id={OPEN_ALARM_TEXT}
              //onChange={this.onMenuItemSelected}
              checked={this.props.groupDashboardComponents.showOpenAlarms}
              aria-labelledby={OPEN_ALARM_TEXT}
              color="primary"
            />
          </ListItemIcon>
          <ListItemText primary={OPEN_ALARM_TEXT} id={OPEN_ALARM_TEXT} />
        </ListItemButton>

        <ListItemButton component="div" className="titlebar_home_menu_item" disabled={false} onClick={() =>

                this.onMenuItemSelected({
                  target: {
                    id: DEVICES_BY_ROLE_TEXT,}},
                     !this.props.groupDashboardComponents.showDevicesByRoles,

                )
              }
              style={{ padding: 0 ,marginLeft:'16px'}} role="group">
          <ListItemIcon>
            <Checkbox
              //onChange={this.onMenuItemSelected}
              checked={this.props.groupDashboardComponents.showDevicesByRoles}
              aria-labelledby={DEVICES_BY_ROLE_TEXT}
              color="primary"
            />
          </ListItemIcon>
          <ListItemText primary={DEVICES_BY_ROLE_TEXT} id={DEVICES_BY_ROLE_TEXT} />
        </ListItemButton>

        <ListItemButton component="div"
          className="titlebar_home_menu_item" onClick={() =>
                this.onMenuItemSelected({
                  target: {
                    id: TOP_ENTITIES_TEXT,}},
                     !topEntityContainer,

                )
              }
              style={{ padding: 0 ,marginLeft:'16px'}} role="group"
          /* open={true}
                        primaryTogglesNestedList={false} */
        >
          <ListItemIcon>
            <Checkbox
              id={TOP_ENTITIES_TEXT}
             // onChange={this.onMenuItemSelected}
              checked={topEntityContainer}
              aria-labelledby={TOP_ENTITIES_TEXT}
              color="primary"
            />
          </ListItemIcon>
          <ListItemText primary={TOP_ENTITIES_TEXT} id={TOP_ENTITIES_TEXT} />
        </ListItemButton>
        {/* Nested list items */}
        <Collapse in={true} style={{ marginLeft: "16px", padding: "8px 0px" }}>
          <List component="div" disablePadding>
            {(entityType == EntityTypes.DYNAMIC ||
              entityType == EntityTypes.STATIC ||
              entityType == EntityTypes.AUTOMATIC) && (
              <ListItemButton component="div"
                key={TECHNOLOGIES_TEXT}
                className="titlebar_home_menu_item" onClick={() =>
                  this.onMenuItemSelected({
                  target: {
                    id: TECHNOLOGIES_TEXT,}},
                     !this.props.groupDashboardComponents.showTopTechnologies,

                )

              }
              style={{ padding: 0 ,marginLeft:'16px'}} role="group"
              >
                <ListItemIcon>
                  <Checkbox
                    //onChange={this.onMenuItemSelected}
                    checked={
                      this.props.groupDashboardComponents.showTopTechnologies
                    }
                    aria-labelledby={TECHNOLOGIES_TEXT}
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText primary={TECHNOLOGIES_TEXT} id={TECHNOLOGIES_TEXT} />
              </ListItemButton>
            )}

            {entityType == EntityTypes.CONTAINER && (
              <ListItemButton component="div" key={GROUPS_TEXT} className="titlebar_home_menu_item" onClick={() =>
               this.onMenuItemSelected({
                  target: {
                    id: GROUPS_TEXT,}},
                     !this.props.groupDashboardComponents.showTopGroups,

                )

              }
              style={{ padding: 0 ,marginLeft:'16px'}} role="group">
                <ListItemIcon>
                  <Checkbox
                    //onChange={this.onMenuItemSelected}
                    checked={this.props.groupDashboardComponents.showTopGroups}
                    aria-labelledby={GROUPS_TEXT}
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText primary={GROUPS_TEXT} id={GROUPS_TEXT} />
              </ListItemButton>
            )}

            <ListItemButton component="div" key={DEVICES_TEXT} className="titlebar_home_menu_item" onClick={() =>
            this.onMenuItemSelected({
                  target: {
                    id: DEVICES_TEXT,}},
                     !this.props.groupDashboardComponents.showTopDevices,

                )

              }
              style={{ padding: 0 ,marginLeft:'16px'}} role="group">
              <ListItemIcon>
                <Checkbox
                  //onChange={this.onMenuItemSelected}
                  checked={this.props.groupDashboardComponents.showTopDevices}
                  aria-labelledby={DEVICES_TEXT}
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText primary={DEVICES_TEXT} id={DEVICES_TEXT} />
            </ListItemButton>
          </List>
        </Collapse>
      </div>
    );
  }
}
