/*
 * Copyright © 2023 Broadcom. All rights reserved. The term "Broadcom" refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION "AS IS" WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import "./styles.less";

import * as React from "react";
import {
  MultiThemeProvider,
  AppShell,
  SideNavMenu,
  SideNavMenuItem,
  States,
  theme as lightTheme,
} from "@mineral/core";

import {
  hyperLinkInNewTab,
  hyperLinkMapper,
  iconMapper,
  notReactRouter,
  menu as MENU
} from "./helpers";
import { NavLink,Link, useLocation, } from "react-router-dom";
import { useFetchInitials } from "./hooks/useFetchInitials";
import { useNavigationListData } from "./hooks/useNavigationListData";
import { AdminConsole as AdminConsoleApi } from "./../../api/admin-console/adminConsole";
import { useICE } from '@mineral-community/ice-utils';
import * as ICESettings from './../iceSettings/ICESettingsConstants';

export const LeftNavPaneComponent = ({ children }) => {
  const [drawerClosed, setDrawerClosed] = React.useState(true);
  useFetchInitials();

  return (
    <MultiThemeProvider
      themes={{
        light: lightTheme,
        dark: lightTheme,
      }}
    >
      <AppShell
        initialState={{
          leftDrawer: {
            state: States.Closed,
            userToggled: true,
          },
        }}
        leftDrawer={<LeftDrawerContent drawerClosed={drawerClosed} />}
        onDrawerStateChange={(drawer, prevState, nextState) => {
          if (nextState.state === States.Closed) {
            setDrawerClosed(true);
          } else {
            setDrawerClosed(false);
          }
        }}
        className="LeftDrawerAppShell position"
      >
        {children}
      </AppShell>
    </MultiThemeProvider>
  );
};
 
const LeftDrawerContent = ({ drawerClosed }) => {
  const data = useNavigationListData();

  return (
    <SideNavMenu className="LeftNavPane__Container" >
      {data.map((item) => (
        <MenuListProvider item={item} drawerClosed={drawerClosed} />
      ))}
    </SideNavMenu>
  );
};

const MenuListContext = React.createContext(null);

const MenuListProvider = ({ item, drawerClosed }) => {
  const [isCurrent, setIsCurrent] = React.useState("");
  const [to, setIsTo] = React.useState("");
  const location = useLocation();

  React.useEffect(() => {
    if (!location.pathname.includes(to)) {
      setIsCurrent("");
      setIsTo("");
    }
  }, [location, to]);

  return (
    <MenuListContext.Provider
      value={{
        isCurrent,
        to,
        setIsCurrent: (path, toRoute) => {
          setIsCurrent(path);
          setIsTo(toRoute);
        },
      }}
    >
      <MenuListContainer
        item={item}
        drawerClosed={drawerClosed}
        isCurrent={isCurrent}
      />
    </MenuListContext.Provider>
  );
};

const MenuListContainer = ({ item, drawerClosed, isCurrent }) => {
  const getNavigationItem = (item, rootId) => {
    if(item.title === "Help_IPCE"){
      item.title="Help"
      item.path="Help"
      notReactRouter[item.title] = false
    }
    const IconComp = iconMapper[item.title];
    let hyperlink =
      item.type === "custompage"
      ? `${hyperLinkMapper.customPages}/${item?.meta?.dashboardId}?canvasId=${item?.meta?.dashboardId}`
        : hyperLinkMapper[item.title];
    const target = hyperLinkInNewTab[item.title] ? "_blank" : "_self";
    const notReactRouterLink = notReactRouter[item.title] ? true : false;

    if (item.isEndNode) {
      if ((item.path === MENU.ALARM_MANAGEMENT || item.path === MENU.INVENTORY_MANAGEMENT || item.path === MENU.DASHBOARDS || item.path === MENU.MYDASHBOARDS) && !isCurrent) {
        if(window.location.pathname.includes(hyperLinkMapper["Alarm Console"])) {
          isCurrent = MENU.ALARM_MANAGEMENT;
        } else if (window.location.pathname.includes('groups') || window.location.pathname.includes(hyperLinkMapper.Inventory)) {
          isCurrent = MENU.INVENTORY_MANAGEMENT;
        } else if (item.path === MENU.MYDASHBOARDS && window.location.pathname.includes("mydashboardscustompages")) {
          isCurrent = MENU.MYDASHBOARDS;
        }else if (window.location.pathname === '/operatorconsole_portlet/mydashboards') {
          isCurrent = MENU.DASHBOARDS;
        } else if (window.location.pathname.includes(hyperLinkMapper["Dashboard Viewer"])) {
          isCurrent = MENU.DASHBOARDS;
        }
      }
      const isActiveMenu = isCurrent.includes(item.path);
      return (
        <SideNavMenuLink 
          key={item.path}
          label={item.title}
          itemPath={item.path}
          {...(hyperlink ? { to: hyperlink, target, notReactRouterLink } : {})}
          icon={IconComp ? <IconComp sx={{color:'#333840'}}  /> : null}
          aria-label={item.title}
          title={item.title}
          id={item.title}
         
          highlighted={isActiveMenu}
        />
      );
    } else {
      if ((item.path === MENU.ALARM_MANAGEMENT || item.path === MENU.INVENTORY_MANAGEMENT || item.path === MENU.DASHBOARDS || item.path === MENU.MYDASHBOARDS) && !isCurrent) {
        if(window.location.pathname.includes(hyperLinkMapper["Alarm Console"])) {
          isCurrent = MENU.ALARM_MANAGEMENT;
        } else if (window.location.pathname.includes('groups') || window.location.pathname.includes(hyperLinkMapper.Inventory)) {
          isCurrent = MENU.INVENTORY_MANAGEMENT;
        } else if (item.path === MENU.MYDASHBOARDS && window.location.pathname.includes("mydashboardscustompages")) {
          isCurrent = MENU.MYDASHBOARDS;
        }else if (window.location.pathname === '/operatorconsole_portlet/mydashboards') {
          isCurrent = MENU.DASHBOARDS;
        } else if (window.location.pathname.includes(hyperLinkMapper["Dashboard Viewer"])) {
          isCurrent = MENU.DASHBOARDS;
        }
      }
      const isActiveMenu = isCurrent.includes(item.path);
      return (
        <SideNavMenuItem 
          key={item.path}
          label={item.title}
          title={item.title}
          icon={IconComp ? <IconComp sx={{color:'#333840'}}  /> : null}
          id={rootId}
          AccordionProps={{
            defaultExpanded: false,
          }}
          ariaLabel={item.title}
        
          
          highlighted={isActiveMenu}
        >
          {drawerClosed
            ? null
            : Array.isArray(item.children)
            ? item.children.map((nestedItem) =>
                getNavigationItem(nestedItem, rootId)
              )
            : null}
        </SideNavMenuItem>
      );
    }
  };

  return getNavigationItem(item, new Date().getTime());
};

const launchAdminconsoleWithPost = async () => {
  const adminconsoleUrl = await getAdminConsoleURL();
  if (adminconsoleUrl) {
    const appForm = document.createElement("form");
    appForm.setAttribute("style", "");
    appForm.style.display = "none";
    appForm.method = "POST";
    const urlWithParams = adminconsoleUrl;
    const baseUrl = urlWithParams.split("?");
    const params = baseUrl[1];
    appForm.action = baseUrl[0];
    appForm.target = "_blank";
    const paramArr = params.split("&");
    const userParams = paramArr[0].split("=");
    const passParams = paramArr[1].split("=");

    const userInput = document.createElement("input");
    userInput.type = "text";
    userInput.name = userParams[0];
    userInput.value = decodeURIComponent(userParams[1]);
    appForm.appendChild(userInput);

    const passInput = document.createElement("input");
    passInput.type = "text";
    passInput.name = passParams[0];
    passInput.value = decodeURIComponent(passParams[1]);
    appForm.appendChild(passInput);
    document.body.appendChild(appForm);
    appForm.submit();
  }
};

const getAdminConsoleURL = () => {
  return new Promise((resolve, reject) => {
    AdminConsoleApi.getAdminConsoleURL()
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
};


const HelpIcon = ({ id, className }) => {
  const isDev = process.env.NODE_ENV === 'development';
  const iceKey = isDev
    ? ICESettings.ICE_ONPREM_DEVTEST_KEY
    : ICESettings.ICE_ONPREM_PROD_KEY;
  const ice = useICE(iceKey);
  if (
    document.getElementById('ipce-surface') &&
    sessionStorage.getItem('IPCE_PATH') !== window.location.pathname?.split('/').at(-1)
  ) {
    window?.IPCE?.open();
  }
  sessionStorage.setItem('IPCE_PATH', window.location.pathname?.split('/').at(-1));
  function handleClick() {
    //sessionStorage.setItem('IPCE_OPEN', true);
    if (window?.IPCE?.IPCE_PRODUCT_CONTEXT_NAME === '') {
      ice?.init?.(
        {
          name: ICESettings.ICE_IDENTIFIER,
          displayName: ICESettings.ICE_PRODUCT_DISPLAYNAME,
          version: ICESettings.ICE_VERSION
        },
        {},
        { mode: 'off' }
      );
      window?.IPCE?.open();
    } else {
      window?.IPCE?.open();
    }
  }

  return (
    <button
      id='helpIconIce'
      onClick={() => handleClick()}
      className={className}
     
      style={{
        border: 0,
        padding: 0,
        width: '100%',
        background: 'transparent',
        cursor: 'pointer'
      }}>
      {id}
    </button>
  );
};

const BaseNavLink = React.forwardRef(
  (
    { className, notReactRouterLink, to, children, itemPath, ...restProps },
    ref
  ) => {
    const location = useLocation();
    const ctx = React.useContext(MenuListContext);
    const isCurrentRoute = location.pathname.includes(to);

    if (!to) {
      to = "/";
    }

    if (isCurrentRoute) {
      ctx.setIsCurrent(itemPath, to);
    }

    if (to === "/adminconsoleapp") {
      return (
        <button
          onClick={launchAdminconsoleWithPost}
          className={className}
          role="link"
          style={{
            border: 0,
            padding: 0,
            width: "100%",
            background: "transparent",
            cursor: 'pointer'
          }}
        >
          {children}
        </button>
      );
    }

    if (itemPath === "Help" & !notReactRouterLink) {
      return <HelpIcon id={children} className={className}></HelpIcon>;
    }

    if (notReactRouterLink) {
      return (
        <div   ref={ref} to={to}>
        <Link {...restProps} aria-label={restProps.label} to={to}  href={to}>
          {children}
        </Link>
        </div>
      );
    }
    return (
      <div {...restProps}  ref={ref} to={to}>
      <NavLink  aria-label={restProps.label} className={className} to={to} role="link">{children}</NavLink>
        
      </div>
    );
  }
);

const SideNavMenuLink = (props) => (
  <SideNavMenuItem component={BaseNavLink} {...props} />
);
