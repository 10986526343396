/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { LineChart, Line, Tooltip } from "recharts";
import "./table.less";
import { Grid, AlarmsIcon } from "../ui-components/uim-components";
import Resource from "./Resource";
import { sortColumns } from "../../utils/tableUtils";
import { RowSelectionCache as selectionCacheApi } from "../../utils/rowSelectionCache";
import { changeInterfacesCount, fetchMetrics } from "./../../api/interfaces/actions";
import UtilPoller from "./../../utils/poller";
import connect from "../../utils/connect";
import { setSelectedIfIds } from "../../api/groups/actions";
import { isEqual, each, differenceWith } from "../../utils/lodash-utils";
import SeverityIcon from "./../icons/SeverityIcon";
import NameColumnCellRendererInterfacesTable from "./NameColumnCellRendererInterfacesTable";
import ReactDOM from "react-dom";

const KBPS = 1000;
const MBPS = KBPS * 1000;
const GBPS = MBPS * 1000;
let tabForward;
let tabBackward;
function getAallFocussableElementsOf(el) {
  return Array.from(
    el.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
  ).filter((el) => {
    return el.tabIndex !== -1;
  });
}
const CustomTooltip = ({ active, payload, label }) => {
  if (!active) return null;
  return (
    <div
      style={{
        position: "relative",
        width: "230px",
        marginBottom: "20px",
        marginTop: "-27px",
        border: "1px solid #f5f5f5",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        paddingBottom: "20px",
      }}
    >
      {payload && payload.length > 0 ? (
        <div style={{position: 'relative', top: "10px"}}>
          {payload[0].value !== null ? (
            <span style={{ margin: "0", color: "#8884d8", fontSize: "12px" }}>
              {`${payload[0].name} : ${payload[0].value}`}{" "}
            </span>
          ) : null}
          {payload[0].value !== null ? <br /> : null}
          {payload[1].value !== null ? (
            <span
              style={{ margin: "0", color: "#4CAF50", fontSize: "12px" }}
            >{`${payload[1].name} : ${payload[1].value}`}</span>
          ) : null}
          {payload[1].value !== null ? <br /> : null}
          {payload[2].value !== null ? (
            <span
              style={{ margin: "0", color: "#EB144C", fontSize: "12px" }}
            >{`${payload[2].name} : ${payload[2].value}`}</span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const isArrayEqual = function(x, y) {

  if(x?.length!==y?.length)
    return false;
  else
  {
  let resultingArrayDiff=x?.length>0? differenceWith(x,y, isEqual): differenceWith(y,x, isEqual);
  //console.log("_.differenceWith(x,y, isEqual)"+resultingArrayDiff);
  return resultingArrayDiff?.length===0;
  }
};

class InterfaceTable extends Component {
  constructor(props) {
    super(props);
    this.pageLimit = 15;
    this.numPages = 0;
    this.groupPage = 0;
    this.updateRowSelectionCache = true;
    this.severityFocusRef = React.createRef();
    this.state = {
      sortedData: this.props.interfaceData,
      pageNumber: 0,
      showDialog: false,
      device: null,
      tooltipShowDelay: 0,
      overlayNoRowsDisplay:
        '<div><div class="nohubs__contentImg"></div><span style="color: #333333;font-family: Roboto;font-size: 1.25rem;">No Data found.</span></div>',
      metricData: {},
      metricMap: new Map(),
      grpMasterMap: new Map(),
      entityType: this.props.selectedEntityType,
    };
    // this.state = {
    //   metricData: {},
    //   metricMap: new Map(),
    //   grpMasterMap: new Map(),
    //   entityType: this.props.selectedEntityType,
    // };
    // console.log("this.props.selectedEntity", this.props.selectedEntity);
  }
//Severity
 severity= Resource.get("Severity");
//Name
 name= Resource.get("Name");
//Information
 information = Resource.get("Information");
//Device
device= Resource.get("Device");
 //Utilization %
 utilization = Resource.get("Utilization %");
 //Errors %
 errors = Resource.get("Errors %");
 //Discards %
 discards = Resource.get("Discards %");
 //Speed
 speed= Resource.get("Speed");
 //Index
 index= Resource.get("Index");
 //MAC
 mac = Resource.get("MAC");
 //Admin Status
 adminStatus= Resource.get("Admin Status");
 //Oper Status
 operStatus= Resource.get("Oper Status");
 //Type
 type= Resource.get("Type");
 //MTU
 mtu= Resource.get("MTU");
 //Description
 description= Resource.get("Description");
 //IfName
 ifName= Resource.get("IfName");

  getCols = () => {
    let groupCols = [
      {
        headerName: this.severity,
        field: "severity",
        headerTooltip: this.severity,
        tooltipField: "severity",
        headerTooltip: this.severity,
        cellRendererFramework: this.addAlarmIconToData,
        suppressMovable: true,
        suppressResize: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        // suppressSizeToFit: true,
        sortable: true,
        headerComponentParams: {
          // Max Alarm Severity
          icon: <AlarmsIcon role='figure' title={Resource.get("Max Alarm Severity")} />,
        },
      },
      {
        headerName: this.name,
        field: "displayName",
        resizable:true,
        tooltipField: "displayName",
        headerTooltip: this.name,
        suppressMovable: true,
        suppressResize: false,
        //   width: 200,
         minWidth: 200,
        flex: 1,
        cellRendererFramework: NameColumnCellRendererInterfacesTable,
       /* cellRendererFramework: (params) => {
          return (
            <>
            <div className="information__icon--dialog" style={{margin: '0 8px 0 0', }}>
            <GroupInfoIcon role="figure" title= {this.information} />
            </div>
            <p style={{margin: 0, whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
              {params.data.displayName ? params.data.displayName : ''}
            </p>
            </>
            )
          }, */
      },
      {
        headerName: this.alias,
        field: "displayAlias",
        //cellRendererFramework: this.addAlarmIconToData,
        tooltipField: "displayAlias",
        headerTooltip:this.alias,
        suppressMovable: true,
        suppressResize: false,
        resizable:true,
        //width: 200,
        minWidth: 150,
        flex: 0.9,
        suppressSizeToFit: false,
        cellRendererFramework: params => params.data.displayAlias
      },
      {
        headerName: this.device,
        field: "parent_displayName",
        resizable:true,
        tooltipField: "parent_displayName",
        headerTooltip:this.device,
        suppressMovable: true,
        suppressResize: false,
        minWidth: 200,
        flex: 1,
        suppressSizeToFit: false,
        cellRendererFramework: params => params.data.parent_displayName
      },
      {
        headerName: this.utilization,
        field: "masterId",
        resizable:true,
        cellRendererFramework: this.metricDataCellRenderer,
        //tooltipField: "masterId",
        headerTooltip:this.utilization,
        suppressMovable: true,
        suppressResize: false,
        minWidth: 130,
        flex: 1,
        suppressSizeToFit: false,
        equals: function (masterId1, masterId2) {
          return false;
        },
      },
      {
        headerName: this.errors,
        field: "Errors",
        resizable:true,
        cellRendererFramework: this.metricDataCellRenderer,
        //tooltipField: "Errors",
        headerTooltip: this.errors,
        suppressMovable: true,
        suppressResize: false,
        minWidth: 130,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: this.discards,
        field: "Discards",
        resizable:true,
        cellRendererFramework: this.metricDataCellRenderer,
        //tooltipField: "Discards",
        headerTooltip:this.discards,
        suppressMovable: true,
        suppressResize: false,
        minWidth: 130,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: this.speed,
        field: "ifSpeed",
        resizable:true,
        cellRendererFramework: this.speedDataCellRenderer,
        tooltipField: "ifSpeed",
        headerTooltip:this.speed,
        //suppressMovable: true,
        //suppressResize: false,
        minWidth: 70,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: this.index,
        field: "ifIndex",
        resizable:true,
        cellRendererFramework: params => params.data.ifIndex,
        tooltipField: "ifIndex",
        headerTooltip:this.index,
        minWidth: 70,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: this.mac,
        field: "ifPhysAddress",
        resizable:true,
        cellRendererFramework: params => params.data.ifPhysAddress,
        tooltipField: "ifPhysAddress",
        headerTooltip:this.mac,
        minWidth: 130,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: this.adminStatus,
        field: "ifAdminStatus",
        resizable:true,
        cellRendererFramework: (params) => {
          return (
            <>
              <p style={{margin: 0, whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                {this.getadminStatus(params.data.ifAdminStatus)}
              </p>
            </>
          )
        },
        tooltipField: "ifAdminStatus",
        headerTooltip:this.adminStatus,
        minWidth: 125,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: this.operStatus,
        field: "ifOperStatus",
        resizable:true,
        cellRendererFramework: (params) => {
          return (
            <>
              <p style={{margin: 0, whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                {this.getOperStatus(params.data.ifOperStatus)}
              </p>
            </>
            )
          },
        tooltipField: "ifOperStatus",
        headerTooltip:this.operStatus,
        minWidth: 120,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: this.type,
        field: "ifType",
        minWidth: 130,
        flex: 1,
        resizable:true,
        cellRendererFramework: (params) => {
          return (
            <>
              <p style={{margin: 0, whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                {this.getType(params.data.ifType)}
              </p>
            </>
          )
        },
        tooltipField: "ifType",
        headerTooltip:this.type,
        suppressSizeToFit: false,
      },
      {
        headerName: this.mtu,
        field: "ifMtu",
        minWidth: 70,
        flex: 1,
        resizable:true,
        cellRendererFramework: params => params.data.ifMtu,
        tooltipField: "ifMtu",
        headerTooltip:this.mtu,
        suppressSizeToFit: false,
      },
      {
        headerName: this.description,
        field: "ifDescr",
        resizable:true,
        cellRendererFramework: params => params.data.ifDescr,
        tooltipField: "ifDescr",
        headerTooltip:this.description,
        minWidth: 130,
        flex: 1,
        suppressSizeToFit: false,
      },
      {
        headerName: this.ifName,
        field: "ifName",
        minWidth: 130,
        flex: 1,
        resizable:true,
        cellRendererFramework: params => params.data.ifName,
        tooltipField: "ifName",
        headerTooltip:this.ifName,
        suppressSizeToFit: false,
      },
    ];
    if (this.props.selectedEntityType == "STATIC") {
      groupCols = [
        // {
        //   headerName: "",
        //   field: "checkbox",
        //   checkboxSelection: true,
        //   headerCheckboxSelection: true,
        //   suppressMenu: true,
        //   suppressSorting: true,
        //   suppressResize: true,
        //   suppressSizeToFit: true,
        //   width: 50,
        // },
        ...groupCols,
      ];
    }
    return groupCols;
  };

  getadminStatus = (adminStatusId) => {
    let adminStatus = '';
    if(adminStatusId === '0')
      adminStatus = 'Unknown';
    else if(adminStatusId === '1')
      adminStatus = 'Up';
    else if(adminStatusId === '2')
      adminStatus = 'Down';
    else if(adminStatusId === '3')
      adminStatus = 'Testing';

    return adminStatus;
  }

  getOperStatus = (operStatusId) => {
    let operStatus = '';
    if(operStatusId === '1')
      operStatus = 'Up';
    else if(operStatusId === '2')
      operStatus = 'Down';
    else if(operStatusId === '3')
      operStatus = 'Testing';
    else if(operStatusId === '4')
      operStatus = 'Unknown';
    else if(operStatusId === '5')
      operStatus = 'Dormant';
    else if(operStatusId === '6')
      operStatus = 'Not Present';
    else if(operStatusId === '7')
      operStatus = 'Lower Layer Down';

    return operStatus;

  }

  getType = (typeId) => {
    let typeVal = '';
    switch(typeId) {
      case '1':
      typeVal = "other";
      break;
    case '2':
      typeVal = "regular1822";
      break;
    case '3':
      typeVal = "hdh1822";
      break;
    case '4':
      typeVal = "ddnX25";
      break;
    case '5':
      typeVal = "rfc877x25";
      break;
    case '6':
      typeVal = "ethernetCsmacd";
      break;
    case '7':
      typeVal = "iso88023Csmacd";
      break;
    case '8':
      typeVal = "iso88024TokenBus";
      break;
    case '9':
      typeVal = "iso88025TokenRing";
      break;
    case '10':
      typeVal = "iso88026Man";
      break;
    case '11':
      typeVal = "starLan";
      break;
    case '12':
      typeVal = "proteon10Mbit";
      break;
    case '13':
      typeVal = "proteon80Mbit";
      break;
    case '14':
      typeVal = "hyperchannel";
      break;
    case '15':
      typeVal = "fddi";
      break;
    case '16':
      typeVal = "lapb";
      break;
    case '17':
      typeVal = "sdlc";
      break;
    case '18':
      typeVal = "ds1";
      break;
    case '19':
      typeVal = "e1";
      break;
    case '20':
      typeVal = "basicISDN";
      break;
    case '21':
      typeVal = "primaryISDN";
      break;
    case '22':
      typeVal = "propPointToPointSerial";
      break;
    case '23':
      typeVal = "ppp";
      break;
    case '24':
      typeVal = "softwareLoopback";
      break;
    case '25':
      typeVal = "eon";
      break;
    case '26':
      typeVal = "ethernet3Mbit";
      break;
    case '27':
      typeVal = "nsip";
      break;
    case '28':
      typeVal = "slip";
      break;
    case '29':
      typeVal = "ultra";
      break;
    case '30':
      typeVal = "ds3";
      break;
    case '31':
      typeVal = "sip";
      break;
    case '32':
      typeVal = "frameRelay";
      break;
    case '33':
      typeVal = "rs232";
      break;
    case '34':
      typeVal = "para";
      break;
    case '35':
      typeVal = "arcnet";
      break;
    case '36':
      typeVal = "arcnetPlus";
      break;
    case '37':
      typeVal = "atm";
      break;
    case '38':
      typeVal = "miox25";
      break;
    case '39':
      typeVal = "sonet";
      break;
    case '40':
      typeVal = "x25ple";
      break;
    case '41':
      typeVal = "iso88022llc";
      break;
    case '42':
      typeVal = "localTalk";
      break;
    case '43':
      typeVal = "smdsDxi";
      break;
    case '44':
      typeVal = "frameRelayService";
      break;
    case '45':
      typeVal = "v35";
      break;
    case '46':
      typeVal = "hssi";
      break;
    case '47':
      typeVal = "hippi";
      break;
    case '48':
      typeVal = "modem";
      break;
    case '49':
      typeVal = "aal5";
      break;
    case '50':
      typeVal = "sonetPath";
      break;
    case '51':
      typeVal = "sonetVT";
      break;
    case '52':
      typeVal = "smdsIcip";
      break;
    case '53':
      typeVal = "propVirtual";
      break;
    case '54':
      typeVal = "propMultiplexor";
      break;
    case '55':
      typeVal = "ieee80212";
      break;
    case '56':
      typeVal = "fibreChannel";
      break;
    case '57':
      typeVal = "hippiInterface";
      break;
    case '58':
      typeVal = "frameRelayInterconnect";
      break;
    case '59':
      typeVal = "aflane8023";
      break;
    case '60':
      typeVal = "aflane8025";
      break;
    case '61':
      typeVal = "cctEmul";
      break;
    case '62':
      typeVal = "fastEther";
      break;
    case '63':
      typeVal = "isdn";
      break;
    case '64':
      typeVal = "v11";
      break;
    case '65':
      typeVal = "v36";
      break;
    case '66':
      typeVal = "g703at64k";
      break;
    case '67':
      typeVal = "g703at2mb";
      break;
    case '68':
      typeVal = "qllc";
      break;
    case '69':
      typeVal = "fastEtherFX";
      break;
    case '70':
      typeVal = "channel";
      break;
    case '71':
      typeVal = "ieee80211";
      break;
    case '72':
      typeVal = "ibm370parChan";
      break;
    case '73':
      typeVal = "escon";
      break;
    case '74':
      typeVal = "dlsw";
      break;
    case '75':
      typeVal = "isdns";
      break;
    case '76':
      typeVal = "isdnu";
      break;
    case '77':
      typeVal = "lapd";
      break;
    case '78':
      typeVal = "ipSwitch";
      break;
    case '79':
      typeVal = "rsrb";
      break;
    case '80':
      typeVal = "atmLogical";
      break;
    case '81':
      typeVal = "ds0";
      break;
    case '82':
      typeVal = "ds0Bundle";
      break;
    case '83':
      typeVal = "bsc";
      break;
    case '84':
      typeVal = "async";
      break;
    case '85':
      typeVal = "cnr";
      break;
    case '86':
      typeVal = "iso88025Dtr";
      break;
    case '87':
      typeVal = "eplrs";
      break;
    case '88':
      typeVal = "arap";
      break;
    case '89':
      typeVal = "propCnls";
      break;
    case '90':
      typeVal = "hostPad";
      break;
    case '91':
      typeVal = "termPad";
      break;
    case '92':
      typeVal = "frameRelayMPI";
      break;
    case '93':
      typeVal = "x213";
      break;
    case '94':
      typeVal = "adsl";
      break;
    case '95':
      typeVal = "radsl";
      break;
    case '96':
      typeVal = "sdsl";
      break;
    case '97':
      typeVal = "vdsl";
      break;
    case '98':
      typeVal = "iso88025CRFPInt";
      break;
    case '99':
      typeVal = "myrinet";
      break;
    case '100':
      typeVal = "voiceEM";
      break;
    case '101':
      typeVal = "voiceFXO";
      break;
    case '102':
      typeVal = "voiceFXS";
      break;
    case '103':
      typeVal = "voiceEncap";
      break;
    case '104':
      typeVal = "voiceOverIp";
      break;
    case '105':
      typeVal = "atmDxi";
      break;
    case '106':
      typeVal = "atmFuni";
      break;
    case '107':
      typeVal = "atmIma";
      break;
    case '108':
      typeVal = "pppMultilinkBundle";
      break;
    case '109':
      typeVal = "ipOverCdlc";
      break;
    case '110':
      typeVal = "ipOverClaw";
      break;
    case '111':
      typeVal = "stackToStack";
      break;
    case '112':
      typeVal = "virtualIpAddress";
      break;
    case '113':
      typeVal = "mpc";
      break;
    case '114':
      typeVal = "ipOverAtm";
      break;
    case '115':
      typeVal = "iso88025Fiber";
      break;
    case '116':
      typeVal = "tdlc";
      break;
    case '117':
      typeVal = "gigabitEthernet";
      break;
    case '118':
      typeVal = "hdlc";
      break;
    case '119':
      typeVal = "lapf";
      break;
    case '120':
      typeVal = "v37";
      break;
    case '121':
      typeVal = "x25mlp";
      break;
    case '122':
      typeVal = "x25huntGroup";
      break;
    case '123':
      typeVal = "transpHdlc";
      break;
    case '124':
      typeVal = "interleave";
      break;
    case '125':
      typeVal = "fast";
      break;
    case '126':
      typeVal = "ip";
      break;
    case '127':
      typeVal = "docsCableMaclayer";
      break;
    case '128':
      typeVal = "docsCableDownstream";
      break;
    case '129':
      typeVal = "docsCableUpstream";
      break;
    case '130':
      typeVal = "a12MppSwitch";
      break;
    case '131':
      typeVal = "tunnel";
      break;
    case '132':
      typeVal = "coffee";
      break;
    case '133':
      typeVal = "ces";
      break;
    case '134':
      typeVal = "atmSubInterface";
      break;
    case '135':
      typeVal = "l2vlan";
      break;
    case '136':
      typeVal = "l3ipvlan";
      break;
    case '137':
      typeVal = "l3ipxvlan";
      break;
    case '138':
      typeVal = "digitalPowerline";
      break;
    case '139':
      typeVal = "mediaMailOverIp";
      break;
    case '140':
      typeVal = "dtm";
      break;
    case '141':
      typeVal = "dcn";
      break;
    case '142':
      typeVal = "ipForward";
      break;
    case '143':
      typeVal = "msdsl";
      break;
    case '144':
      typeVal = "ieee1394";
      break;
    case '145':
      typeVal = "if_gsn";
      break;
    case '146':
      typeVal = "dvbRccMacLayer";
      break;
    case '147':
      typeVal = "dvbRccDownstream";
      break;
    case '148':
      typeVal = "dvbRccUpstream";
      break;
    case '149':
      typeVal = "atmVirtual";
      break;
    case '150':
      typeVal = "mplsTunnel";
      break;
    case '151':
      typeVal = "srp";
      break;
    case '152':
      typeVal = "voiceOverAtm";
      break;
    case '153':
      typeVal = "voiceOverFrameRelay";
      break;
    case '154':
      typeVal = "idsl";
      break;
    case '155':
      typeVal = "compositeLink";
      break;
    case '156':
      typeVal = "ss7SigLink";
      break;
    case '157':
      typeVal = "propWirelessP2P";
      break;
    case '158':
      typeVal = "frForward";
      break;
    case '159':
      typeVal = "rfc1483";
      break;
    case '160':
      typeVal = "usb";
      break;
    case '161':
      typeVal = "ieee8023adLag";
      break;
    case '162':
      typeVal = "bgppolicyaccounting";
      break;
    case '163':
      typeVal = "frf16MfrBundle";
      break;
    case '164':
      typeVal = "h323Gatekeeper";
      break;
    case '165':
      typeVal = "h323Proxy";
      break;
    case '166':
      typeVal = "mpls";
      break;
    case '167':
      typeVal = "mfSigLink";
      break;
    case '168':
      typeVal = "hdsl2";
      break;
    case '169':
      typeVal = "shdsl";
      break;
    case '170':
      typeVal = "ds1FDL";
      break;
    case '171':
      typeVal = "pos";
      break;
    case '172':
      typeVal = "dvbAsiIn";
      break;
    case '173':
      typeVal = "dvbAsiOut";
      break;
    case '174':
      typeVal = "plc";
      break;
    case '175':
      typeVal = "nfas";
      break;
    case '176':
      typeVal = "tr008";
      break;
    case '177':
      typeVal = "gr303RDT";
      break;
    case '178':
      typeVal = "gr303IDT";
      break;
    case '179':
      typeVal = "isup";
      break;
    case '180':
      typeVal = "propDocsWirelessMaclayer";
      break;
    case '181':
      typeVal = "propDocsWirelessDownstream";
      break;
    case '182':
      typeVal = "propDocsWirelessUpstream";
      break;
    case '183':
      typeVal = "hiperlan2";
      break;
    case '184':
      typeVal = "propBWAp2Mp";
      break;
    case '185':
      typeVal = "sonetOverheadChannel";
      break;
    case '186':
      typeVal = "digitalWrapperOverheadChannel";
      break;
    case '187':
      typeVal = "aal2";
      break;
    case '188':
      typeVal = "radioMAC";
      break;
    case '189':
      typeVal = "atmRadio";
      break;
    case '190':
      typeVal = "imt";
      break;
    case '191':
      typeVal = "mvl";
      break;
    case '192':
      typeVal = "reachDSL";
      break;
    case '193':
      typeVal = "frDlciEndPt";
      break;
    case '194':
      typeVal = "atmVciEndPt";
      break;
    case '195':
      typeVal = "opticalChannel";
      break;
    case '196':
      typeVal = "opticalTransport";
      break;
    case '197':
      typeVal = "propAtm";
      break;
    case '198':
      typeVal = "voiceOverCable";
      break;
    case '199':
      typeVal = "infiniband";
      break;
    case '200':
      typeVal = "teLink";
      break;
    case '201':
      typeVal = "q2931";
      break;
    case '202':
      typeVal = "virtualTg";
      break;
    case '203':
      typeVal = "sipTg";
      break;
    case '204':
      typeVal = "sipSig";
      break;
    case '205':
      typeVal = "docsCableUpstreamChannel";
      break;
    case '206':
      typeVal = "econet";
      break;
    case '207':
      typeVal = "pon155";
      break;
    case '208':
      typeVal = "pon622";
      break;
    case '209':
      typeVal = "bridge";
      break;
    case '210':
      typeVal = "linegroup";
      break;
    case '211':
      typeVal = "voiceEMFGD";
      break;
    case '212':
      typeVal = "voiceFGDEANA";
      break;
    case '213':
      typeVal = "voiceDID";
      break;
    case '214':
      typeVal = "mpegTransport";
      break;
    case '215':
      typeVal = "sixToFour";
      break;
    case '216':
      typeVal = "gtp";
      break;
    case '217':
      typeVal = "pdnEtherLoop1";
      break;
    case '218':
      typeVal = "pdnEtherLoop2";
      break;
    case '219':
      typeVal = "opticalChannelGroup";
      break;
    case '220':
      typeVal = "homepna";
      break;
    case '221':
      typeVal = "gfp";
      break;
    case '222':
      typeVal = "ciscoISLvlan";
      break;
    case '223':
      typeVal = "actelisMetaLOOP";
      break;
    case '224':
      typeVal = "fcipLink";
      break;
    case '225':
      typeVal = "rpr";
      break;
    case '226':
      typeVal = "qam";
      break;
    case '227':
      typeVal = "lmp";
      break;
    case '228':
      typeVal = "cblVectaStar";
      break;
    case '229':
      typeVal = "docsCableMCmtsDownstream";
      break;
    case '230':
      typeVal = "adsl2";
      break;
    case '231':
      typeVal = "macSecControlledIF";
      break;
    case '232':
      typeVal = "macSecUncontrolledIF";
      break;
    case '233':
      typeVal = "aviciOpticalEther";
      break;
    case '234':
      typeVal = "atmbond";
      break;
    case '235':
      typeVal = "voiceFGDOS";
      break;
    case '236':
      typeVal = "mocaVersion1";
      break;
    case '237':
      typeVal = "ieee80216WMAN";
      break;
    case '238':
      typeVal = "adsl2plus";
      break;
    case '239':
      typeVal = "dvbRcsMacLayer";
      break;
    case '240':
      typeVal = "dvbTdm";
      break;
    case '241':
      typeVal = "dvbRcsTdma";
      break;
    case '242':
      typeVal = "x86Laps";
      break;
    case '243':
      typeVal = "wwanPP";
      break;
    case '244':
      typeVal = "wwanPP2";
      break;
    case '245':
      typeVal = "voiceEBS";
      break;
    case '246':
      typeVal = "ifPwType";
      break;
    case '247':
      typeVal = "ilan";
      break;
    case '248':
      typeVal = "pip";
      break;
    case '249':
      typeVal = "aluELP";
      break;
    case '250':
      typeVal = "gpon";
      break;
    case '251':
      typeVal = "vdsl2";
      break;
    default:
      typeVal = "";

    }
    return typeVal;
  }

  getOriginList = (model) => {
    var origins = model.data.origins;

    return <div>{origins.origin}</div>;
  };
  addAlarmIconToData = (model) => {
    var item = model.data;
    let maxVal =
      item.severity && item.severity
        ? item.severity
        : 0;
    return (
      <div className="alarm__icon-link" ref={this.severityFocusRef}>
        <SeverityIcon role='figure' level={maxVal} class="grid__icon" />
      </div>
    );
  };
  metricDataCellRenderer = (model) => {
    var masterId = model.data.masterId;
    let colName = model.colDef.headerName;
    var grpMasterMap = this.state.grpMasterMap;
    var metricMap =
      grpMasterMap && grpMasterMap.has(masterId)
        ? grpMasterMap.get(masterId)
        : new Map();
    let data = [];
    let key = "";
    if (metricMap.size > 0) {
      if (colName === "Utilization %") {
        key = "Utilization";
      } else if (colName === "Errors %") {
        key = "Errors";
      } else if (colName === "Discards %") {
        key = "Discards";
      }
      data = metricMap.has(key) ? metricMap.get(key) : [];
      return (
        <LineChart width={100} height={45} data={data}>
          <Line
            type="monotone"
            dataKey={key}
            stroke="#8884d8"
            strokeWidth={1}
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey={key + "In"} stroke="#4CAF50" />
          <Line type="monotone" dataKey={key + "Out"} stroke="#EB144C" />
          <Tooltip content={<CustomTooltip />} />
        </LineChart>
      );
    } else {
      return (
        <LineChart width={100} height={46} data={data}>
          <Line
            type="monotone"
            dataKey="util"
            stroke="#8884d8"
            strokeWidth={1}
            activeDot={{ r: 8 }}
          />

          <Tooltip />
        </LineChart>
      );
    }
  };
  speedDataCellRenderer = (model) => {
    var masterId = model.data.masterId;
    let colName = model.colDef.headerName;
    let value = model.data.ifSpeed;
    if (value == null || isNaN(value) || value === 0) {
      return null;
    }

    if (value >= GBPS) {
      value = value / GBPS;
      //field value of interface speed -- {0}: speed in gigabits per second
      return <span>{parseFloat(value).toFixed(2)} Gbps</span>;
    } else if (value >= MBPS) {
      value = value / MBPS;
      //field value of interface speed -- {0}: speed in megabits per second
      return <span>{parseFloat(value).toFixed(2)} Mbps</span>;
    } else if (value >= KBPS) {
      value = value / KBPS;
      //field value of interface speed -- {0}: speed in kilobits per second
      return <span>{parseFloat(value).toFixed(2)} Kbps</span>;
    } else {
      //field value of interface speed -- {0}: speed in bits per second
      return <span>{parseFloat(value).toFixed(2)} bps</span>;
    }
  };

  componentDidMount() {
    window.addEventListener("mouseover", (e) => this.mouseOverClassAddition(e));
    window.addEventListener("mouseout", (e) => this.mouseOutClassDeletion(e));
    window.addEventListener("beforeunload", this.componentCleanup);
  }
  poll = (params) => {
    if (this.poller) {
      this.poller.stop();
    }
    this.poller = UtilPoller(() => this.props.pollMetricData(params), {
      pollFailure: (e) => {
        if (e.response && e.response.status === 401) {
          this.poller.stop();
          this.props.history.push("401");
        }
      },
      timeout: 30 * 1000,
    });
    this.poller.start();
  };

  componentDidUpdate(prevProps, prevState) {
    //     if (!isEqual(prevProps.interfaceData, this.state.sortedData)) {
    //       this.setState({
    //         sortedData: prevProps.interfaceData,
    //       });
    //     }
    // if (prevProps.columnDefs !== this.props.columnDefs) {
    //   if (this.api) {
    //     // Added condition for DE457904
    //     this.api.sizeColumnsToFit();
    //   }
    // }
    if (!isEqual(this.state.sortedData, prevState.sortedData)) {
      let rowData = this.state.sortedData ? this.state.sortedData.iface : [];
      changeInterfacesCount(rowData === null || rowData === undefined ? 0 : rowData.length)
    }
    if (
      this.api &&
      prevProps.visibleDataCount !== this.api.rowModel.rowsToDisplay.length &&
      this.props.handleColumnFilterChange
    ) {
      this.props.handleColumnFilterChange(
        this.api.rowModel.rowsToDisplay.length
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(!isEqual(nextProps.fromGroupsView, this.props.fromGroupsView)) {
      return true;
    }
    if(!isArrayEqual(nextProps.groupData, this.props.groupData)) {
      return true;
    }
    if(
      (nextProps.interfaceData && this.props.interfaceData) &&
      (!isArrayEqual(nextProps.interfaceData.iface, this.props.interfaceData.iface))
    ) {
      return true;
    }
    if(!isEqual(nextProps.isTreeOpen, this.props.isTreeOpen)) {
      return true;
    }
    if (!this.state.metricData && nextState.metricData) {
      return true;
    } else if(
      (nextState.metricData && this.state.metricData) &&
      (!isArrayEqual(nextState.metricData.metric, this.state.metricData.metric)
    )) {
      return true;
    }
    if(!isEqual(nextProps.selectedEntityType, this.props.selectedEntityType)) {
      return true;
    }
    if(!isEqual(nextProps.showInterfaceDetail, this.props.showInterfaceDetail)) {
      return true;
    }
    if(Object.keys(this.props.interfaceData).length === 0) {
      return true
    }
    return false;
  }

  componentCleanup = (onactualUnmount) => {
    selectionCacheApi.clear();
    if (this.props.handleIfRowsSelected) {
      const ids = selectionCacheApi.getData();
      this.props.handleIfRowsSelected(ids);
    }
    window.removeEventListener("beforeunload", this.componentCleanup);
    if (!onactualUnmount) {
      let mountNode = ReactDOM.findDOMNode(this.refs.interfaceRef);
      if (mountNode) {
        let unmount = ReactDOM.unmountComponentAtNode(mountNode);
      }
    }
  };
  updateMetricMap = (metricData) => {
    let metricMap = this.state.metricMap;
    let metricsArray = metricData.metric;
    if (metricsArray) {
      metricsArray.map((item) => {
        let masterId = item.masterId;
        let metricId = item.ci_metric_type;
        let value = item.samplevalue;
        let internalMap = metricMap.has(masterId)
          ? metricMap.get(masterId)
          : new Map();
        let valueArray = internalMap.has(metricId)
          ? internalMap.get(metricId)
          : [];
        if (valueArray.length >= 5) {
          valueArray.pop();
        }
        valueArray.push({ value: value });
        internalMap.set(metricId, valueArray);
        metricMap.set(masterId, internalMap);
        return item.masterId;
      });
    }
    return metricMap;
  };
  createGroupMapForMetrics(masterMap) {
    if (masterMap) {
      let grpMasterMap = this.state.grpMasterMap;
      for (let [key, value] of masterMap) {
        let masterId = key;
        let internalMap = value;
        let grpInternalMap = grpMasterMap.has(masterId)
          ? grpMasterMap.get(masterId)
          : new Map();
        let umain = null;
        let udataIn = null;
        let udataOut = null;
        let emain = null;
        let edataIn = null;
        let edataOut = null;
        let dmain = null;
        let ddataIn = null;
        let ddataOut = null;
        for (let [key, value] of internalMap) {
          let metricId = key;
          let valueArray = value;
          const lastElement = valueArray[valueArray.length - 1];
          if (
            metricId === "11.1:1" ||
            metricId === "11.1:2" ||
            metricId === "11.1:3"
          ) {
            if (metricId === "11.1:1") {
              umain = lastElement ? lastElement.value : 0.0;
            } else if (metricId === "11.1:2") {
              udataIn = lastElement ? lastElement.value : 0.0;
            } else if (metricId === "11.1:3") {
              udataOut = lastElement ? lastElement.value : 0.0;
            }
          } else if (
            metricId === "11.1:24" ||
            metricId === "11.1:25" ||
            metricId === "11.1:26"
          ) {
            if (metricId === "11.1:24") {
              dmain = lastElement ? lastElement.value : 0.0;
            } else if (metricId === "11.1:25") {
              ddataIn = lastElement ? lastElement.value : 0.0;
            } else if (metricId === "11.1:26") {
              ddataOut = lastElement ? lastElement.value : 0.0;
            }
          } else if (
            metricId === "11.1:27" ||
            metricId === "11.1:28" ||
            metricId === "11.1:29"
          ) {
            if (metricId === "11.1:27") {
              emain = lastElement ? lastElement.value : 0.0;
            } else if (metricId === "11.1:28") {
              edataIn = lastElement ? lastElement.value : 0.0;
            } else if (metricId === "11.1:29") {
              edataOut = lastElement ? lastElement.value : 0.0;
            }
          }
        }
        if (umain !== null || udataIn !== null || udataOut !== null) {
          let valueArrayUtil = grpInternalMap.has("Utilization")
            ? grpInternalMap.get("Utilization")
            : [];
          if (valueArrayUtil.length >= 5) {
            valueArrayUtil.pop();
          }
          valueArrayUtil.push({
            Utilization: umain,
            UtilizationIn: udataIn,
            UtilizationOut: udataOut,
          });
          grpInternalMap.set("Utilization", valueArrayUtil);
        }
        if (emain !== null || edataIn !== null || edataOut !== null) {
          let valueArrayErr = grpInternalMap.has("Errors")
            ? grpInternalMap.get("Errors")
            : [];
          if (valueArrayErr.length >= 5) {
            valueArrayErr.pop();
          }
          valueArrayErr.push({
            Errors: emain,
            ErrorsIn: edataIn,
            ErrorsOut: edataOut,
          });
          grpInternalMap.set("Errors", valueArrayErr);
        }
        if (dmain !== null || ddataIn !== null || ddataOut !== null) {
          let valueArrayDsc = grpInternalMap.has("Discards")
            ? grpInternalMap.get("Discards")
            : [];
          if (valueArrayDsc.length >= 5) {
            valueArrayDsc.pop();
          }
          valueArrayDsc.push({
            Discards: dmain,
            DiscardsIn: ddataIn,
            DiscardsOut: ddataOut,
          });
          grpInternalMap.set("Discards", valueArrayDsc);
        }
        grpMasterMap.set(masterId, grpInternalMap);
      }
      return grpMasterMap;
    }
  }
  componentWillReceiveProps(nextProps) {
    let masterMetricMap = this.updateMetricMap(nextProps.metricData);
    let grpMasterMap = this.createGroupMapForMetrics(masterMetricMap);
    let stateToUpdate = {
      // sortedData: nextProps.interfaceData,
      // metricData: nextProps.metricData,
      // metricMap: masterMetricMap,
      //grpMasterMap: grpMasterMap,
      pageNumber: 0,
    };
    if (!isEqual(this.state.sortedData, nextProps.interfaceData)) {
      stateToUpdate.sortedData = nextProps.interfaceData;
    }
    if (!isEqual(this.state.metricData, nextProps.metricData)) {
      stateToUpdate.metricData = nextProps.metricData;
    }
    if (!isEqual(this.state.masterMetricMap, masterMetricMap)) {
      stateToUpdate.masterMetricMap = masterMetricMap;
    }
     if (!isEqual(this.state.grpMasterMap, grpMasterMap)) {
       stateToUpdate.grpMasterMap = grpMasterMap;
     }
    this.setState(stateToUpdate);
    if (this.api) {
      this.api.redrawRows();
    }
    //this.api.refreshCells(["Utilization %"]);
  }
  componentWillUnmount() {
    this.componentCleanup(true);
    if (this.poller) {
      this.poller.stop();
    }
  }
  handleDrillDown = (row) => {
      //It is opening device details from interfaces in tree show interface details only kolas01
  //if(this.props.fromTreeView)
   // this.props.openDetailInterfaceFromTreeView(row.data);
   // this.props.openAlarmViewForInterface(row.data);
  //else
   this.props.openDetailedViewInterface(row.data);

    //}

    // const history = this.props.history;
    // if (!this.props.customRowClicking) {
    //   let el = row.event.target;
    //   let columnName = el.closest("[col-id]").getAttribute("col-id");
    //   let itemType = row.data.entityType;
    //   if (
    //     itemType !== entityTypes.COMPUTER_SYSTEM &&
    //     columnName !== "maxAlarmSeverity.value" &&
    //     columnName !== " "
    //   ) {
    //     this.props.handleGroupCellClick(row, history);
    //   } else if (columnName === " ") {
    //     this.handleSetDialogFlag(el.closest("[col-id]"), row);
    //   } else if (columnName !== "checkbox") {
    //     if (columnName === "maxAlarmSeverity.value") {
    //       this.props.handleAlarmCellClick(
    //         row,
    //         history,
    //         this.props.saas,
    //         this.props.doiurl,
    //         this.props.featureFlags
    //       );
    //     } else {
    //       this.props.handleCabiCellClick(row, history);
    //     }
    //   }
    // }
  };
  handleExitClickFlag = (element) => {
    this.setState({
      showDialog: false,
    });
  };
  handlePageClick = (page) => {
    this.setState({
      pageNumber: page.selected,
    });
  };
  handleSetDialogFlag = (element, row) => {
    let device = row.data;
    this.setState({
      showDialog: true,
      device: device,
    });
  };
  mouseOverClassAddition(e) {
    if (e.target && e.target.closest(".ag-cell")) {
      let hoveredCell = e.target.closest(".ag-cell");
      if (e.target.closest(".ag-row")) {
        let children = e.target.closest(".ag-row").attributes.class.ownerElement
          .childNodes;
        if (hoveredCell.getAttribute("col-id") === "maxAlarmSeverity.value") {
          each(children, (child) => {
            if (child.getAttribute("col-id") === "maxAlarmSeverity.value") {
              child.className += " grid__row--hover";
            } else if (
              child.getAttribute("col-id") === "checkbox" &&
              this.hideCheckboxes
            ) {
              child.className += " grid__row--hover";
            }
          });
        } else if (hoveredCell.getAttribute("col-id") === " ") {
          each(children, (child) => {
            if (child.getAttribute("col-id") === " ") {
              child.className += " grid__row--hover";
            }
          });
        } else if (
          hoveredCell.getAttribute("col-id") === "checkbox" &&
          this.hideCheckboxes
        ) {
          each(children, (child) => {
            if (
              child.getAttribute("col-id") === "maxAlarmSeverity.value" ||
              child.getAttribute("col-id") === "checkbox"
            ) {
              child.className += " grid__row--hover";
            }
          });
        } else {
          each(children, (child) => {
            if (
              child.getAttribute("col-id") !== "maxAlarmSeverity.value" &&
              child.getAttribute("col-id") !== " "
            ) {
              if (
                this.hideCheckboxes &&
                child.getAttribute("col-id") === "checkbox"
              ) {
                return;
              } else {
                child.className += " grid__row--hover";
              }
            }
          });
        }
      }
    }
  }
  mouseOutClassDeletion(e) {
    if (e.target && e.target.closest(".ag-cell")) {
      if (e.target.closest(".ag-row")) {
        let children = e.target.closest(".ag-row").attributes.class.ownerElement
          .childNodes;
        each(children, (child) => {
          child.className = child.className.replace(" grid__row--hover", "");
        });
      }
    }
  }
  onGridReady(params) {
    this.api = params.api;
    // this.api.sizeColumnsToFit();
    if (this.props.onGridReady) {
      this.props.onGridReady(params);
    }
    setTimeout(
      function () {
        if (
          this.api &&
          this.props.visibleDataCount !==
            this.api.rowModel.rowsToDisplay.length &&
          this.props.handleColumnFilterChange
        ) {
          this.props.handleColumnFilterChange(
            this.api.rowModel.rowsToDisplay.length
          );
        }
      }.bind(this),
      0
    );

    this.updateRowSelection();
  }
  rowDataChanged = () => {
    this.updateRowSelection();
  };
  filterChanged = (params) => {
    this.api = params.api;
    if (this.api.rowModel.rowsToDisplay.length === 0) {
      this.api.showNoRowsOverlay();
    } else {
      this.api.hideOverlay();
    }
    if (
      this.api &&
      this.props.visibleDataCount !== this.api.rowModel.rowsToDisplay.length &&
      this.props.handleColumnFilterChange
    ) {
      this.props.handleColumnFilterChange(
        this.api.rowModel.rowsToDisplay.length
      );
    }
  };
  getNoRowInfo = () => {
    let noDataMsg = Resource.get("No Data Found"); //No Data Found
    let classNames = ["setup-wizard__localprobes__info"];
    let msg2 = Resource.get("Try different filter options"); //Try different filter options
    return (
      <div style={{ position: "relative" }} className={classNames.join(" ")}>
        <div
          style={{
            width: "calc(100% - 60px)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <div className="nohubs__contentImg"></div>
          <span className="setup-wizard__localprobes__content">
            <span
              style={{
                padding: "2px 13px 2px 20px",
                borderRight: "2px solid #999999",
              }}
            >
              {noDataMsg}
            </span>
            <span style={{ paddingLeft: "9px" }}>{msg2}</span>
          </span>
        </div>
      </div>
    );
  };

  updateRowSelection = () => {
    setTimeout(
      function () {
        if (this.api) {
          this.updateRowSelectionCache = false;
          selectionCacheApi.updateOnRowDataChanged(this.api);
          this.updateRowSelectionCache = true;
          if (this.api.rowModel.rowsToDisplay.length === 0) {
            this.api.showNoRowsOverlay();
          } else {
            this.api.hideOverlay();
          }
        }
      }.bind(this),
      0
    );
  };
  selectionChanged = () => {
    if (this.updateRowSelectionCache && this.api) {
      selectionCacheApi.onChange(this.api, true);
    }
    if (this.props.handleIfRowsSelected) {
      const ids = selectionCacheApi.getData();
      this.props.handleIfRowsSelected(ids);
    }
  };
  sortColumns = (column, order) => {
    const newSortedData = sortColumns(column, order, this.state.sortedData);
    this.setState({
      sortedData: newSortedData,
      pageNumber: 0,
    });

    this.updateRowSelection();
  };

  sortInterfaceData(value1, value2) {
    if(value1.displayName < value2.displayName) {
      return -1
    }
    if(value1.displayName > value2.displayName) {
      return 1
    }
    return 0
  }

  findMetricInfo = (interfaceData) => {
    if (interfaceData) {
      var params = new URLSearchParams();
      let itemId = interfaceData.itemId;
      let masterIds = interfaceData.iface
        ? interfaceData.iface.map((item) => {
            return item.masterId;
          })
        : [];
      let metricTypes = [
        "11.1:1",
        "11.1:2",
        "11.1:3",
        "11.1:24",
        "11.1:25",
        "11.1:26",
        "11.1:27",
        "11.1:28",
        "11.1:29",
      ];
      params.append("cs_id", itemId);
      params.append("masterIds", masterIds);
      params.append("metricTypes", metricTypes);
      return params;
    }
  };
  onGridSizeChanged(params) {
    if (this.api) {
      this.api.sizeColumnsToFit();
    }
  }
  callRefreshAfterMillis(params, millis, gridApi) {
    setTimeout(function () {
      gridApi.refreshCells(params);
      this.severityFocusRef.current.focus();
    }, millis);
  }

  onSuppressKeyboardEvent(params) {
    let key = params.event.key;
    let shiftKey = params.event.shiftKey;
    let isTabbingForward = key === 'Tab' && shiftKey === false;
    let isTabbingBackWards = key === 'Tab' && shiftKey === true;

    // Handle cell children tabbing
    if (isTabbingForward || isTabbingBackWards) {
      tabForward = isTabbingForward;
      tabBackward = isTabbingBackWards;

      if (params.editing) {
        return false;
      }

      let eGridCell = params.event.path.find((el) => {
        if (el.classList === undefined) return false;
        return el.classList.contains('ag-cell');
      });

      let focusableChildrenElements = getAallFocussableElementsOf(eGridCell);
      let lastCellChildEl =
        focusableChildrenElements[focusableChildrenElements.length - 1];
      let firstCellChildEl = focusableChildrenElements[0];

      //  FORWARD
      // allow to movee to next cell when the cells last element is focused
      if (isTabbingForward && focusableChildrenElements.length > 0) {
        let isLastChildFocused =
          lastCellChildEl && document.activeElement === lastCellChildEl;

        if (isLastChildFocused === false) {
          return true;
        }
      }

      //  BACKWARDS
      //  focus last element if none are focused
      //  allow to tab backwards when first element is focused
      if (isTabbingBackWards && focusableChildrenElements.length > 0) {
        let cellHasFocusedChildren =
          eGridCell.contains(document.activeElement) &&
          eGridCell !== document.activeElement;

        if (!cellHasFocusedChildren) {
          params.event.preventDefault();
          lastCellChildEl.focus();
        }

        let isFirstChildFocused =
          firstCellChildEl && document.activeElement === firstCellChildEl;

        if (isFirstChildFocused === false) {
          return true;
        }
      }
    }

    return false;
  }

  onSuppressHeaderKeyboardEvent(params) {
    let key = params.event.key;
    let shiftKey = params.event.shiftKey;
    let isTabbingForward = key === 'Tab' && shiftKey === false;
    let isTabbingBackWards = key === 'Tab' && shiftKey === true;
    // Handle cell children tabbing

    if (isTabbingForward || isTabbingBackWards) {
      let eGridCell = params.event.path.find((el) => {
        if (el.classList === undefined) return false;
        return el.classList.contains('ag-header-cell');
      });

      let focusableChildrenElements = getAallFocussableElementsOf(eGridCell);
      let lastCellChildEl =
        focusableChildrenElements[focusableChildrenElements.length - 1];
      let firstCellChildEl = focusableChildrenElements[0];

      //  FORWARD
      // allow to movee to next cell when the cells last element is focused
      if (isTabbingForward && focusableChildrenElements.length > 0) {
        let isLastChildFocused =
          lastCellChildEl && document.activeElement === lastCellChildEl;

        if (isLastChildFocused === false) {
          return true;
        }
      }

      //  BACKWARDS
      //  focus last element if none are focused
      //  allow to tab backwards when first element is focused
      if (isTabbingBackWards && focusableChildrenElements.length > 0) {
        let cellHasFocusedChildren =
          eGridCell.contains(document.activeElement) &&
          eGridCell !== document.activeElement;
        if (!cellHasFocusedChildren) {
          params.event.preventDefault();

          lastCellChildEl.focus();
        }

        let isFirstChildFocused =
          firstCellChildEl && document.activeElement === firstCellChildEl;

        if (isFirstChildFocused === false) {
          return true;
        }
      }
    }

    return false;
  }
  onCellFocused2(params) {
    if (tabForward) {
      let cell = document.activeElement;
      let focusableChildren = getAallFocussableElementsOf(cell);

      if (focusableChildren.length > 0) {
        focusableChildren[0].focus();
      }

      tabForward = false;
    }

    if (tabBackward) {
      let cell = document.activeElement;
      let focusableChildren = getAallFocussableElementsOf(cell);

      if (focusableChildren.length > 0) {
        focusableChildren[focusableChildren.length - 1].focus();
      }

      tabBackward = false;
    }
  }
  render() {
    // No results found
    const NoReultsMsg = Resource.get("No results found");
    let view;
    const headerHeight = 36;
    const rowHeight = 48;
    let metricParams = this.findMetricInfo(this.state.sortedData);
    if (metricParams) {
      this.poll(metricParams);
    }
    let rowData = this.state.sortedData ? (this.state.sortedData.iface ? this.state.sortedData.iface.sort(this.sortInterfaceData) : this.state.sortedData.iface ) : [];
    let heightOfDiv = 15 * rowHeight + headerHeight + 30;
    let onSelectionChanged = this.selectionChanged;
    let rowSelection = "multiple";
    view = (
      <div ref="interfaceRef" style={{height:'100%'}}>
        {/* {this.props.fromTreeView && (
          <span style={{ paddingLeft: "20px", fontSize: "15px" }}>
            Showing interfaces for {this.props.groupData.name} ({rowData.length}
            )
          </span>
        )} */}
        <div
          className="ag-material tablecontainer"
          style={{margin:'0px',
            height: this.props.fromTreeView?"100%":(this.props.fromGroupsView?"calc(100% - 3px)":"calc(100vh - 135px)"),
          }}
        >
          <Grid
            {...this.props}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            rowData={rowData}
            columnDefs={this.getCols()}
            onGridReady={this.onGridReady.bind(this)}
            onGridSizeChanged={this.onGridSizeChanged.bind(this)}
            enableColResize={true}
            overlayNoRowsTemplate={this.state.overlayNoRowsDisplay}
            onSelectionChanged={onSelectionChanged}
            onFilterChanged={this.filterChanged.bind(this)}
            onRowDataChanged={this.rowDataChanged}
            rowSelection={rowSelection}
            onRowClicked={this.handleDrillDown.bind(this)}
            tooltipShowDelay={this.state.tooltipShowDelay}
            suppressCellFocus={true}
            //suppressKeyboardEvent= {this.handleDrillDown.bind(this)}
            getRowNodeId={(rowData) => {
              return rowData.masterId;
            }}
            suppressHorizontalScroll={false}
            onCellFocused={this.onCellFocused2.bind(this)}
            //suppressCellFocus={true}
            suppressKeyboardEvent= {this.onSuppressKeyboardEvent}
            context={this}
            suppressHeaderKeyboardEvent= {this.onSuppressHeaderKeyboardEvent}
          />
        </div>
      </div>
    );
    return view;
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    pollMetricData: (params) => dispatch(fetchMetrics(params)),
    handleIfRowsSelected: (ids) => dispatch(setSelectedIfIds(ids)),
    changeInterfacesCount: (count) => dispatch(changeInterfacesCount(count))
  };
};

const mapStateToProps = (state) => {
  try {
    return {
      sid: state.sid,
      metricData: state.interfaces.metricData,
      interfaceData: state.interfaces.interfaceData,
      selectedEntityType: state.entity.type,
    };
  } catch (e) {
    return {
      error: e,
    };
  }
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InterfaceTable));
