// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Origin Text
  content["Origin"] =
  "Origin";

  //Name
  content["Name"] =
  "Name";

  //Alias message
  content["Alias"] =
  "Alias";

  //Device
  content["Device"] =
  "Device";

  //If Index
  content["If Index"] =
  "If Index";

  //IfPhysAddress
  content["If Physical Address"] =
  "If Physical Address";

  //IfType
  content["If Type"] =
  "If Type";

  //instanceId
  content["Instance Id"] =
  "Instance Id";

  //IPaddress message
  content["IP address"] =
  "IP address";

  //Caption message
  content["Caption"] =
  "Caption";

  //Description message
  content["Description"] =
  "Description";

  //Role message
  content["Role"] =
  "Role";

  //OSType message
  content["OS Type"] =
  "OS Type";

  //OSName message
  content["OS Name"] =
  "OS Name";

  //OSVersion message
  content["OS Version"] =
  "OS Version";

  //OSDescription message
  content["OS Description"] =
  "OS Description";

  //MacAddress message
  content["MacAddress"] =
  "MacAddress";

  //User Tag 1 message
  content["User Tag 1"] =
  "User Tag 1";

  //User Tag 2 message
  content["User Tag 2"] =
  "User Tag 2";

  //Matching Devices
  content["Matching Devices"] =
  "Matching Devices";

  //Found
  content["Found"] =
  "Found";

  //SearchforComputerSystems message
  content["Search for Computer Systems"] =
  "Search for Computer Systems";

  //Namemustbelessthan256 characters message
  content["Name must be less than 256 characters"] =
  "Name must be less than 256 characters";

  //Groupnamelareadyexists message
  content["Group name already exists"] =
  "Group name already exists";

  // A group may contain either devices or additional groups, not a mix.
  content[" A group may contain either devices or additional groups, not a mix."] =
  " A group may contain either devices or additional groups, not a mix.";

  //Groupt Type from Static, Dynamic or Contianer
  content["Group Type"] =
  "Group Type";

  //Account for Group
  content["Account"] =
  "Account";

  // Dynamic Group
  content["Dynamic Group"] =
  "Dynamic Group";

  // Element Type
  content["Element Type"] =
  "Element Type";

  //Default value for dropdown
  content["No Account"] =
  "No Account";

  //Cancel
  content["Cancel"] =
  "Cancel";

  //Add
  content["Add"] =
  "Add";

  //Group Name
  content["Group Name"] =
  "Group Name";

  //Group name
  content["Group name"] =
  "Group name";

  //Static
  content["Static"] =
  "Static";

  //Dynamic
  content["Dynamic"] =
  "Dynamic";

  //Container
  content["Container"] =
  "Container";

  //Devices
  content["Devices"] =
  "Devices";

  //Interfaces
  content["Interfaces"] =
  "Interfaces";

  //Current Password
  content["Current Password"] =
  "Current Password";

  //New Password
  content["New Password"] =
  "New Password";

  //Confirm Password
  content["Confirm Password"] =
  "Confirm Password";

  //Not allowed more than 24 characters.
  content["Not allowed more than 24 characters."] =
  "Not allowed more than 24 characters.";

  //Please enter valid password
  content["Please enter valid password"] =
  "Please enter valid password";

  //The passwords must match.
  content["The passwords must match."] =
  "The passwords must match.";

  //current and new passwords cannot be same.
  content["The current and new passwords cannot be same."] =
  "The current and new passwords cannot be same.";

  //Password must be between 8 and 24 characters.
  content["Password must be between 8 and 24 characters."] =
  "Password must be between 8 and 24 characters.";

  //Password must contain a upper case letter, a lower case letter, a number and a special character.
  content["Password must contain a upper case letter, a lower case letter, a number and a special character."] =
  "Password must contain a upper case letter, a lower case letter, a number and a special character.";

  //Save
  content["Save"] =
  "Save";

  //Close
  content["Close"] =
  "Close";

  //Required
  content["Required"] =
  "Required";

  //Delete
  content["Delete"] =
  "Delete";

  //DeleteDevice
  content["Delete Device"] =
  "Delete Device";

  // help message
  content["This action will remove the selected device(s) from the system\'s inventory of discovered and monitored devices. It will no longer count against the billing cap."] =
  "This action will remove the selected device(s) from the system\'s inventory of discovered and monitored devices. It will no longer count against the billing cap.";

  // help message1
  content["To restore the device and enable monitoring, go to\… Setting > Deleted devices"] =
  "To restore the device and enable monitoring, go to\… Setting > Deleted devices";

  //Delete Group
  content["Delete Group"] =
  "Delete Group";

  //Your individual devices will not be deleted.
  content[" Your individual devices will not be deleted. "] =
  " Your individual devices will not be deleted. ";

  //selectDiscoveryAgent
  content["Please select a discovery agent from an Inventory tree and then click on Discovery wizard"] =
  "Please select a discovery agent from an Inventory tree and then click on Discovery wizard";

  //Group Management Failed message
  content["Group Management Failed"] =
  "Group Management Failed";

  //Add Devices Failed message
  content["Add Devices Failed"] =
  "Add Devices Failed";

  //Delete Group Failed message
  content["Delete Group Failed"] =
  "Delete Group Failed";

  //Rename Group Failed message
  content["Rename Group Failed"] =
  "Rename Group Failed";

  //Add Group Failed message
  content["Add Group Failed"] =
  "Add Group Failed";

  //Removing Devices Failed message
  content["Removing Devices Failed"] =
  "Removing Devices Failed";

  //Removing Devices Failed message
  content["Importing Devices Failed."] =
  "Importing Devices Failed.";

  //Delete Device(s) Failed message
  content["Delete Device(s) Failed"] =
  "Delete Device(s) Failed";

  //Failed to get containers
  content["Failed to get containers"] =
  "Failed to get containers";

  //Please contact an administrator
  content["Please contact an administrator."] =
  "Please contact an administrator.";

  //Please check file format for data
  content["Please check file format for data."] =
  "Please check file format for data.";

  //Failure Description
  content["Group creation failed as parent group and child group do not share the same account. Please contact an administrator."] =
  "Group creation failed as parent group and child group do not share the same account. Please contact an administrator.";

  //Show hide views
  content["Show / Hide Views"] =
  "Show / Hide Views";

  //Group description
  content["Group Description"] =
  "Group Description";

  // Member Type
  content["Member Type"] =
  "Member Type";

  //Interfaces
  content["Interface"] =
  "Interface";

  //Parent
  content["Parent"] =
  "Parent";

  //Proceed
  content["Proceed"] =
  "Proceed";

  //Deploy Robot
  content["Deploy Robot"] =
  "Deploy Robot";

  // help message
  content["Click proceed to deploy robot in selected device(s)"] =
  "Click proceed to deploy robot in selected device(s)";

  //Search For Computer Systems
  content["Search For Computer Systems"] =
  "Search For Computer Systems";

  //Remove
  content["Remove"] =
  "Remove";

  //Your individual devices will not be deleted
  content["Your individual devices will not be deleted "] =
  "Your individual devices will not be deleted ";

  //Session Expired message
  content["Your session is expired."] =
  "Your session is expired.";

  //Please try to relogin
  content["Please try to re-login"] =
  "Please try to re-login";

  //Unknown
  content["Unknown"] =
  "Unknown";

  //dp039841
  content["Personal User preference saved successfully"] =
  "Personal User preference saved successfully";

  //PersonalUserPrefsFailure
  content["Failed to save personal user preference"] =
  "Failed to save personal user preference";

  //Change Password
  content["Change Password"] =
  "Change Password";

  //makethismydefaultview
  content["Set as default view"] =
  "Set as default view";

  // className="secondaryTextStyle"
  content["Logout"] =
  "Logout";

  //All
  content["All"] =
  "All";

  //Open Alarms
  content["Open Alarms"] =
  "Open Alarms";

  //Monitored Devices
  content["Monitored Devices"] =
  "Monitored Devices";

  //Devices By Roles
  content["Devices By Roles"] =
  "Devices By Roles";

  //Top Entities
  content["Top 10 Entities"] =
  "Top 10 Entities";

  //Technologies
  content["Technologies"] =
  "Technologies";

  //Groups
  content["Groups"] =
  "Groups";

  //Remove device(s) from Group Message
  content["Remove device(s) from Group"] =
  "Remove device(s) from Group";

  //Edit Group Text
  content["Edit group"] =
  "Edit group";

  //Action Menu
  content["Action Menu"] =
  "Action Menu";

  //Add group Message
  content["Add group"] =
  "Add group";

  // Delete group message
  content["Delete group"] =
  "Delete group";

  //Delete device(s) Menu Item title
  content["Delete device(s)"] =
  "Delete device(s)";

  //Delete device(s) Menu Item title
  content["Deploy Robot(s)"] =
  "Deploy Robot(s)";

  // END OF LOCALIZATION

export default content;
