/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import { Grid } from "../ui-components/uim-components";
import "./copyDialog.less";
// import Resource from "./Resource";
//import CheckBox from '@material-ui/core/CheckBox';
import { isEqual } from "../../utils/lodash-utils";

const INIT_PAGE_NUMBER = 0;
const PAGE_SIZE = 500; //500
const FILTER_OPTIONS = {
  pageNum: INIT_PAGE_NUMBER,
  pageSize: PAGE_SIZE,
  sortColumn: "",
  sortDirection: "",
};
class MetricViewMatchingDeviceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      //selectedIndices: this.props.selectedIndices?this.props.selectedIndices:[],
      computerSystemList: [],
      isMaximizedView: false,
      pageNum: INIT_PAGE_NUMBER,
      gridRowData: this.props.rowData,
      isExcessDevice: this.props.isExcessDevice,
      tooltipShowDelay: 0,
    };
    this.deviceFilterOptions = Object.assign({}, FILTER_OPTIONS);
    this.deviceColumns = [
      {
        headerName: "",
        field: "checkbox",
        suppressMenu: true,
        suppressSorting: true,
        suppressResize: true,
        suppressSizeToFit: true,
        width: 50,
        lockPosition: true,
        checkboxSelection: true,

        headerComponentFramework: (params) => {
          return (
            <input
              type="checkbox"
              ref={(element) => {
                params.context.globalCheckoxRef = element;
              }}
              style={{
                width: "18px",
                height: "16px",
                margin: "10px 0px",
              }}
              tabIndex="-1"
              aria-label={"Select all controls"}
              //checked={this.state.isGlobalCheckbox}
              onChange={(event) =>
                this.handleCheckboxChange(event.target.checked)
              }
            />
          );
        },
        hide: false,
      },
      {
        //Name message
        headerName: "Device Name",
        field: "name",
        tooltipField: "Name",
        width: 200,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.name ? params.data.name : ""}
            </p>
          );
        },
      },
      {
        //Alias
        headerName: "Alias",
        field: "alias",
        tooltipField: "alias",
        width: 150,
        sortable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.alias ? params.data.alias : ""}
            </p>
          );
        },
      },
      {
        //Dedicated
        headerName: "Dedicated",
        field: "role",
        tooltipField: "role",
        width: 120,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.role ? params.data.role : ""}
            </p>
          );
        },
      },

      {
        //OS Type
        headerName: "OS Type",
        field: "osType",
        tooltipField: "osType",
        width: 120,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.osType ? params.data.osType : ""}
            </p>
          );
        },
      },
      {
        //OS Name
        headerName: "OS Name",
        field: "osName",
        tooltipField: "osName",
        width: 120,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.osName ? params.data.osName : ""}
            </p>
          );
        },
      },

      {
        //Origin
        headerName: "Origin",
        field: "origin",
        tooltipField: "origin",
        width: 150,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.origin ? params.data.origin : ""}
            </p>
          );
        },
      },
      {
        //usertag1
        headerName: "User Tag 1",
        field: "userTag1",
        tooltipField: "userTag1",
        width: 150,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.userTag1 ? params.data.userTag1 : ""}
            </p>
          );
        },
      },
      {
        //usertag1
        headerName: "User Tag 2",
        field: "userTag2",
        tooltipField: "userTag2",
        width: 150,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.userTag2 ? params.data.userTag2 : ""}
            </p>
          );
        },
      },
    ];

    this.groupColumns = [
      {
        headerName: "",
        field: "checkbox",
        suppressMenu: true,
        suppressSorting: true,
        suppressResize: true,
        suppressSizeToFit: true,
        width: 50,
        lockPosition: true,
        checkboxSelection: true,
        headerComponentFramework: (params) => {
          return (
            <input
              type="checkbox"
              ref={(element) => {
                params.context.globalCheckoxRef = element;
              }}
              style={{
                width: "18px",
                height: "16px",
                margin: "10px 0px",
              }}
              tabIndex="-1"
              aria-label={"Select all controls"}
              onChange={(event) =>
                this.handleCheckboxChange(event.target.checked)
              }
            />
          );
        },
        hide: false,
      },
      {
        //Group Name
        headerName: "Group Name",
        field: "grpName",
        tooltipField: "grpName",
        width: 200,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.grpName ? params.data.grpName : ""}
            </p>
          );
        },
      },
      {
        //Parent Group
        headerName: "Parent Group",
        field: "parentGrpName",
        tooltipField: "parentGrpName",
        width: 120,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.parentGrpName ? params.data.parentGrpName : ""}
            </p>
          );
        },
      },
      {
        //Group Type
        headerName: "Group Type",
        field: "grpType",
        tooltipField: "grpType",
        sortable: true,
        resizable: true,
        width: 120,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.grpType ? params.data.grpType : ""}
            </p>
          );
        },
      },
    ];
    this.groupDeviceColumns = [
      {
        headerName: "",
        field: "checkbox",
        suppressMenu: true,
        suppressSorting: true,
        suppressResize: true,
        suppressSizeToFit: true,
        width: 50,
        lockPosition: true,
        checkboxSelection: true,

        headerComponentFramework: (params) => {
          return (
            <input
              type="checkbox"
              ref={(element) => {
                params.context.globalCheckoxRef = element;
              }}
              style={{
                width: "18px",
                height: "16px",
                margin: "10px 0px",
              }}
              tabIndex="-1"
              aria-label={"Select all controls"}
              //checked={this.state.isGlobalCheckbox}
              onChange={(event) =>
                this.handleCheckboxChange(event.target.checked)
              }
            />
          );
        },
        hide: false,
      },
      {
        //Name message
        headerName: "Device Name",
        field: "name",
        tooltipField: "name",
        width: 200,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.name ? params.data.name : ""}
            </p>
          );
        },
      },
      {
        //Alias
        headerName: "Alias",
        field: "alias",
        tooltipField: "alias",
        width: 150,
        sortable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.alias ? params.data.alias : ""}
            </p>
          );
        },
      },
      {
        //Dedicated
        headerName: "Dedicated",
        field: "role",
        tooltipField: "role",
        width: 120,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.role ? params.data.role : ""}
            </p>
          );
        },
      },
      {
        //OS Type
        headerName: "OS Type",
        field: "osType",
        tooltipField: "osType",
        width: 120,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.osType ? params.data.osType : ""}
            </p>
          );
        },
      },
      {
        //OS Name
        headerName: "OS Name",
        field: "osName",
        tooltipField: "osName",
        width: 120,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.osName ? params.data.osName : ""}
            </p>
          );
        },
      },

      {
        //Origin
        headerName: "Origin",
        field: "attributes.origin",
        tooltipField: "attributes.origin",
        width: 150,
        sortable: true,
        resizable: true,
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes.origin
                ? params.data.attributes.origin
                : ""}
            </p>
          );
        },
      },
      {
        //usertag1
        headerName: "User Tag 1",
        width: 150,
        sortable: true,
        resizable: true,
        field: "attributes.user_tag_1",
        tooltipField: "attributes.user_tag_1",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes.user_tag_1
                ? params.data.attributes.user_tag_1
                : ""}
            </p>
          );
        },
      },
      {
        //usertag1
        headerName: "User Tag 2",
        width: 150,
        sortable: true,
        resizable: true,
        field: "attributes.user_tag_2",
        tooltipField: "attributes.user_tag_2",
        cellRendererFramework: (params) => {
          return (
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.data?.attributes.user_tag_2
                ? params.data.attributes.user_tag_2
                : ""}
            </p>
          );
        },
      },
    ];
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isExcessDevice != nextProps.isExcessDevice) {
      this.setState({ isExcessDevice: nextProps.isExcessDevice });
    }

    if (!isEqual(this.state.gridRowData, nextProps.rowData)) {
      let isChecked = false;
      if (
        nextProps.isBackClicked ||
        nextProps.isNextClicked ||
        nextProps.isFilterApplied ||
        this.props.targetType !== nextProps.targetType
      ) {
        this.paramsInfo = null;
        if (
          this.api &&
          (nextProps.isNextClicked || nextProps.isFilterApplied)
        ) {
          this.api.deselectAll();
        } else if (nextProps.isBackClicked) {
          let selInfo = nextProps.selectedGroupDeviceMapping.filter((group) => {
            return group.groupId === nextProps.currentGroupId;
          });
          if (selInfo.length > 0) {
            isChecked =
              selInfo[0].selDevices.length == this.state.gridRowData
                ? true
                : false;
          }
        }
      }

      if (this.paramsInfo != null) {
        this.updateData(nextProps.rowData, this.paramsInfo);
        this.setState({
          gridRowData: nextProps.rowData,
          // isGlobalCheckbox: isChecked
        });
      } else if (this.paramsInfo == null) {
        this.startRow = 0;
        this.setGridDataSource(nextProps.rowData, nextProps);
        this.setState({
          gridRowData: nextProps.rowData,
          //isGlobalCheckbox: isChecked
        });
      }
    }
    if (this.props.targetType !== nextProps.targetType) {
      setTimeout(() => {
        this.api.sizeColumnsToFit();
      }, 200);
    }
  }

  updateSelectedRows = (props) => {
    //for back button implmeentaion
    if (
      this.api &&
      props?.selectedGroupDeviceMapping?.length > 0 &&
      props?.index > -1
    ) {
      let currentGroup_Devices = props?.selectedGroupDeviceMapping.filter(
        (group) => {
          return group.groupId === props.currentGroupId;
        }
      );
      if (currentGroup_Devices.length > 0) {
        let selectedRows = currentGroup_Devices[0].selDevices;
        this.api.forEachNode((node) => {
          let isSelected = selectedRows.some((row) => {
            return row.id === node.id;
          });
          isSelected ? node.setSelected(true) : node.setSelected(false);
        });
        this.api.redrawRows();
      }
    } else if (this.api && props?.index == -1 && props?.isBackClicked) {
      this.api.forEachNode((node) => {
        let isSelected = props.selectedGroupIds.some((row) => {
          return row.id === node.id;
        });
        isSelected ? node.setSelected(true) : node.setSelected(false);
      });
      this.api.redrawRows();
    }
  };

  handleCheckboxChange = (event) => {
    if (event) {
      this.api.forEachNode(function (node) {
        node.setSelected(true);
      });
    } else {
      this.api.deselectAll();
    }
    this.api.redrawRows();
  };
  onGridReady = (params) => {
    this.api = params.api;
    this.api.showLoadingOverlay();
    this.paramsInfo = null;
    if (
      this.state.gridRowData.length === undefined ||
      this.state.gridRowData.length === 0
    ) {
      this.api.hideOverlay();
      this.api.showNoRowsOverlay();
    } else if (this.paramsInfo == null) {
      this.startRow = 0;
      this.firstLoad = false;
      this.setGridDataSource(this.state.gridRowData, null);
    }
  };

  onGridSizeChanged = (params) => {
    if (this.api) {
      this.api.sizeColumnsToFit();
    }
  };
  setGridDataSource = (data, nextProps) => {
    let datasource = {
      rowCount: null,
      getRows: function (params) {
        params.context.updateData(data, params);
      },
    };
    if (this.api) {
      this.api.setDatasource(datasource);
      setTimeout(() => {
        this.updateSelectedRows(nextProps);
      }, 1000);
    }
  };

  updateData = (data, params) => {
    if (params.context) {
      params.context.paramsInfo = params;
      if (params.context.startRow != params.startRow) {
        params.context.startRow = params.startRow;
        // Change the page number in store and state
        let pageSize = params.endRow - params.startRow,
          nextPageNumber = params.startRow / pageSize;
        if (params.context.api) {
          params.context.api.showLoadingOverlay();
        }
        params.context.onPageChange(nextPageNumber);
      } else {
        setTimeout(function () {
          var lastRow = -1;
          if (data.length <= params.endRow) {
            lastRow = data.length;
          }
          // params.context.getRowsCount=params.context.getRowsCount+1;
          if (params.context && params.context.api) {
            params.context.api.hideOverlay();
          }
          if (data && data.length == 0) {
            params.context.api.showNoRowsOverlay();
          }
          params.successCallback(data, lastRow);
        }, 200);
      }
    }
  };

  onPageChange(pageNumber) {
    this.deviceFilterOptions.pageNum = pageNumber;
    this.props.findDevices(this.deviceFilterOptions);
  }

  onSelectionChanged(event) {
    var selectedNode = event.api.getSelectedNodes();
    this.props.handleDeviceSelection(selectedNode);
  }
  onSort() {
    let sorts = [];
    if (this.state.gridRowData.length > 0) {
      for (let column of this.api.sortController.columnController.gridColumns) {
        if (column.sort != null) {
          let columnId = column.colId;
          sorts.push({
            sortColumn: columnId,
            sortDirection: column.sort,
            sortedAt: column.sortedAt,
          });
        }
      }
      if (sorts != undefined && sorts.length != 0) {
        this.paramsInfo = null;
        if (this.api) {
          this.api.showLoadingOverlay();
        }
      }
      sorts.sort((a, b) => {
        return a.sortedAt - b.sortedAt;
      });
      sorts = sorts.map((sort) => {
        return {
          sortColumn: sort.sortColumn,
          sortDirection: sort.sortDirection,
        };
      });
      if (sorts != undefined && sorts.length != 0) {
        this.startRow = 0;
        this.deviceFilterOptions.pageNum = INIT_PAGE_NUMBER;
        this.deviceFilterOptions.sortColumn = sorts[0].sortColumn;
        this.deviceFilterOptions.sortDirection = sorts[0].sortDirection;
        this.props.findDevices(this.deviceFilterOptions);
      }
    }
  }

  render() {
    //Matching Devices
    let deviceTableLabel = "Select Devices";
    //Matching Groups
    let groupTableLabel = "Select Groups";
    let overlayMessageTemplate =
      "No Devices found. You may define a new filter criteria.";
    let found = "Found"; //Found
    let noOfDevices = this.state.rowData ? this.state.rowData.length : 0;
    let countLabel = noOfDevices + " " + found;

    return (
      <>
        {this.state.gridRowData.length === undefined ||
        this.state.gridRowData.length === 0 ? (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            {overlayMessageTemplate}
          </div>
        ) : (
          <div id="matching-devices-table">
            <div
              style={{
                marginTop: "24px",
                marginBottom: "11px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span className="textField-heading">
                {this.props.targetType == "device"
                  ? deviceTableLabel
                  : this.props.index == -1
                  ? groupTableLabel
                  : deviceTableLabel}
              </span>
              {this.state.isExcessDevice && (
                <span
                  style={{
                    fontSize: "14px",
                    color: "#333840",
                    fontFamily: "Roboto",
                  }}
                >
                  Maximum 100 devices can be selected
                </span>
              )}

              <span
                style={{
                  fontSize: "14px",
                  color: "#333840",
                  fontFamily: "Roboto",
                }}
              >
                {`${
                  this.props.totalDeviceCount ? this.props.totalDeviceCount : 0
                } Matching Devices Found`}
              </span>
              {/* <TextField
                className= "device-filter-box"
                name="q"
                variant="outlined"
                //SearchforComputerSystems message
                placeholder={Resource.get("Filter by Device Name")}
                value={this.state.deviceSearchQuery}
                //onChange={this.handleSearchTextChange.bind(this)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FilterIcon/>
                    </InputAdornment>
                  ),
                }}
              /> */}
            </div>
            <div
              id="matching-devices-ag-grid"
              className="ag-material matching-devices-ag-grid"
              style={{ height: "408px", width: "100%", marginLeft: "16px" }}
            >
              <Grid
                rowHeight={40}
                headerHeight={40}
                //rowData={this.state.gridRowData}
                columnDefs={
                  this.props.targetType == "device"
                    ? this.deviceColumns
                    : this.props.index == -1
                    ? this.groupColumns
                    : this.groupDeviceColumns
                }
                enableColResize={true}
                suppressCellFocus={true}
                rowSelection="multiple"
                suppressRowHoverClass={false}
                rowModelType="infinite"
                onGridReady={this.onGridReady}
                onGridSizeChanged={this.onGridSizeChanged}
                overlayNoRowsTemplate={overlayMessageTemplate}
                cacheBlockSize={PAGE_SIZE}
                cacheOverflowSize={2}
                maxConcurrentDatasourceRequests={1}
                maxBlocksInCache={10}
                context={this}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
                //onFilterChanged = {this.onFilter.bind(this)}
                onSortChanged={this.onSort.bind(this)}
                enableSorting={true}
                tooltipShowDelay={this.state.tooltipShowDelay}
                getRowNodeId={(data) => {
                  let id =
                    this.props.targetType == "device"
                      ? data.id
                      : this.props.index == -1
                      ? data.grpId
                      : data.id;
                  return id;
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}
export default MetricViewMatchingDeviceList;
