/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { useMemo, useState, useEffect } from "react";
import "./QosChart.less";
import "./ResponsiveQosChart.less";
import { Typography, CircularProgress } from "@mineral/core";
//import Resource from "./Resource";
import { useDrop, useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { PRD } from "../../../../../../../api/performanceReports/prd";
import X2JS from "xmljson-converter";
import { isArray, isEqual } from "../../../../../../../utils/lodash-utils";
import QosChartControlsToolbar, {
  chartMenuOptions,
} from "../chart-toolbar/QosChartControlsToolbar";
import { addNewSeries, setDataCount } from "../../../../../../../api/performanceReports/actions";
import moment from "moment";
import DataisnotAvailable from "../../../../../../../../src/assets/images/img_dataisnotavailable.svg";
import ChartView from "../mineral-chart-container/ChartView";
import { strokeCombinations } from "../mineral-chart-container/dataset";
import CustomDataGrid from "../mineral-chart-container/CustomDataGrid";

const randomColor = [
  "1724976",
  "11240711",
  "11750149",
  "9375246",
  "1609605",
  "4169248",
  "10393088",
  "13402884",
  "413040",
  "873037",
  "2968931",
  "3618739",
  "5194104",
  "6564766",
  "9048644",
  "3116538",
  "6056896",
  "11225020",
  "15105570",
  "15684432",
  "427414",
  "775701",
  "14773335",
  "35195",
  "43036",
  "14057730",
  "16740419",
  "9268835",
  "11608353",
  "22145",
  "39356",
  "9342168",
  "45716",
  "1083710",
  "97375",
  "8287071",
  "683666",
  "8400173",
  "5254180",
];

export const dataInterPolation = (dataArray, aggInterval) => {
  if (aggInterval == 0) {
    return dataArray;
  }
  let outputArray = [];
  let count = 0;
  let keys = [];
  dataArray &&
    dataArray.forEach((dataObject) => {
      outputArray.push(dataObject);
      if (count < dataArray.length - 1) {
        let nextObject = dataArray[count + 1];
        if (count === 0) {
          keys = Object.keys(dataObject);
          keys = keys.filter((key) => key !== "t");
        }
        let dataObjectMilli = dataObject.t;
        let nextObjectMilli = nextObject.t;
        let sampleDiff = nextObjectMilli - dataObjectMilli;
        let copiedObject = dataObject;
        while (sampleDiff > aggInterval * 1000) {
          let newObject = {};
          let newSampleTime = dataObjectMilli + aggInterval * 1000;
          let newDate = new Date(newSampleTime);
          newObject["t"] = newSampleTime;
          keys &&
            keys.forEach((key) => {
              let value = copiedObject[key];
              let value1 = nextObject[key];
              let newValue = null;
              if (value !== null && value1 !== null) {
                let y =
                  value +
                  (newSampleTime - dataObjectMilli) *
                  ((value1 - value) / (nextObjectMilli - dataObjectMilli));
                newValue = y < 0 ? y * -1 : y;
                newValue =
                  newValue == 0 ? 0 : ((newValue + Number.EPSILON) * 100) / 100;
              }
              newObject[key] = newValue;
            });
          outputArray.push(newObject);
          sampleDiff = nextObjectMilli - newSampleTime;
          dataObjectMilli = newSampleTime;
          copiedObject = newObject;
        }
        count++;
      }
    });
  return outputArray;
};

export const hexToDec = (hexColor) => {
  return parseInt(hexColor, 16);
};

export const decToHex = (decColor) => {
  // if(decColor.includes("#")){
  //     return decColor;
  // }
  let convertedNum = parseInt(decColor).toString(16);
  if (convertedNum.length < 6) {
    let diff = 6 - convertedNum.length;
    let preFix = "";
    for (let i = 0; i < diff; i++) {
      preFix += "0";
    }
    convertedNum = preFix + convertedNum;
  }
  return `#${convertedNum}`;
};

export const randomColoFn = () => {
  let color =
    "#" +
    ("000000" + Math.floor(Math.random() * 16777215).toString(16)).slice(-6);
  return hexToDec(color.substring(1, color.length));
};

const QosChart = (props) => {
  const [combos, setCombos] = useState([]);
  const QosChartTableRef = React.useRef();
  const [showChat,setShowChart]=useState(false);
  const ref = React.useRef(null);
  let filteredChartData = [],
    finalArray = [];

  const [dropResult, setDropResult] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartObject, setChartObject] = useState(props.chartData);
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [filterResult, setFilterResult] = useState({});
  const [defaultChart, setDefaultChart] = useState(false);
  const [openedSnackbar, setOpenedSnackbar] = useState(false);
  const [sourceDropdownState, setSourceDropdownState] = useState({
    isSourceDropdown: true,
    chartData: [],
  });
  useEffect(() => {
    if (props.changeLoading !== undefined)
      props.changeLoading(loading);
  }, [loading])
  const [showMaxMin, setShowMaxMin] = useState(false);
  const changeMaxMin = (val) => {
    setShowMaxMin(val);
  }
  const is_updated = useSelector(
    (state) => state.performanceReports.chartData.State.is_updated
  );
  const isHostDrop = useSelector(
    (state) => state.performanceReports.selectedSource == "target"
  );
  const isChartExpanded = useSelector(
    (state) =>
      state.performanceReports.chartData.State.GraphMaximized ==
      chartObject.chartId
  );
  const aggregationInterval = useSelector(
    (state) => state.performanceReports.chartData.State.aggregationInterval
  );
  const aggLevel = useSelector(
    (state) => state.performanceReports.chartData.State.aggLevel
  );
  const isInterpolation = useSelector(
    (state) => state.performanceReports.chartData.State.isInterpolation
  );
  const timeFrame = useSelector(
    (state) => state.performanceReports.chartData.State.timeFrame
  );
  const startTime = useSelector(
    (state) => state.performanceReports.chartData.State.TimeStart
  );
  const stopTime = useSelector(
    (state) => state.performanceReports.chartData.State.TimeStop
  );
  const minY = useSelector(
    (state) => state.performanceReports.chartData.State.minYAxis
  );
  const maxY = useSelector(
    (state) => state.performanceReports.chartData.State.maxYAxis
  );
  const dataCount = useSelector(
    (state) => state.performanceReports.dataCount
  );
  const currentSelectedCombos = useSelector(
    (state) => state.performanceReports.selectedCombos
  );
  const multiSqtData = useSelector((state) =>
    state.performanceReports.newPreparedCharts != null
      ? state.performanceReports.newPreparedCharts.find(
        (chart) => chart.chartId == chartObject.chartId
      ) != undefined
        ? state.performanceReports.newPreparedCharts.find(
          (chart) => chart.chartId == chartObject.chartId
        ).data
        : null
      : null
  );

  const savedFilter = useSelector((state) =>
    state.performanceReports.filteredCharts != null
      ? state.performanceReports.filteredCharts.find(
        (chart) => chart.chartId == chartObject.chartId
      ) != undefined
        ? state.performanceReports.filteredCharts.find(
          (chart) => chart.chartId == chartObject.chartId
        ).data
        : null
      : null
  );

  const selectChartText = `Select and drop target(s)
                                                        \n  or
                                                        \n Click Create Chart Button`;

  const dispatch = useDispatch();

  const prepareIntervals = () => {
    var dif = Number(aggregationInterval);
    var startIntervalTime = moment(moment.unix(startTime / 1000)).add(
      -dif,
      "ms"
    );
    var endIntervalTime = moment(moment.unix(startTime / 1000));
    var finishTime = moment(moment.unix(stopTime / 1000));
    console.log(startIntervalTime.isBefore(finishTime));
    var intervals = [];

    while (startIntervalTime.valueOf() <= finishTime.valueOf()) {
      var format = startIntervalTime.valueOf();

      intervals.push({ t: format });
      startIntervalTime.add(dif, "ms");
      endIntervalTime.add(dif, "ms");
    }
    return intervals;
  };
  // const [time_intervals, setTimeIntervals] = useState([]);
  // if (time_intervals.length == 0) {
  // 	(async () => {
  // 		setTimeIntervals(prepareIntervals());
  // 	})();
  // }
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ["ChartItem", "FilterItem"],
    drop: (item) => {
      renderChart(item);
    },
    canDrop: (item, monitor) => checkDropFeasibility(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const [{ handlerId }, dropContainer] = useDrop({
    accept: "ChartContainer",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //     return;
      // }
      // Dragging upwards
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //     return;
      // }
      // Time to actually perform the action
      props.moveChart(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "ChartContainer",
    item: () => {
      return { id: props.chartData.chartId, index: props.index };
    },
    canDrag: () => !props.fromUrl,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(dropContainer(ref));

  const checkDropFeasibility = (item) => {
    if (item.data) {
      let source = isHostDrop ? item.data : props.data.source,
        qos = props.data.qosValue,
        target = isHostDrop ? props.data.target : item.data;
      if (
        chartData.filter(
          (d) => d.source == source && d.target == target && d.qos == qos
        ).length > 0
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return checkFilterDropFeasibility(item) ? true : false;
    }
  };
  const renderChart = (item) => {
    if (item.data) {
      setDropResult([...dropResult, item]);
    } else {
      renderFilterChart(item);
    }

    return item;
  };
  const renderFilterChart = (item) => {
    const fetchFilterData = async () => {
      setChartData([]);
      setChartObject({
        ...chartObject,
        filterId: item.filter.filterId,
        series: [],
      });
      let requestBody = [];
      item.filter.rowSelectors.rowSelector.forEach((input) => {
        let filter = {};
        filter.operator = input.logical;
        filter.negationIdentifier =
          input.not == true || input.not == "true" ? "not" : "";
        filter.field =
          input.field === "User Tag 1"
            ? "user_tag_1"
            : input.field === "User Tag 2"
              ? "user_tag_2"
              : input.field;
        filter.filterCondition =
          input.operator == "starts with"
            ? "starts"
            : input.operator == "ends with"
              ? "ends"
              : input.operator;
        filter.filterValue = input.value;
        requestBody.push(filter);
      });
      const response = await PRD.filterPRDOutput(
        requestBody,
        item.filter.filterId,
        true
      );
      setFilterResult(response.data);
    };

    fetchFilterData();
  };
  useEffect(() => {
    if (savedFilter != null) {
      renderFilterChart({ filter: savedFilter });
    }
  }, [savedFilter]);
  const checkFilterDropFeasibility = (item) => {
    if (chartObject.filterId) {
      return chartObject.filterId != item.filter.filterId;
    } else {
      return true;
    }
  };
  const getGraphColor = (color, index, isNewDrop) => {
    if (isNewDrop) {
      return randomColor[index] ? randomColor[index] : randomColoFn();
    } else {
      let selectedColor = randomColor[index]
        ? randomColor[index]
        : randomColoFn();
      if (color) {
        if (color.length > 0) {
          selectedColor = color;
        }
      }
      return selectedColor;
    }
  };

  const proceedForFetchOperation = (sqtObject) => {
    let proceed = true;
    //check if chart already has same source-qos-target data
    if (chartData.length > 0) {
      if (
        chartData.filter(
          (d) =>
            d.source == sqtObject.source &&
            d.target == sqtObject.target &&
            d.qos == sqtObject.qos
        ).length > 0
      ) {
        proceed = false;
      }
    }

    return proceed;
  };

  const computeSourceDropdownSelection = (source, target) => {
    if (chartData.length > 0) {
      let sourceRequestList = chartData.filter(
        (d) => d.source == chartData[0].source && d.qos == chartData[0].qos
      );
      let targetRequestList = chartData.filter(
        (d) => d.target == chartData[0].target && d.qos == chartData[0].qos
      );
      if (sourceRequestList.length == chartData.length) {
        setSelectedSource(source);
      } else if (targetRequestList.length == chartData.length) {
        setSelectedSource(target);
      } else {
        setSelectedSource("");
      }
    }
  };

  const updateChart = (
    isNewDrop,
    sqtObject,
    chartObject,
    chartData,
    seriesUrlRequests,
    finalSeriesChartData
  ) => {
    if (isNewDrop && chartObject.series) {
      let series = seriesUrlRequests.filter(
        (url) =>
          !chartObject.series.some(
            (series) =>
              url.source === series.source &&
              url.qos === series.qos &&
              url.target === series.target
          )
      );
      let refilteredChartData = finalSeriesChartData.filter(
        (d) =>
          !chartData.some(
            (series) =>
              d.source === series.source &&
              d.qos === series.qos &&
              d.target === series.target
          )
      );
      setChartObject({
        ...chartObject,
        series: [...chartObject.series, ...series],
      });
      setChartData([...chartData, ...refilteredChartData]);
      computeSourceDropdownSelection(sqtObject.source, sqtObject.target);
      setLoading(false);
    } else {
      if (
        selectedSource.length == 0 &&
        chartObject.series &&
        chartObject.series[0]
      ) {
        setChartObject({ ...chartObject, series: seriesUrlRequests });

        setChartData([...finalSeriesChartData]);
        computeSourceDropdownSelection(
          chartObject.series[chartObject.series.length - 1].displayName.split(
            "|"
          )[0],
          chartObject.series[chartObject.series.length - 1].displayName.split(
            "|"
          )[2]
        );
      } else if (chartObject.series) {
        setChartObject({ ...chartObject, series: seriesUrlRequests });

        setChartData([...finalSeriesChartData]);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isEqual(props.chartData, chartObject)) {
      console.log("New Chart object received.");

      if (props.chartData.series && props.chartData.series.length == 0) {
        setChartObject(props.chartData);
        setChartData([]);
        setSelectedSeries([]);
      } else {
        setChartObject(props.chartData);
        fetchData(false, props.chartData, chartData, {}, true);
      }
    }
  }, [props.chartData]);
  useEffect(() => {
    dispatch(addNewSeries(chartObject));
  }, [chartObject]);

  useEffect(() => {
    if (dropResult.length > 0) {
      let sqtObject = {
        source: isHostDrop
          ? dropResult[dropResult.length - 1].data
          : props.data.source,
        qos: props.data.qosValue,
        target: isHostDrop
          ? props.data.target
          : dropResult[dropResult.length - 1].data,
      };
      if (proceedForFetchOperation(sqtObject)) {
        setLoading(true);
        fetchData(true, chartObject, chartData, sqtObject, true);
        setTimeout(()=>{
          setShowChart(true);
        },1000);
      }
      setDropResult([]);
      setShowChart(false);
    }
  }, [dropResult]);

  useEffect(() => {
    // (async () => {
    // 	setTimeIntervals(prepareIntervals());
    // })();
    if (isEqual(props.chartData.series, chartObject.series)) {
      if (chartData.length > 0) {
        if (
          chartData[0].startTime != startTime ||
          chartData[0].stopTime != stopTime ||
          chartData[0].interval != aggregationInterval
        ) {
          setLoading(true);
          fetchData(false, chartObject, [], {}, true);
        }else if (chartObject.series ) {
          if (chartObject.series.length > 0) {
            setLoading(true);
            fetchData(false, chartObject, [], {}, true);
          }
        }
        //In case of import, the chartobject has already initialized with series but chartData requires updation
      } else if (chartObject.series) {
        if (chartObject.series.length > 0) {
          setLoading(true);
          if(is_updated)            
            fetchData(false, chartObject, [], {}, true);  
        }
      }
    }
  }, [startTime,is_updated, stopTime, aggregationInterval,isInterpolation]);

  const fetchData = async (
    isNewDrop,
    chartObject,
    chartData,
    sqtObject,
    updateRequired,
    resultCallback = null
  ) => {
    let source = undefined,
      qos = undefined,
      target = undefined,
      proceedForFetchOperation = true;

    if (isNewDrop) {
      source = sqtObject.source;
      qos = sqtObject.qos;
      target = sqtObject.target;
    }

    if (proceedForFetchOperation) {
      let seriesUrls = [],
        seriesUrlRequests = [];
      let match = undefined;
      let distinctUnits = [];
      //If timeStamp has been changed there is no need to fetch series info again
      //We will use the saved series urls and update the timestamps
      if (!isNewDrop) {
        seriesUrlRequests = chartObject.series; //?chartObject.series.filter(data=> data.displayName && (data.displayName.split('|')[0]==selectedSource)):[];
        seriesUrlRequests &&
          seriesUrlRequests.forEach((urlRequest, index) => {
            seriesUrls.push(
              PRD.getchartSeriesData({
                ...urlRequest,
                startTime: startTime ?? 0,
                stopTime: stopTime ?? 0,
                interval: aggregationInterval,
                source: urlRequest.displayName.split("|")[0],
                qos: urlRequest.displayName.split("|")[1],
                target: urlRequest.displayName.split("|")[2],
                pla: null,
                ptile: null,
                interpolateData:isInterpolation?isInterpolation:false
              })
            );
            urlRequest.startTime = startTime;
            urlRequest.stopTime = stopTime;
            urlRequest.interval = aggregationInterval;
            urlRequest.source = urlRequest.displayName.split("|")[0];
            urlRequest.qos = urlRequest.displayName.split("|")[1];
            urlRequest.target = urlRequest.displayName.split("|")[2];
            urlRequest.pla = null;
            urlRequest.ptile = null;
            urlRequest.graphColor = getGraphColor(
              seriesUrlRequests[index]["graphColor"],
              chartData.length + index,
              false
            );
          });
      } else {
        let chartInfoRequest = {
          chartId: props.chartData.chartId,
          source: source,
          qos: qos,
          target: target,
          graphColor: null,
          style: null,
          scale: null,
          pla: null,
          ptile: null,
        };
        let chartSeriesData = {};
        try {
          chartSeriesData = await PRD.getchartSeriesInfo(chartInfoRequest);
        } catch (e) {
          if (resultCallback != null) {
            resultCallback({ error: true });
          } else {
            setLoading(false);
            return;
          }
        }
        var x2js = new X2JS({
          arrayAccessFormPaths: ["data.series"],
        });
        var seriesJsonData = x2js.xml_str2json(chartSeriesData.data);
        // console.log(seriesJsonData)

        for (let i = 0; i < seriesJsonData.data.series.length; i++) {
          let urlRequest = {
            ...seriesJsonData.data.series[i],
            startTime: startTime,
            stopTime: stopTime,
            interval: aggregationInterval,
            pla: null,
            ptile: null,
            source: source,
            qos: qos,
            target: target,
            graphColor: getGraphColor(null, chartData.length + i, true),
            interpolateData:isInterpolation?isInterpolation:false,
          };
          seriesUrls.push(PRD.getchartSeriesData(urlRequest));
          seriesUrlRequests.push(urlRequest);
        }

        //check whether the new units from series matches to current saved series' units.
        //Maximum two units can be plotted
        distinctUnits = chartData
          .map((item) => item.unit)
          .filter((value, index, self) => self.indexOf(value) === index);

        if (distinctUnits.length >= 2) {
          let query = seriesJsonData.data.series;
          match = query.find((s) => distinctUnits.includes(s.unit));
        }
      }
      let isUnitCheckPassed = isNewDrop ? distinctUnits.length < 2 : true;
      if (match || isUnitCheckPassed) {
        let resolvedData = [];
        try {
          resolvedData = await Promise.all(seriesUrls);
        } catch (e) {
          if (resultCallback != null) {
            resultCallback({ error: true });
          } else {
            setLoading(false);
            return;
          }
        }

        let finalSeriesChartData = [];
        for (let i = 0; i < resolvedData.length; i++) {
          let chartData = {};
          if (
            resolvedData[i].data.samplingData &&
            resolvedData[i].data.samplingData != null &&
            (isArray(resolvedData[i].data.samplingData)
              ? resolvedData[i].data.samplingData.length > 0
              : false)
          ) {
            chartData = resolvedData[i].data;
          } else {
            chartData = {
              dataAvg: null,
              dataMin: null,
              dataMax: null,
              dataStdDev: null,
              samplingData: [],
            };
          }

          finalSeriesChartData.push({
            ...chartData,
            ...seriesUrlRequests[i],
            source: isNewDrop ? source : seriesUrlRequests[i].source,
            qos: isNewDrop ? qos : seriesUrlRequests[i].qos,
            target: isNewDrop ? target : seriesUrlRequests[i].target,
          });
        }

        if (updateRequired) {
          updateChart(
            isNewDrop,
            sqtObject,
            chartObject,
            chartData,
            seriesUrlRequests,
            finalSeriesChartData
          );
        } else {
          if (resultCallback != null) {
            resultCallback({
              seriesUrlRequests,
              finalSeriesChartData,
              error: false,
            });
          }
        }
      } else {
        if (resultCallback != null) {
          resultCallback({ error: true });
        } else {
          setLoading(false);
          return;
        }
      }
    }
    setDefaultChart(true);
  };
  const handleMultiSqtRequests = async (sqtList) => {
    setLoading(true);
    const callFetchData = (chartObject, chartData, sqtObject) => {
      return new Promise((resolve, reject) => {
        fetchData(true, chartObject, chartData, sqtObject, false, (result) => {
          if (result.error == true) {
            reject();
          } else {
            resolve(result);
          }
        });
      });
    };

    let clonedChartData = [...chartData];
    let clonedChartObject = { ...chartObject };
    const iterateAndUpdate = (index, chartObject, chartData) => {
      let sqtObject = sqtList[index];

      if (proceedForFetchOperation(sqtObject)) {
        //Call Fetch Data in Sequence to smooth rendering and state updations
        callFetchData(chartObject, chartData, sqtObject).then(
          (result) => {
            clonedChartObject = {
              ...clonedChartObject,
              series: [
                ...clonedChartObject.series,
                ...result.seriesUrlRequests,
              ],
            };
            clonedChartData = [
              ...clonedChartData,
              ...result.finalSeriesChartData,
            ];

            //call recursively till last element from array
            if (sqtList.length != index + 1) {
              iterateAndUpdate(index + 1, clonedChartObject, clonedChartData);
            } else {
              setChartObject(clonedChartObject);
              setChartData(clonedChartData);
              computeSourceDropdownSelection(
                sqtObject.source,
                sqtObject.target
              );
              setLoading(false);
            }
          },
          (error) => {
            //call recursively till last element from array
            if (sqtList.length != index + 1) {
              iterateAndUpdate(index + 1, clonedChartObject, clonedChartData);
            } else {
              setChartObject(clonedChartObject);
              setChartData(clonedChartData);
              setLoading(false);
            }
          }
        );
      } else {
        if (sqtList.length != index + 1) {
          iterateAndUpdate(index + 1, chartObject, chartData);
        } else {
          setChartObject(clonedChartObject);
          setChartData(clonedChartData);
          setLoading(false);
        }
      }
    };

    iterateAndUpdate(0, clonedChartObject, clonedChartData);
  };
  useEffect(() => {
    if (sourceDropdownState.chartData.length > 0) {
      let chartData = [];
      if (sourceDropdownState.isSourceDropdown)
        chartData = sourceDropdownState.chartData.filter(item => item.source === selectedSource);
      else
        chartData = sourceDropdownState.chartData.filter(item => item.target === selectedSource);
      console.log("sourceDropdownState",sourceDropdownState.chartData);
      let SqtList = [];
      for (let obj of chartData) {
        //Check whether it's search by host or target and prepare sqtObject i.e. Source,Qos,Target Object
        let sqtObject = {
          source: sourceDropdownState.isSourceDropdown
            ? selectedSource
            : obj.source,
          qos: obj.qos,
          target: sourceDropdownState.isSourceDropdown
            ? obj.target
            : selectedSource,
        };
        if (
          SqtList.find(
            (d) =>
              d.source == sqtObject.source &&
              d.target == sqtObject.target &&
              d.qos == sqtObject.qos
          ) == undefined
        ) {
          SqtList.push(sqtObject);
        }
      }
      handleMultiSqtRequests(SqtList);
    }
  }, [selectedSource]);
  useEffect(() => {
    if (multiSqtData != null) {
      let SqtList = [];
      for (let obj of multiSqtData) {
        //Check whether it's search by host or target and prepare sqtObject i.e. Source,Qos,Target Object
        let sqtObject = {
          source: isHostDrop ? obj : props.data.source,
          qos: props.data.qosValue,
          target: isHostDrop ? props.data.target : obj,
        };
        SqtList.push(sqtObject);
      }
      handleMultiSqtRequests(SqtList);
    }
  }, [multiSqtData]);
  useEffect(() => {
    if (filterResult.filterTargetDto) {
      let SqtList = [];
      for (let i = 0; i < filterResult.filterTargetDto.length; i++) {
        let dtoObject = filterResult.filterTargetDto[i];
        let sqtObject = {
          source: dtoObject.source,
          qos: dtoObject.qos,
          target: dtoObject.target,
        };

        SqtList.push(sqtObject);
      }
      if (SqtList.length > 0) {
        handleMultiSqtRequests(SqtList);
      }
    }
  }, [filterResult]);
  const isActive = canDrop && isOver;
  let border = "none";

  if (isActive) {
    border = "1px dashed #3272D9";
  }

  const findLineByLeastSquares = (values_x, values_y) => {
    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var sum_xx = 0;
    var count = 0;

    /*
     * We'll use those variables for faster read/write access.
     */
    var x = 0;
    var y = 0;
    var values_length = values_x.length;

    if (values_length != values_y.length) {
      throw new Error(
        "The parameters values_x and values_y need to have same size!"
      );
    }

    /*
     * Nothing to do.
     */
    if (values_length === 0) {
      return [[], []];
    }

    /*
     * Calculate the sum for each of the parts necessary.
     */
    for (var v = 0; v < values_length; v++) {
      x = values_x[v];
      y = values_y[v];
      sum_x += x;
      sum_y += y;
      sum_xx += x * x;
      sum_xy += x * y;
      count++;
    }

    /*
     * Calculate m and b for the formular:
     * y = x * m + b
     */
    var m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
    var b = sum_y / count - (m * sum_x) / count;

    /*
     * We will make the x and y result line now
     */
    var result_values_x = [];
    var result_values_y = [];

    for (var v = 0; v < values_length; v++) {
      x = values_x[v];
      y = x * m + b;
      result_values_x.push(x);
      result_values_y.push(y);
    }

    return result_values_y;
  };
  const getPercentileComputedArray = (
    percentileValue,
    finalArray,
    filteredChartData
  ) => {
    var data = [...finalArray];
    let isPercentileCalculationRequired = percentileValue != -1;
    let isTrendLineCalculationRequired = chartObject.trend == "true";
    if (isPercentileCalculationRequired || isTrendLineCalculationRequired) {
      let sortedArray = isPercentileCalculationRequired
        ? new Array(filteredChartData.length)
        : [];
      let trendArray = isTrendLineCalculationRequired
        ? new Array(filteredChartData.length)
        : [];
      let percentileIndex = 0.0;
      let num = 0;
      let fraction = 0.0;
      if (isPercentileCalculationRequired) {
        percentileIndex = (data.length - 1) * (percentileValue / 100) + 1;
        if (percentileIndex <= 1) {
          percentileIndex = 0;
        } else if (percentileIndex == data.length) {
          percentileIndex = data.length - 1;
        } else {
          num = Math.floor(percentileIndex);
          let int_part = Math.trunc(percentileIndex);
          fraction = Number((percentileIndex - int_part).toFixed(4));
        }
      }
      for (let i = 0; i < filteredChartData.length; i++) {
        if (isPercentileCalculationRequired) {
          sortedArray[i] = data
            .map((d) => d[`avg_${i}`])
            .sort(function (a, b) {
              return a - b;
            });
          if (num > 0) {
            if (fraction > 0) {
              let p_index =
                sortedArray[i][num - 1] +
                fraction * (sortedArray[i][num] - sortedArray[i][num - 1]);
              filteredChartData[i][`Percentile`] = p_index;
            } else {
              filteredChartData[i][`Percentile`] =
                sortedArray[i][percentileIndex];
            }
          } else {
            filteredChartData[i][`Percentile`] =
              sortedArray[i][percentileIndex];
          }
        }
        if (isTrendLineCalculationRequired) {
          trendArray[i] = findLineByLeastSquares(
            data.map((d) => Number(d[`t_${i}`])),
            data.map((d) => Number(d[`avg_${i}`]))
          );
        }
      }

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < filteredChartData.length; j++) {
          if (data[i][`avg_${j}`] != null) {
            if (isPercentileCalculationRequired) {
              if (num > 0) {
                if (fraction > 0) {
                  let p_index =
                    sortedArray[i][num - 1] +
                    fraction * (sortedArray[i][num] - sortedArray[i][num - 1]);
                  filteredChartData[i][`Percentile`] = p_index;
                } else {
                  filteredChartData[i][`Percentile`] =
                    sortedArray[i][percentileIndex];
                }
              } else {
                data[i][`percentile_${j}`] = sortedArray[j][percentileIndex];
              }
            }
            if (isTrendLineCalculationRequired) {
              data[i][`trendline_${j}`] = trendArray[j][i];
            }
          }
        }
      }
    }
    return data;
  };
  const memoData = useMemo(() => {
    let filteredChartData = [...chartData];
    let finalArray = [];
    let scaledValue =
      chartObject.series && chartObject.series.length > 0
        ? chartObject.series[0].scale && chartObject.series[0].scale.length > 0
          ? chartObject.series[0].scale
          : 1
        : 1;
    // filteredChartData = [...new Map(filteredChartData.map(item =>
    //     [item['unit'], item])).values()];
    let largeSampledChart =
      filteredChartData.length > 0 ? filteredChartData[0] : {};
    (async () => {
      let aggInterval = aggregationInterval;
      if (aggregationInterval == 0) {
        for (let i = 0; i < filteredChartData.length; i++) {
          let dataArray = filteredChartData[i].samplingData;
          if (dataArray && dataArray.length >= 2) {
            if (aggInterval === 0) {
              aggInterval = dataArray[1].t - dataArray[0].t;
            } else {
              let newAggInterval = dataArray[1].t - dataArray[0].t;
              aggInterval =
                newAggInterval < aggInterval ? newAggInterval : aggInterval;
            }
          }
        }
        aggInterval = Math.round(aggInterval / 1000);
      }
      for (let i = 0; i < filteredChartData.length; i++) {
        //dataInterPolation not required in UI as its done in Backend
        // filteredChartData[i].samplingData = dataInterPolation(
        //   filteredChartData[i].samplingData,
        //   aggInterval
        // );
        filteredChartData[i] = {
          ...filteredChartData[i],
          dataMin:
            filteredChartData[i].dataMin != null
              ? filteredChartData[i].dataMin * scaledValue
              : filteredChartData[i].dataMin,
          dataMax:
            filteredChartData[i].dataMax != null
              ? filteredChartData[i].dataMax * scaledValue
              : filteredChartData[i].dataMax,
          dataAvg:
            filteredChartData[i].dataAvg != null
              ? filteredChartData[i].dataAvg * scaledValue
              : filteredChartData[i].dataAvg,
          dataStdDev:
            filteredChartData[i].dataStdDev != null
              ? filteredChartData[i].dataStdDev * scaledValue
              : filteredChartData[i].dataStdDev,
        };
        largeSampledChart =
          filteredChartData[i].samplingData.length >
            largeSampledChart.samplingData
            ? filteredChartData[i]
            : largeSampledChart;
      }
      let samplingDataMap = [
        ...new Map(
          filteredChartData.map((item, index) => [index, item.samplingData])
        ).values(),
      ];

      let percentileValue =
        chartObject.series && chartObject.series.length > 0
          ? chartObject.series[0].pLine &&
            chartObject.series[0].pLine.percentile &&
            chartObject.series[0].pLine.percentile.length > 0
            ? chartObject.series[0].pLine.percentile
            : -1
          : -1;

      if (filteredChartData.length > 0) {
        //t_0 is considered as the x_axis values in QosChartView.js.
        //If first series object is null, we will update t_0_object from any non-null series
        // let t_0_Object=samplingDataMap.find(co=>co.find(d=>d.t!=undefined?d.t!=null:false)!=undefined);
        let t_0_Object = largeSampledChart.samplingData;
        // for(let i=0;i<largeSampledChart.samplingData.length;i++){
        //finalArray = [...time_intervals];
        finalArray = [];
        // let mergedObject = {};
        // for (let j = 0; j < samplingDataMap.length; j++) {
        // 	let i = 0;
        // 	for (let k = 0; k < samplingDataMap[j].length; k++) {
        // 		//	var target = moment(samplingDataMap[j][k].t);
        // 		while (i < finalArray.length - 1) {
        // 			//targetSampleData= finalArray.findIndex(d=>{
        // 			//var start = moment(finalArray[i].t);
        // 			//	var finish = moment(finalArray[i + 1].t);

        // 			//if (target.isBetween(start, finish, "milliseconds", "[]")) {
        // 			finalArray[i] = {
        // 				...finalArray[i],
        // 				[`avg_${j}`]: samplingDataMap[j][k].avg * scaledValue,
        // 				[`min_${j}`]: samplingDataMap[j][k].min * scaledValue,
        // 				[`max_${j}`]: samplingDataMap[j][k].max * scaledValue,
        // 				[`t_${j}`]: samplingDataMap[j][k].t,
        // 				[`cnt_${j}`]: samplingDataMap[j][k].cnt,
        // 				[`usm_min_max_${j}`]: [
        // 					samplingDataMap[j][k].min * scaledValue,
        // 					samplingDataMap[j][k].max * scaledValue,
        // 				],
        // 				["data_point"]: true,
        // 			};

        // 			i++;

        // 			//} else {

        // 			//}
        // 		}
        // 	}
        // }
        for (let i = 0; i < t_0_Object.length; i++) {
          let mergedObject = {};
          for (let j = 0; j < samplingDataMap.length; j++) {
            let mappedObject = {};
            if (samplingDataMap[j][i]) {
              mappedObject = {
                [`avg_${j}`]:
                  samplingDataMap[j][i].avg == null
                    ? null
                    : samplingDataMap[j][i].avg * scaledValue,
                [`min_${j}`]: (samplingDataMap[j][i].min !== undefined ? samplingDataMap[j][i].min : filteredChartData[j].dataMin) * scaledValue,
                [`max_${j}`]: (samplingDataMap[j][i].max !== undefined ? samplingDataMap[j][i].max : filteredChartData[j].dataMax) * scaledValue,
                [`t_${j}`]: samplingDataMap[j][i].t,
                [`cnt_${j}`]: samplingDataMap[j][i].cnt,
                [`usm_min_max_${j}`]: [
                  (samplingDataMap[j][i].min !== undefined ? samplingDataMap[j][i].min : filteredChartData[j].dataMin) * scaledValue,
                  (samplingDataMap[j][i].max !== undefined ? samplingDataMap[j][i].max : filteredChartData[j].dataMax) * scaledValue,
                ],
                "data_point": true,
                //[`percentile_${j}`]:percentileValue!=1?sortedArray[j][percentileIndex]:null
              };
            } else {
              mappedObject = {
                [`avg_${j}`]: null,
                [`min_${j}`]: null,
                [`max_${j}`]: null,
                [`t_${j}`]:
                  j == 0
                    ? t_0_Object != undefined
                      ? t_0_Object[i].t
                      : null
                    : null,
                [`cnt_${j}`]: null,
                [`percentile_${j}`]: null,
              };
            }
            mergedObject = {
              ...mergedObject,
              ...mappedObject,
            };
          }
          finalArray.push(mergedObject);
        }
        //finalArray = finalArray.filter((d) => d.data_point == true);

        finalArray = getPercentileComputedArray(
          percentileValue,
          finalArray,
          filteredChartData
        );
      }
    })();
    //console.log( "finalArray",finalArray)
    // console.log(filteredChartData)
    return { filteredChartData, finalArray };
  }, [chartData, selectedSource, chartObject, aggregationInterval]);

  filteredChartData = memoData.filteredChartData
    ? memoData.filteredChartData
    : [];
  finalArray = memoData.finalArray ? memoData.finalArray : [];

  const getSelectedCombos = () => {
    const shuffledCombos = strokeCombinations.sort(() => 0.5 - Math.random());
    let selectedCombos = shuffledCombos.slice(0, filteredChartData.length);
    let selectedComboscheckdup = selectedCombos.filter((value, index, self) => {
      if (index === self.findIndex((t) => (
        t.color === value.color && t.icon === value.icon
      )))
        return value.source;
    })
    if (selectedComboscheckdup?.length > 0)
      getSelectedCombos()
    else
      return selectedCombos;
  }

  useEffect(() => {
    //console.log("filteredChartData",filteredChartData);
    //console.log("dataCount",dataCount);
    let selectedCombos;
    if (filteredChartData.length === dataCount)
      selectedCombos = currentSelectedCombos;
    else {
      selectedCombos = getSelectedCombos();
      let values = { dataCount: filteredChartData.length, selectedCombos: selectedCombos }
      dispatch(setDataCount(values));
    }
    setCombos(selectedCombos);
  }, [filteredChartData])

  const handleChartMenuItemSelection = (menuItem) => {
    if (chartMenuOptions[0] == menuItem) {
      if (chartObject.stacked == "true") {
        setChartObject({ ...chartObject, stacked: "false" });
      } else {
        setChartObject({ ...chartObject, stacked: "true" });
      }
    } else if (chartMenuOptions[3] == menuItem && selectedSeries.length > 0) {
      let updatedSeries = chartObject.series;
      updatedSeries = updatedSeries.filter(
        (savedSeries) =>
          selectedSeries.find(
            (selectedNode) =>
              selectedNode.r_table == savedSeries.r_table &&
              selectedNode.table_id == savedSeries.table_id &&
              selectedNode.displayName == savedSeries.displayName &&
              selectedNode.qos_type == savedSeries.qos_type
          ) == undefined
      );

      setSelectedSeries([]);
      props.handdleSnackbar(true);
      fetchData(
        false,
        { ...chartObject, series: updatedSeries },
        chartData,
        {},
        true
      );
      setDefaultChart(false);
    } else if (chartMenuOptions[5] == menuItem) {
      setChartObject({ ...chartObject, series: [] });
      setChartData([]);
      setSelectedSeries([]);
    } else if (chartMenuOptions[7] == menuItem) {
      if (chartObject.trend == "true") {
        setChartObject({ ...chartObject, trend: "false" });
      } else {
        setChartObject({ ...chartObject, trend: "true" });
      }
    } else if (chartMenuOptions[8] == menuItem) {
      if (chartObject.baseline == "true") {
        setChartObject({ ...chartObject, baseline: "false" });
      } else {
        setChartObject({ ...chartObject, baseline: "true" });
      }
    }
  };

  return (
    <>
      <div
        ref={ref}
        className={`${!isChartExpanded
          ? "qos-chart-box"
          : props.fromUrl
            ? "qos-chart-box expanded-view url"
            : "qos-chart-box expanded-view"
          }`}
        data-handler-id={handlerId}
        style={{
          opacity: isDragging ? 0 : 1,
        }}
      >
        <div className="qos-chart-box-controls">
          <QosChartControlsToolbar
            onQosChartDelete={() => {
              props.onQosChartDelete();
            }}
            // sourceList={chartData.map(chart=>chart.source).filter((value, index, self) => self.indexOf(value) === index)}
            onSourceChange={(updatedSource, isSourceDropdown) => {
              setSourceDropdownState({
                isSourceDropdown,
                chartData: [...chartData],
              });
              setChartData([]);
              setChartObject({ ...chartObject, series: [] });
              setSelectedSource(updatedSource);
            }}
            selectedSource={selectedSource}
            handleChartObjectChange={(updatedChartObject) => {
              setChartObject(updatedChartObject);
            }}
            chartObject={chartObject}
            handleChartMenuItemSelection={handleChartMenuItemSelection}
            fromUrl={props.fromUrl}
            filteredChartData={filteredChartData}
            finalArray={finalArray}
            aggLevel={aggLevel}
            startTime={startTime}
            stopTime={stopTime}
            chartList={props.chartList}
            selectedSeries={selectedSeries}
            urlData={{
              chartData: { ...props.chartData },
              timeData: {
                aggregationInterval,
                aggLevel,
                timeFrame,
                startTime,
                stopTime,
              },
              ...props.data,
            }}
          />
        </div>
        <div ref={drop} className="qos-chart-box-content" style={{ border }}>
          {loading ? (
            <div
              className="no-data"
              style={{
                width: "100%",
                height: "90%",
                // height: isChartExpanded ? "80%" : "66%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress style={{ color: "#3272D9" }} />
            </div>
          ) : finalArray?.length == 0 &&
            defaultChart &&
            chartData.length > 0 ? (
            <>
              <div
                className="no-data"
                style={{
                  width: "100%",
                  //height: "60%",
                  height: props.fromUrl ? "55%": isChartExpanded ? "80%" : "65%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                <div>
                  <img
                    src={DataisnotAvailable}
                    alt="Data is not available"
                    className="Dataisnotavailable"
                  />
                </div>
                <div>Data is not available</div>
              </div>
              {chartObject.showLegend == "false" && (
                <div
                  className="qos-chart-table"
                  style={{ height: isChartExpanded ? "20vh" : "40vh" }}
                >
                  <CustomDataGrid
                  isBlank = {true}
                  chartData={filteredChartData}
                  isChartExpanded={isChartExpanded}
                  selectedSeries={selectedSeries}
                  chartObject={chartObject}
                  handleSelectedSeries={(list) => setSelectedSeries(list)}
                  />
                </div>
              )}
            </>
          ) : showChat || chartData.length > 0 ? (
            <>
              <div
                className="qos-chart-div"
                style={{
                  height:
                    chartObject.showLegend == "true"
                      ? "90%"
                      : isChartExpanded
                        ? "calc(100% - 1vh)"
                        : "calc(100% - 1vh)",
                  // : "calc(100% - 11vh)",
                }}
                tabIndex={0}
                role="img"
                aria-label={"A chart showing qos performance data"}
              >
                <ChartView
                  filteredChartData={filteredChartData}
                  finalArray={finalArray}
                  aggLevel={aggLevel ? aggLevel : "Automatic"}
                  startTime={startTime}
                  stopTime={stopTime}
                  chartObject={chartObject}
                  isChartExpanded={isChartExpanded}
                  selectedCombos={combos}
                  showMaxMin={showMaxMin}
                  changeMaxMin={changeMaxMin}
                  handleSelectedSeries={(list) => setSelectedSeries(list)}
                  selectedSeries={selectedSeries}
                />
              </div>

              {/* {chartObject.showLegend == "false" && (
                <div
                  className="qos-chart-table"
                  style={{ height: isChartExpanded ? "12vh" : "12vh" }}
                >
                  <QosChartTable
                    chartData={filteredChartData}
                    isChartExpanded={isChartExpanded}
                    selectedSeries={selectedSeries}
                    chartObject={chartObject}
                    handleSelectedSeries={(list) => setSelectedSeries(list)}
                  />
                </div>
              )} */}
            </>
          ) : props.fromUrl ? null : (
            <Typography
              variant="caption"
              classes={{ caption: "create-chart-text" }}
              role="region"
            >
              <pre>{selectChartText}</pre>
            </Typography>
          )}
        </div>
      </div>
    </>
  );
};

export default QosChart;
