/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  FETCH_MAINTENANCE,
  FETCH_MAINTENANCE_SUCCESS,
  FETCH_MAINTENANCE_FAILURE,
  FETCH_SCHEDULE_DEVICES,
  FETCH_SCHEDULE_DEVICES_SUCCESS,
  FETCH_SCHEDULE_DEVICES_FAILURE,
  FETCH_GROUP_DEVICES,
  FETCH_GROUP_DEVICES_SUCCESS,
  FETCH_GROUP_DEVICES_FAILURE,
  FETCH_TIMEZONES_SUCCESS,
  FETCH_TIMEZONES_FAILURE,
  FETCH_CS_MAINTENANCE_LIST,
  FETCH_CS_MAINTENANCE_LIST_SUCCESS,
  FETCH_CS_MAINTENANCE_LIST_FAILURE,
} from "./actionTypes";
import { isEqual } from "../../utils/lodash-utils";

const maintenance = (
  state = {
    didInvalidate: false,
    isFetching: false,
    items: [],
    devices: [],
    isFetchingDevices: false,
    didDevicesInvalidate: false,
    groupDevices: [],
    isFetchingGroupDevices: false,
    didGroupDevicesInvalidate: false,
    timezones: [],
    deviceMaintenance: [],
    isFetchingMntn: false,
    didInvalidateMntn: false,
    schedules: [],
    mntnError: "",
  },
  action
) => {
  switch (action.type) {
    case FETCH_MAINTENANCE:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
    case FETCH_MAINTENANCE_SUCCESS:
      const items = action.maintenance._items[0];
      const updatedState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: items,
      });
      return isEqual(updatedState, state) ? state : updatedState;
    case FETCH_MAINTENANCE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        errorMessage: action.errorMessage,
        items: [],
      });

    case FETCH_SCHEDULE_DEVICES:
      return Object.assign({}, state, {
        isFetchingDevices: true,
        didDevicesInvalidate: false,
      });
    case FETCH_SCHEDULE_DEVICES_SUCCESS:
      const devices = action.devices._items;
      const updatedDeviceState = Object.assign({}, state, {
        isFetchingDevices: false,
        didDevicesInvalidate: false,
        devices: devices,
      });
      return isEqual(updatedDeviceState, state) ? state : updatedDeviceState;
    case FETCH_SCHEDULE_DEVICES_FAILURE:
      return Object.assign({}, state, {
        isFetchingDevices: false,
        didDevicesInvalidate: true,
        errorMessage: action.errorMessage,
        devices: [],
      });
    case FETCH_GROUP_DEVICES:
      return Object.assign({}, state, {
        isFetchingGroupDevices: true,
        didGroupDevicesInvalidate: false,
      });
    case FETCH_GROUP_DEVICES_SUCCESS:
      const groupDevices = action.devices._items;
      const updatedGroupDeviceState = Object.assign({}, state, {
        isFetchingDevices: false,
        didGroupDevicesInvalidate: false,
        groupDevices: groupDevices,
      });
      return isEqual(updatedGroupDeviceState, state)
        ? state
        : updatedGroupDeviceState;
    case FETCH_GROUP_DEVICES_FAILURE:
      return Object.assign({}, state, {
        isFetchingGroupDevices: false,
        didGroupDevicesInvalidate: true,
        errorMessage: action.errorMessage,
        groupDevices: [],
      });

    case FETCH_TIMEZONES_SUCCESS:
      return Object.assign({}, state, {
        timezones: action.timezones,
      });
    case FETCH_TIMEZONES_FAILURE:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
      });
    case FETCH_CS_MAINTENANCE_LIST:
      let initialState = Object.assign({}, state, {
        isFetchingMntn: true,
        didInvalidateMntn: false,
        mntnError: "",
      });
      return isEqual(newState, state) ? state : initialState;
    case FETCH_CS_MAINTENANCE_LIST_SUCCESS:
      let updateState = Object.assign({}, state, {
        isFetchingMntn: false,
        didInvalidateMntn: false,
        schedules: action.schedules,
        mntnError: "",
      });
      return isEqual(newState, state) ? state : updateState;
    case FETCH_CS_MAINTENANCE_LIST_FAILURE:
      let newState = Object.assign({}, state, {
        isFetchingMntn: false,
        didInvalidateMntn: true,
        mntnError: action.errorMessage,
      });
      return isEqual(newState, state) ? state : newState;

    default:
      return state;
  }
};

export default maintenance;
