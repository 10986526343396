/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import { capitalize } from "./../../utils/lodash-utils";
import { SET_ENTITIES, REMOVE_ENTITIES, ADD_ENTITIES } from "./actionTypes";
import Resource from "./Resource";

export function cleanItems(itemsInputed) {
  let max = 0;
  const items = itemsInputed
    .map((item) => {
      if (item && !item.alarmCountSummary) {
        return item;
      }
      const alarmCountSummary = Object.keys(item.alarmCountSummary).map(
        function (key) {
          let name = key === "information" ? "info" : key;
          let cnt = item.alarmCountSummary[key];
          max = max >= cnt ? max : cnt;
          return { name: capitalize(name), cnt: cnt };
        }
      );
      let maxAlarmSeverity = 0;
      if (alarmCountSummary[0].cnt > 0) {
        maxAlarmSeverity = {
          //CRITICAL
          label: Resource.get("CRITICAL"),
          value: 5,
          cnt: alarmCountSummary[0].cnt,
        };
      } else if (alarmCountSummary[1].cnt > 0) {
        maxAlarmSeverity = {
          //MAJOR
          label: Resource.get("MAJOR"),
          value: 4,
          cnt: alarmCountSummary[1].cnt,
        };
      } else if (alarmCountSummary[2].cnt > 0) {
        maxAlarmSeverity = {
          //MINOR
          label: Resource.get("MINOR"),
          value: 3,
          cnt: alarmCountSummary[2].cnt,
        };
      } else if (alarmCountSummary[3].cnt > 0) {
        maxAlarmSeverity = {
          //WARNING
          label: Resource.get("WARNING"),
          value: 2,
          cnt: alarmCountSummary[3].cnt,
        };
      } else if (alarmCountSummary[4].cnt > 0) {
        maxAlarmSeverity = {
          // INFO
          label: Resource.get("INFO"),
          value: 1,
          cnt: alarmCountSummary[4].cnt,
        };
      } else {
        maxAlarmSeverity = {
          // NONE
          label: Resource.get("NONE"),
          value: 0,
          cnt: 0,
        };
      }
      var totalAlarmCount = alarmCountSummary.reduce(function (total, alarm) {
        return alarm.cnt + total;
      }, 0);
      return Object.assign({}, item, {
        alarmCountSummary: alarmCountSummary,
        maxAlarmSeverity: maxAlarmSeverity,
        totalAlarmCount: totalAlarmCount,
      });
    })
    .map((item) => Object.assign(item, { maxAlarmCount: max }));
  return items;
}

const entities = (state = [], action) => {
  switch (action.type) {
    case SET_ENTITIES:
      return cleanItems(action.items);

    case ADD_ENTITIES:
      let addItems = cleanItems(action.items);
      return state.concat(addItems);

    case REMOVE_ENTITIES:
      const deletedEntities = action.items;
      const remainingEntities = state.filter((entity) => {
        return !deletedEntities.includes(entity.id);
      });
      return remainingEntities;
    default:
      return state;
  }
};

export default entities;
