/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import { call, put, take, takeEvery, takeLatest, cancel, delay } from "redux-saga/effects";
import {
  POLL_GROUPS_START,
  POLL_GROUPS_STOP,
  REQUEST_GROUP,
  FETCH_TOP_SUB_GROUP,
  POLL_TOP_SUB_GROUP_START,
  POLL_TOP_SUB_GROUP_STOP,
} from "./actionTypes";
import entityTypes from "./../entityTypes";
import { Group } from "./groups";
import {
  fetchGroupsSuccess, fetchGroupsFailure, fetchTopSubGroupsFailure,
  fetchTopSubGroupsSuccess
} from "./actions";
import { setEntities, setEntity } from "./../../components/entities/actions";

const isComputerSystemContainer = (entity) => {
  return (
    entity &&
    entity.type !== entityTypes.ROOT &&
    entity.type !== entityTypes.CONTAINER &&
    !entity.masterElementGroup
  );
};
const isInterfaceContainer = (entity) => {
  return (
    entity &&
    entity.type !== entityTypes.ROOT &&
    entity.type !== entityTypes.CONTAINER &&
    entity.masterElementGroup
  );
};
const getEntities = (group) => {
  let entityKey = isComputerSystemContainer(group)
    ? "computer-systems"
    : isInterfaceContainer(group)
    ? "interfaceSystems"
    : "groups";
  if (entityKey == "interfaceSystems" && !group.interfaceSystems) {
    entityKey = "interfaceSystem";
  }
  const entityType = isComputerSystemContainer(group)
    ? entityTypes.COMPUTER_SYSTEM
    : isInterfaceContainer(group)
    ? entityTypes.INTERFACES
    : entityTypes.GROUP;
  const items =
    group[entityKey] &&
    group[entityKey].map((item) => {
      return Object.assign({}, item, {
        entityType: entityType,
      });
    });
  return items;
};
const getEntity = (group) =>
  Object.assign({}, group, {
    entityType: entityTypes.GROUP,
  });

function* fetchGroups(params) {
  try {
    const response = yield call(Group.get, params);
    yield put(fetchGroupsSuccess(response.data));
    const entities = getEntities(response.data);
    yield put(setEntities(entities));
    //if (params.fromTree) {
      yield put(setEntity(getEntity(response.data)));
    //}
    return response;
  } catch (error) {
   // console.log("error in fetching data", error);
    yield put(fetchGroupsFailure(error));
  }
}

function* pollGroups(action) {
  if (action.fromTree) {
    yield call(fetchGroups, { id: action.id, fromTree: action.fromTree });
  }
  while (true) {
    yield delay(30000);
    yield call(fetchGroups, { id: action.id });
  }
}

export function* groupsPollingSaga() {
  while (true) {
    const watcherInstance = yield takeLatest(POLL_GROUPS_START, pollGroups);
    // cancel polling on POLL_GROUPS_STOP
    yield take(POLL_GROUPS_STOP);
    yield cancel(watcherInstance);
  }
}

function* fetchGroupSetEntities(action) {
  try {
    const response = yield call(Group.get, action.params);
    const group = response.data;
    yield put(fetchGroupsSuccess(group));
    yield put(setEntities(getEntities(group)));
    yield put(setEntity(getEntity(group)));
    return response;
  } catch (error) {
    yield put(fetchGroupsFailure(error));
  }
}

export function* fetchGroupSetEntitiesSaga() {
  yield takeLatest(REQUEST_GROUP, fetchGroupSetEntities);
}


 //------------------------------------ TopSubGroups Polling Functions----------------------------
 function* fetchTopSubGroupspoll(params) {

  try {
    const response = yield call(Group.getTopSubGroupsByDeviceCount, params)
    yield put(fetchTopSubGroupsSuccess(response.data))
  } catch (error) {
    yield put(fetchTopSubGroupsFailure(error))
  }
}

function* pollTopSubGroups(action) {
  while (true) {
    yield call(fetchTopSubGroupspoll,{
      groupId:action.groupId,
    })
    yield delay(30000)

  }
}

export function* topSubGroupsRequestSaga() {
  yield takeEvery(FETCH_TOP_SUB_GROUP, fetchTopSubGroupspoll)
}

export function* topSubGroupsPollingSaga() {
  while (true) {
    const watcherInstance = yield takeLatest(POLL_TOP_SUB_GROUP_START, pollTopSubGroups)
    // cancel polling on POLL_HUBS_STOP
    yield take(POLL_TOP_SUB_GROUP_STOP)
    yield cancel(watcherInstance)
  }
}
