/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import { ClearIcon, SearchIcon } from "../ui-components/uim-components";

import { ReactComponent as DangerIcon } from "../settings/portlets/performanceReports/PerformanceLayout/qosData/icons/ic_danger.svg";
import Resource from './Resource';
import { Cross as CloseIcon } from '@mineral/icons';
import {
  FormControlLabel,
  Button,
  Tooltip,
  IconButton,
  OutlinedInput, DialogTitle, DialogContent,
  Checkbox,
  RadioGroup as RadioButtonGroup,
  Radio as RadioButton,
  Drawer, Select as DropDownMenu,
  MenuItem,
  Button as FlatButton,
  Dialog,
  DialogActions,
  Tabs,
  Tab,
  Snackbar, Popover, Typography, InputLabel, FormControl
} from "@mineral/core";
import AddIcon from "@material-ui/icons/Add";
import Alert from "@material-ui/lab/Alert";
import SelectionRedux from "./components/selection/SelectionRedux";
import MetricsRedux from "./components/MetricsRedux";
import Nav from "./components/Nav";
import Group from "./components/group/Group";
// import MetricChart from "./components/MetricCharts";
import Chart from "./components/MineralMetricChart/Chart";
import { isEqual } from "../../utils/lodash-utils";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  resetChartData,
  fetchAllMetric,
  saveMetric,
  copyMetrics
} from "./api/deviceMetrics/actions";
import { Metric as metricApi } from "./api/deviceMetrics/metric";
import { Account as accountApi } from "../../api/account/account";
import "./MetricPalette.less";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CopyIcon from "./../icons/Copy";
import MetricViewCopyDialog from "./MetricViewCopyDialog";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";


// Create view => viewAction 1
// Edit View => viewAction 2
// Manage View => viewAction 3

export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

class MetricPaletteViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      drawerIsClosing: true,
      viewName: `Metric View 1 - ${props.name}`,
      isDefaultView: false,
      publishView: "Private",
      viewAction: 1,
      tabValue: 1,
      selectedMetric: { name: "", id: null },
      viewDetails: {
        viewName: `Metric View 1 - ${props.name}`,
        isDefaultView: false,
        publishView: "Private",
        id: "null",
        accountId:"",
      },
      viewList: this.formatNamedList(props.namedMetricList),
      savedViewName: "",
      openSnackBar: false,
      snackBarMessage: "",
      showMenu: false,
      showCopy: false,
      copyMetricName: "",
      savedViewSearch: "",
      openDefaultConf: false,
      cbData: {},
      cbVal: false,
      fromMngView: false,
      menuCopyAnchorEl: null,
      menuMoreAnchorEl: null,
      viewType: "",
      selectedIndex: 0,
      selectedViewData: {},
      viewTypePersist: "",
      openCopyMetricSuccess: false,
      isCopyDialogOpen: false,
      persistDefaultVal: false,
      accountList: props.account.accounts,
      accountName:props.acl.aclInfo.accountName,
      showDeleteDialog:false,
      showCloseMetricsCreateConfirmation:false
    };
    this.handleRequestChange = this.handleRequestChange.bind(this);
  }

  componentWillUnmount() {
    sessionStorage.removeItem('metricsChartDatePickerOpen');
  }

  componentDidMount() {
    accountApi
      .getAccounts()
      .then((response) => {
        this.setState({ accountList: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  toggleDrawer = (isClicked) => {
    this.setState({
      open: isClicked ? false : !this.state.open,
      drawerIsClosing: isClicked,
    });
    isClicked && sessionStorage.removeItem('selectedCsIds');
    isClicked && sessionStorage.removeItem('filteredDevices');
  };
  ViewNameChange = (e) => {
    let viewName = e.target.value;
    let { viewDetails } = this.state;
    let isDefaultView = viewDetails.isDefaultView;
    let publishView = viewDetails.publishView;
    let id = viewDetails.id;
    let accountId = viewDetails.accountId;
    viewDetails = Object.assign({}, viewDetails, {
      viewName,
      isDefaultView,
      publishView,
      id,
      accountId,
    });
    this.setState({
      viewDetails,
    });
  };
  updateCheck = (selected) => {
    let { viewDetails } = this.state;
    let viewName = viewDetails.viewName;
    let isDefaultView = selected;
    let publishView = viewDetails.publishView;
    let id = viewDetails.id;
    let accountId = viewDetails.accountId;
    viewDetails = Object.assign({}, viewDetails, {
      viewName,
      isDefaultView,
      publishView,
      id,
      accountId,
    });
    this.setState({
      viewDetails,
      openDefaultConf: false,
      cbVal: false,
      fromMngView: false,
    });
  };
  handlePublishView = (event) => {
    let value = event.target.value;
    let { viewDetails } = this.state;
    let viewName = viewDetails.viewName;
    let isDefaultView = viewDetails.isDefaultView;
    let publishView = value;
    let id = viewDetails.id;
    let accountId = viewDetails.accountId;
    viewDetails = Object.assign({}, viewDetails, {
      viewName,
      isDefaultView,
      publishView,
      id,
      accountId,
    });
    this.validateSelAccountName(value,viewDetails);
  };

  handleAccountNameChange = (event) => {
    let value = event.target.value;
    let  newViewDetails = {...this.state.viewDetails,accountId:value}
    this.setState({viewDetails:newViewDetails});
  };

  formatNamedList = (namedMetricList) => {
    let views = namedMetricList?.MyViews;
    views = views?.length
      ? views.map((item) => {
          return {
            ...item,
            showMenu: false,
            showCopy: false,
            showCopyMenu: false,
          };
        })
      : [];
    let sharedViews = namedMetricList?.SharedViews;
    sharedViews = sharedViews?.length
      ? sharedViews.map((item) => {
          return {
            ...item,
            showMenu: false,
            showCopy: false,
            showCopyMenu: false,
          };
        })
      : [];
    this.setState({
      viewList: {
        MyViews: views,
        SharedViews: sharedViews,
      },
    });
  };

  componentWillReceiveProps(nextProps) {
    // if (!arraysEqual(nextProps.devices.map(i => i?.id), this.props.devices.map(i => i?.id))) {
    //   this.props.resetChartData();
    // }

    if (!isEqual(nextProps.namedMetricList, this.props.namedMetricList)) {
      this.formatNamedList(nextProps.namedMetricList);
      // this.setState({
      //   viewList:nextProps.namedMetricList
      // })
    }
    if (!isEqual(nextProps.deleteMsg, this.props.deleteMsg)) {
      this.setState({
        snackBarMessage: nextProps.deleteMsg.msg,
        openSnackBar: true,
      });
    }
    if (!isEqual(nextProps.isUpdateView, this.state.isUpdateView)) {
      if (!nextProps.isSaving) {
        this.setState({
          snackBarMessage: nextProps.isUpdateView?"View Updated Successfully":"View Created Successfully",
          openSnackBar: true,
          isUpdateView: nextProps.isUpdateView,
        });
      } else {
        this.setState({
          isUpdateView: nextProps.isUpdateView,
        });
      }
    }
    if (this.props.name != nextProps.name) {
      this.setState({
        viewDetails: {
          viewName: `Metric View 1 - ${nextProps.name}`,
          isDefaultView: false,
          publishView: "Private",
          id: "null",
          accountId:"",
        },
      });
    }
    /*  if(!isEqual(nextProps.isCopyDeviceMetricSuccess, this.state.openCopyMetricSuccess) && this.state.isCopyDialogOpen){


     } */
  }

  isViewChanged = () => {
    this.setState({ viewAction: 2, isUpdateView: null });
  };
  whichViewClicked = (payload) => {
    this.setState({
      viewAction: payload,
    });
    sessionStorage.getItem("newCalled") &&
      this.setState({
        viewDetails: {
          viewName: `Metric View 1 - ${this.props.name}`,
          isDefaultView: false,
          publishView: "Private",
          id: "null",
          accountId:"",
        },
      });
  };
  drawerTitle = () => {
    const { viewAction } = this.state;
    if (viewAction === 1) {
      return "Create Metrics View";
    } else if (viewAction === 2) {
      return "Edit Metrics View";
    }
    return "Manage Metrics View";
  };
  createView = () => {
    const { viewAction } = this.state;
    this.setState({ viewAction: 1 });
    this.toggleDrawer();
  };
  manageViews = () => {
    const { viewAction } = this.state;
    this.setState({ viewAction: 3 });
  };
  loadedView = () => {
    this.setState({ viewAction: 4 });
  };
  handleTabChange = (event, newTab) => {
    this.setState({ tabValue: newTab, savedViewSearch: "" });
  };
  handleSearchChange = (evt) => {
    this.setState({
      savedViewSearch: evt.target.value,
    });
  };
  handleOpen = (evt, value, index, viewType) => {
    this.setState({
      openDefaultConf: true,
    });
  };

  handleClose = () => {
    this.setState({
      openDefaultConf: false,
      cbData: {},
      cbVal: false,
      fromMngView: false,
    });
  };
  editDefaultChkBox = (evt, value, index, viewType) => {
    let { viewList } = this.state;
    /* let MyViews = viewList.MyViews;
    let SharedViews = viewList.SharedViews;
     let oldDefaultVal = false;
    if (viewType === "MyViews") {
      oldDefaultVal=  MyViews[index].isDefault == 0 ? false :
        MyViews[index].isDefault == 1 ? true: MyViews[index].isDefault;
      MyViews[index] = {...MyViews[index], isDefault: value};
    } else {
      oldDefaultVal=  SharedViews[index].isDefault == 0 ? false :
      SharedViews[index].isDefault == 1 ? true: SharedViews[index].isDefault;
      SharedViews[index] = {...SharedViews[index], isDefault: value};
    }
    viewList = Object.assign({}, viewList, {MyViews, SharedViews}); */
    this.setState({
      viewList,
      openDefaultConf: false,
      cbData: {},
      fromMngView: false,
      persistDefaultVal: value,
    });
  };
  handlePublishViewChange = (evt, index, viewType) => {
    let { viewList } = this.state;
    const MyViews = viewList.MyViews;
    const SharedViews = viewList.SharedViews;
    let newViewData = Object.assign({}, this.state.selectedViewData, {
      viewType: evt.target.value,
    });
    if (viewType === "MyViews") {
      MyViews[index] = { ...MyViews[index], viewType: evt.target.value };
    } else {
      SharedViews[index] = {
        ...SharedViews[index],
        viewType: evt.target.value,
      };
    }
    viewList = Object.assign({}, viewList, { MyViews, SharedViews });
    this.setState({
      viewList,
      selectedViewData: newViewData,
    });
  };
  createNewView = () => {
    const { viewAction } = this.state;
    this.setState({
      viewAction: 1,
      viewDetails: {
        viewName: `Metric View 1 - ${this.props.name}`,
        isDefaultView: false,
        publishView: "Private",
        id: "null",
        accountId:"",
      },
    });
  };
  handleCloseSnackBar = () => {
    this.setState({ openSnackBar: false });
  };

  onMetricClicked = (value) => () => {
    this.props.fetchMetricById(value).then(() => {
      let metricDef = JSON.parse(this.props.namedMetricData.definition);
      let selectedCIs = {};
      let mtrIds = [];
      metricDef.metrics.map((mtr) => {
        selectedCIs[mtr.metricTypeId] = mtr.ciNames;
        mtrIds.push(mtr.metricTypeId);
      });
      this.props.getMetricForDevices(
        metricDef.csIds.join(),
        mtrIds,
        this.props.timerange,
        selectedCIs,
        this.props.deviceMetrics.isInterface,
        this.props.timerangeEndDate
      );
    });
    this.setState({ selectedMetric: value, isOpen: false });
  };
  deleteView = (id) => {
    metricApi.deleteNamedViews(id).then((response) => {
      this.setState({
        snackBarMessage: response.data,
        openSnackBar: true,
      });
      let body = {};
      if(this.props.isInterface){
        body.masterId=this.props.masterId
        body.csId=this.props.contextValue
      } else if (this.props.fromCsView) {
        body.csId = this.props.contextValue;
      } else if (this.props.fromGroup) {
        body.groupId = this.props.groupId;
      }
      this.props.fetchAllMetric(body);
    });
    //this.toggleDrawer();
  };
  handleEdit = (index, type) => {
    this.handleMoreMenuClose();
    let { viewList } = this.state;
    const MyViews = viewList.MyViews;
    const SharedViews = viewList.SharedViews;
    let data;
    if (type === "MyViews") {
      data = MyViews[index];
      MyViews[index] = {
        ...MyViews[index],
        showMenu: false,
        showCopy: false,
        isDefault: this.state.persistDefaultVal,
      };
    } else {
      data = SharedViews[index];
      SharedViews[index] = {
        ...SharedViews[index],
        showMenu: false,
        showCopy: false,
        isDefault: this.state.persistDefaultVal,
      };
    }
    let reqBody = {
      name: data.name,
      isDefault: this.state.persistDefaultVal ? 1 : 0,
      viewType: data.viewType,
      id: data.id,
      isGoldenMetricView: 1,

      definition: data.definition,
    };
    if(this.props.isInterface){

      reqBody={...reqBody, masterId:this.props.masterId,csId:this.props.contextValue}
    }
    else
    {
      reqBody={...reqBody, csId: this.props.fromCsView ? this.props.contextValue : null,
      groupId: this.props.fromGroup ? this.props.groupId : null}
    }
    this.props.saveMetric(reqBody, true);
    viewList = Object.assign({}, viewList, { MyViews, SharedViews });
    this.setState({
      viewList,
      viewTypePersist: data.viewType,
      //persistDefaultVal: data.isDefault,
    });
  };
  getViewDetails = (payload) => {
    const value = payload;
    let { viewDetails } = this.state;
    const savedViewName = payload.name;
    viewDetails = Object.assign({}, viewDetails, {
      viewName: payload.name,
      isDefaultView: payload.isDefault,
      publishView: payload.viewType,
      id: payload.id,
      accountId:payload.accountId,
    });
    this.setState({
      viewDetails,
      savedViewName,
    });
  };
  handleRequestChange = (index, value, viewType, data) => {
    let { viewList } = this.state;
    let MyViews = viewList.MyViews;
    let SharedViews = viewList.SharedViews;
    let existViewType = "";
    if (!value) {
      if (viewType === "MyViews") {
        MyViews[index] = {
          ...MyViews[index],
          viewType: this.state.viewTypePersist,
        };
      } else {
        SharedViews[index] = {
          ...SharedViews[index],
          viewType: this.state.viewTypePersist,
        };
      }
    } else {
      existViewType = data.viewType;
    }
    //viewList = Object.assign({}, viewList, { MyViews, SharedViews });

    if (viewType === "MyViews") {
      MyViews[index] = { ...MyViews[index], showMenu: value };
    } else {
      SharedViews[index] = { ...SharedViews[index], showMenu: value };
    }
    viewList = Object.assign({}, viewList, { MyViews, SharedViews });
    this.setState({
      viewList,
      viewType: viewType,
      selectedIndex: index,
      selectedViewData: data,
      viewTypePersist: existViewType,
      persistDefaultVal: data
        ? data.isDefault == 1 || data.isDefault == true
          ? true
          : false
        : false,
    });
    if (!value) {
      this.handleMoreMenuClose();
    }
  };

  handleCopyClick = (index, value, viewType, data) => {
    let { viewList } = this.state;
    let MyViews = viewList.MyViews;
    let SharedViews = viewList.SharedViews;
    if (viewType === "MyViews") {
      MyViews[index] = { ...MyViews[index], showCopyMenu: value };
    } else {
      SharedViews[index] = { ...SharedViews[index], showCopyMenu: value };
    }
    viewList = Object.assign({}, viewList, { MyViews, SharedViews });
    this.setState({
      viewList,
      viewType: viewType,
      selectedIndex: index,
      selectedViewData: data,
    });
    if (!value) {
      this.handleCopyMenuClose(false);
    }
  };

  handleCopyMetricName = (event) => {
    this.setState({ copyMetricName: event.target.value });
  };

  copyMetric = (index, viewType) => {
    let { viewList } = this.state;
    let MyViews = viewList.MyViews;
    let SharedViews = viewList.SharedViews;
    let selectedViewId;
    if (viewType === "MyViews") {
      selectedViewId = MyViews[index].id;
    } else {
      selectedViewId = SharedViews[index].id;
    }
    this.props
      .copyMetrics(selectedViewId, this.state.copyMetricName)
      .then(() => {
        if (this.props.isCopySuccess) {
          if (viewType === "MyViews") {
            MyViews[index] = { ...MyViews[index], showCopyMenu: false };
          } else {
            SharedViews[index] = { ...SharedViews[index], showCopyMenu: false };
          }
          viewList = Object.assign({}, viewList, { MyViews, SharedViews });
          this.setState({
            snackBarMessage: "Metrics Copied Successfully",
            openSnackBar: true,
            copyMetricName: "",
            viewList,
          });
          let body = {};
          if(this.props.isInterface){
            body.masterId=this.props.masterId
            body.csId=this.props.contextValue
          }
          if (this.props.fromCsView) {
            body.csId = this.props.contextValue;
          } else if (this.props.fromGroup) {
            body.groupId = this.props.groupId;
          }
          this.props.fetchAllMetric(body);
        } else {
          this.setState({
            snackBarMessage:
              "This view name already exists. Please create a different view name",
            openSnackBar: true,
          });
        }
      });
  };

  handleCopyMenuClick = (event) => {
    this.setState({
      menuCopyAnchorEl: event.currentTarget,
      openCopyMetricSuccess: false,
      isCopyDialogOpen: true,
    });
  };
  handleCopyMenuClose = (isSave) => {
    this.setState({
      menuCopyAnchorEl: null,
      openCopyMetricSuccess: false,
      isCopyDialogOpen: isSave,
    });
  };
  copyNamedView = (option, index, viewType) => {
    return (


      <IconButton
        onClick={(event) => {
          this.handleCopyMenuClick(event);
          this.handleCopyClick(event.currentTarget.id, true, viewType, option);
        }}
        aria-label="Copy view"
        disabled={this.props.isInterface}

      >
        <CopyIcon role="figure" title="Copy view" />
      </IconButton>

    );
  };

  handleMoreMenuClick = (event) => {
    this.setState({ menuMoreAnchorEl: event.currentTarget });
  };
  handleMoreMenuClose = () => {
    this.setState({ menuMoreAnchorEl: null });
  };
  getMoreOptions = (option, index, viewType) => {
    return (
      <Tooltip title={"More options for "+option.name}>
      <IconButton

        aria-label="More"
        onClick={(event) => {
          this.handleMoreMenuClick(event);
          this.handleRequestChange(
            event.currentTarget.id,
            true,
            viewType,
            option
          );
        }}
        id={index}
      >
        <MoreVertIcon role="img" title="More" />
      </IconButton>
      </Tooltip>
    );
  };

  manageViewComponent = () => {
    console.log(this.state.viewList);
    return (
      <div style={{width:'100%'}}>

          <Tabs variant="fullWidth"
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            inkBarStyle={{ display: "none" }}
          >
            <Tab variant="fullWidth"
              label={`My Views (${
                this.state.viewList ? this.state.viewList.MyViews.length : 0
              })`}
              value={1}

            ></Tab>
            <Tab variant="fullWidth"
              label={`Shared With Me (${
                this.state.viewList ? this.state.viewList.SharedViews.length : 0
              })`}
              value={2}
              //onChange={this.handleTabChange}

            ></Tab>
          </Tabs>


        {this.state.tabValue === 1 && (
          <div>

            <FormControl fullWidth style={{marginTop:'16px'}}>
              <InputLabel id="managedview-filter-field-label" htmlFor="managedview-filter-field">Filter</InputLabel>
              <OutlinedInput fullWidth
                key="filterText" id="managedview-filter-field" labelId="managedview-filter-field-label"
                inputProps={{ "aria-labelledby": "managedview-filter-field-label" ,'aria-label':"Filter"}}
                value={this.state.savedViewSearch}
                onChange={this.handleSearchChange}
                endAdornment={
                <SearchIcon role="figure" title="Search" name="Search" />
              }
              />
              </FormControl>


            <ul
              style={{
                paddingInlineStart: "0px",
                margin: "0px",
                marginTop: "10px",
              }}
            >
              {this.state.viewList && this.state.viewList.MyViews.length ? (
                this.state.viewList.MyViews.filter(
                  (view) =>
                    view.name
                      .toLowerCase()
                      .indexOf(this.state.savedViewSearch.toLowerCase()) > -1 ||
                    !this.state.savedViewSearch
                ).length ? (
                  this.state.viewList.MyViews.filter(
                    (view) =>
                      view.name
                        .toLowerCase()
                        .indexOf(this.state.savedViewSearch.toLowerCase()) >
                        -1 || !this.state.savedViewSearch
                  ).map((option, index) => (
                    <li
                      className="managedview-drop-list-item"
                      // onClick={this.onMetricClicked(option)}
                      key={option.id}
                    >
                       <Typography component="span"
                      title={option.name}
                      style={{

                        width: "370px",


                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}> {option.name}</Typography>


                      {this.copyNamedView(option, index, "MyViews")}
                      <Tooltip title={"Delete view "+option.name}>
                      <IconButton
                        aria-label="Delete"
                        disabled={
                          option.isDefault

                        }
                        onClick={(id) =>
                         { this.setState({showDeleteDialog:true, idToDelete:option.id,nameToDelete:option.name});}
                        }

                      >
                        <DeleteOutlineIcon style={{color:option.isDefault?"#666666":"#DE1B1B"}}  role="img" title="Delete" />
                      </IconButton>
                      </Tooltip>
                      {this.getMoreOptions(option, index, "MyViews")}
                    </li>
                  ))
                ) : (
                  <div role="alert" style={{ textAlign: "center" }}>No results</div>
                )
              ) : (
                <div role="alert" style={{ textAlign: "center" }}>No results</div>
              )}
            </ul>
          </div>
        )}

        {this.state.tabValue === 2 && (
          <div>

               <FormControl fullWidth style={{marginTop:'16px'}}>
              <InputLabel id="managedview-filter-field-label" htmlFor="managedview-filter-field">Filter</InputLabel>
              <OutlinedInput fullWidth
                key="filterText" id="managedview-filter-field" labelId="managedview-filter-field-label"
                inputProps={{ "aria-labelledby": "managedview-filter-field-label" ,'aria-label':"Filter"}}
                value={this.state.savedViewSearch}
                onChange={this.handleSearchChange}
                endAdornment={
                <SearchIcon role="figure" title="Search" name="Search" />
              }
              />
              </FormControl>


            <ul
              style={{
                paddingInlineStart: "0px",
                margin: "0px",
                marginTop: "10px",
              }}
            >
              {this.state.viewList && this.state.viewList.SharedViews.length ? (
                this.state.viewList.SharedViews.filter(
                  (view) =>
                    view.name
                      .toLowerCase()
                      .indexOf(this.state.savedViewSearch.toLowerCase()) > -1 ||
                    !this.state.savedViewSearch
                ).map((option, index) => (
                  <li
                    className="managedview-drop-list-item"
                    // onClick={this.onMetricClicked(option)}
                    key={option.id}
                  >
                    <Typography component="span"
                      title={option.name}
                      style={{

                        width: "370px",


                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {option.name}
                    </Typography>
                    {this.copyNamedView(option, index, "SharedViews")}
                    <IconButton
                      title="Delete"
                      disabled={
                          option.isDefault

                        }
                      onClick={(id) =>
                        !option.isDefault && this.deleteView(option.id)
                      }
                      id={index}
                    >
                      <DeleteOutlineIcon
                        style={{color:option.isDefault?"#666666":"#DE1B1B"}}
                      />
                    </IconButton>
                    {this.getMoreOptions(option, index, "SharedViews")}
                  </li>
                ))
              ) : (
                <div role="alert" style={{ textAlign: "center" }}>No results</div>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  };

  closeCopyMetricSnackBar = () => {
    this.setState({ openCopyMetricSuccess: false, isCopyDialogOpen: false });
  };
  saveNewMetricView = (metricData, targetType) => {
    metricApi
      .saveDeviceMetricView(metricData, targetType)
      .then((response) => {
        let index = this.state.selectedIndex;
        let { viewList } = this.state;
        let MyViews = viewList.MyViews;
        let SharedViews = viewList.SharedViews;

        if (this.state.viewType === "MyViews") {
          MyViews[index] = { ...MyViews[index] };
        } else {
          SharedViews[index] = { ...SharedViews[index] };
        }
        viewList = Object.assign({}, viewList, { MyViews, SharedViews });
        this.setState({
          openCopyMetricSuccess: true,
          viewList,
        });
        let body = {};
        if(this.props.isInterface){
          body.masterId=this.props.masterId
          body.csId=this.props.contextValue
        }
        if (this.props.fromCsView) {
          body.csId = this.props.contextValue;
        } else if (this.props.fromGroup) {
          body.groupId = this.props.groupId;
        }
        this.props.fetchAllMetric(body);
      })
      .catch((error) => {
        alert("Metric Copied failed");
        console.error("Error in saving copy metric", error);
      });
  };

  handleView = (view) => {
    view == 1 && sessionStorage.setItem("newCalled", true);
    this.toggleDrawer();
    this.whichViewClicked(view);
  };

  validateSelAccountName = (value,viewDetails) => {
    if (value === "Account") {
      let acctFirstId = 1;
      let accountList = this.state.accountList;
      let selectedAccount = this.state.selectedAccount;
      if (accountList && accountList.length > 0)
        acctFirstId = Object.values(accountList)[0].accountId;

      if (!(
        selectedAccount === "" || selectedAccount === 0 ||
        selectedAccount === "0" || selectedAccount === undefined ||
        selectedAccount === "undefined" || selectedAccount === null ||
        selectedAccount < 0
      )){
        const acctSelId = accountList.find(
          (account) => account.accountId === selectedAccount
        );
        if (acctSelId) {
          acctFirstId =acctSelId.accountId;
        }
      }
      let  newViewDetails = {...viewDetails,accountId:acctFirstId}
      this.setState({selectedAccount:acctFirstId, viewDetails:newViewDetails});
    }else{
      this.setState({viewDetails,});
    }
  };
  render() {
    const { name, contextValue, getMetricTypes, getMetrics, columns } =
      this.props;
    const { viewAction, viewDetails, savedViewName } = this.state;
    const actions = [
      <FlatButton
        children="Cancel"
        onClick={this.handleClose}
        variant="text"
      />,
      <FlatButton
        variant="contained"
        children="Proceed"

        onClick={() => {
          !this.state.fromMngView
            ? this.updateCheck(this.state.cbVal)
            : this.editDefaultChkBox(
                null,
                this.state.cbData.value,
                this.state.cbData.index,
                this.state.cbData.viewType
              );
        }}
      />,
    ];
    // const savedViewName = viewDetails.viewName
    const confirmActions = [

       ];
        let confirmMassage = 'Are you sure you want to close the dialog?'

    return (
      <div className="Metric-Palette" style={{height:'100%'}}>


      <Dialog
       open={this.state.showCloseMetricsCreateConfirmation}
       onClose={()=>{this.setState({
          showCloseMetricsCreateConfirmation:false
        });}}
     >
       <DialogTitle component="div" disableTypography>


             <Typography component="h1" variant="h4">
               {"Confirm exit"}
             </Typography>

       </DialogTitle>
       <div style={{height:'0px'}}>

       <Tooltip title={"Close"} style={{float:'right',marginRight:'8px',top:'-50px'}}>
        <IconButton autoFocus  onClick={()=>{this.setState({
          showCloseMetricsCreateConfirmation:false
        });}}>
          <ClearIcon role='figure' />
        </IconButton>
         </Tooltip>
       </div>
       <DialogContent>{confirmMassage}</DialogContent>
       <DialogActions>
       <Button

          children={"No"}
          variant="outlined"

          onClick={()=>{this.setState({
            showCloseMetricsCreateConfirmation:false
          });}}

        />


        <Button

          children={"Yes"}
          variant="contained"
          color="error"
          onClick={() => {

            this.setState({
              showCloseMetricsCreateConfirmation:false
          });
            this.toggleDrawer(true)}}

        />
       </DialogActions>
       </Dialog>

        <Drawer role="dialog"
          width={540}
          // style={{ width: "480px" }}
          anchor="right"
          open={this.state.open}
          className="edit-metrics"  aria-label={this.drawerTitle()}
          style={{ top: "auto ! important" }}
          onClose={()=>{this.toggleDrawer(true)}}
          // containerClassName="edit-metrics"
        >
          <div style={{ width: "540px",margin:'16px' }}>
            <div className="drawer-header">
              <Typography component="h1" variant="h3">{this.drawerTitle()}</Typography>
              <Tooltip title="Close">
              <IconButton
                onClick={()=>{this.toggleDrawer(true)}}

                title="Close" aria-label="Close"

                autoFocus
              >
                <ClearIcon role="img" title="Close" />
              </IconButton>
              </Tooltip>
              {viewAction === 1 || viewAction === 2 ? (<FormControlLabel
                  style={{
                    position: "absolute",
                    top: "53px",
                    right: "16px",
                  }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={viewDetails.isDefaultView}
                      // style={{
                      //   marginTop: "5px",
                      //   color: "rgb(24, 133, 199)",
                      //   fontSize: "12px",
                      // }}
                      style={{ marginRight: "5px" }}
                      onChange={(event) => {
                        let val = event.target.checked;
                        this.props.singleDevice
                          ? this.setState({
                              openDefaultConf: true,
                              cbVal: val,
                            })
                          : this.updateCheck(val);
                      }}
                      // className="isDefaultView"
                    />
                  }
                  label="Default View"
                />):null}

            </div>
            <Tooltip title={viewAction === 2 ? `${savedViewName} / ${name}` : name}>
				<Typography component="h2" variant="h6" noWrap style={{maxWidth:"75%"}}>
				  {viewAction === 2 ? `${savedViewName} / ${name}` : name}
				</Typography>
            </Tooltip>
            {viewAction === 1 || viewAction === 2 ? (
              <div style={{height: "calc( 100vh - 190px )"}}>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",

                    boxSizing: "border-box",
                    marginTop: "16px",
                  }}
                >
                  <Tooltip title={viewDetails.viewName}>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "column nowrap",
                      // justifyContent: "space-between",
                      flex: "1 1 auto",
                    }}
                  >
                    <InputLabel id="txt-view-name-label" htmlFor="txt-view-name">
                      Name  <span className="metricviews-required-label">{"Required"}</span>
                    </InputLabel>
                    <OutlinedInput style={{marginBottom:'16px'}} labelId="txt-view-name-label"  id="txt-view-name"
                    //  classes={{ root: "txt-view-name" }}
                      onChange={this.ViewNameChange}
                      value={viewDetails.viewName}
                      // autoFocus={true}
                      inputProps={{ "aria-label": "Name" ,"aria-required":true}}
                    />
                  </div>
                  </Tooltip>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "column nowrap",
                      // justifyContent: "space-between",
                      marginLeft: "10px",
                    }}
                  >
                    <InputLabel  id="txt-view-type">
                      Type
                    </InputLabel>
                    <DropDownMenu

                      //className="publish-dropdown"
                      value={viewDetails.publishView}
                      //underlineStyle={{ display: "none" }}
                     // style={{
                     //   height: "30px",
                     //   lineHeight: "16px",
                     //   fontSize: "14px",
                        // width: "16%",
                        // marginLeft: "108px",
                        // marginTop: "16px",
                        // display: "inline-block"
                      //}}

                      variant="outlined"
                      onChange={this.handlePublishView}
                      labelId="txt-view-type"
                      inputProps={{ "aria-label": "Type", role: 'combobox', }}
                      MenuProps={{
       MenuListProps:{

         'aria-label' :"Type options list",

        }}}
                      // disableUnderline
                    >
                      <MenuItem value="Private" children="Private" />
                      <MenuItem value="Account" children="Account" />
                      {!this.state.accountName ? (
                      <MenuItem value="Public" children="Public" />
                      ):null}
                    </DropDownMenu>
                    </div>
                    {this.state.viewDetails && this.state.viewDetails.publishView === "Account"
                         &&
                 this.state.accountList &&
                 this.state.accountList.length
                 ? (
                  <div style={{ width: "150px",marginTop:"6px",marginLeft:"10px" }}>
                    <span style={{ fontSize: "14px" }}>Account</span>
                    <FormControl className="qos-import-chart-dialog__save-select">
                      <DropDownMenu
                        id="mv-account-select"
                        value={this.state.selectedAccount?this.state.selectedAccount:(this.state.viewDetails && this.state.viewDetails.accountId)}
                        variant="outlined"
                        displayEmpty
                        onChange={(event) => {
                          this.setState({selectedAccount:event.target.value});
                          this.handleAccountNameChange(event);
                        }}
                      >
                        {this.state.accountList.map((account, index) => {
                          return (
                            <MenuItem
                              value={account.accountId}
                              key={"account-" + index}
                            >
                              {account.contactName}
                            </MenuItem>
                          );
                        })}
                      </DropDownMenu>
                    </FormControl>
                  </div>
                ) : null}

                </div>
                {this.props.location.pathname.indexOf("/computer-systems/") >
                -1 || this.props.location.pathname.indexOf("/containers/") >
                -1 ? (
                  <Group
                    devices={[
                      /computer-systems\/(\d+)\//.exec(
                        this.props.location.pathname
                      )[1],
                    ]} // extract csId from URL and set it in an array
                    toggleDrawer={this.toggleDrawer}
                    manageViews={this.manageViews}
                    {...this.props}
                    singleDevice={true}
                    fromCsView={true}
                    drawerIsClosing={this.state.drawerIsClosing}
                    fromUrl={true}
                    viewName={this.state.viewDetails.viewName}
                    isDefault={this.state.viewDetails.isDefaultView}
                    viewType={this.state.viewDetails.publishView}
                    contextValue={contextValue}
                    id={this.state.viewDetails.id}
                    viewAction={this.state.viewAction}
                    deleteView={this.deleteView}
                    loadedView={this.loadedView}
                    accountId= {this.state.viewDetails.accountId}
                  />
                ) : this.props.fromGroup || this.props.fromCsView ? (
                  <Group
                    isInterface={this.props.isInterface}
                    devices={this.props.devices}
                    toggleDrawer={this.toggleDrawer}
                    manageViews={this.manageViews}
                    {...this.props}
                    drawerIsClosing={this.state.drawerIsClosing}
                    viewName={this.state.viewDetails.viewName}
                    isDefault={this.state.viewDetails.isDefaultView}
                    viewType={this.state.viewDetails.publishView}
                    contextValue={contextValue}
                    id={this.state.viewDetails.id}
                    viewAction={this.state.viewAction}
                    deleteView={this.deleteView}
                    loadedView={this.loadedView}
                    accountId= {this.state.viewDetails.accountId}
                  />
                ) : (
                  <SelectionRedux
                    contextValue={contextValue}
                    getMetricTypes={getMetricTypes}
                  />
                )}
              </div>
            ) : (
              this.manageViewComponent()
            )}
          </div>

          <MetricViewCopyDialog
            open={this.state.menuCopyAnchorEl != null}
            onClose={(value) => this.handleCopyMenuClose(value)}
            selectedMetricId={
              this.state.selectedViewData ? this.state.selectedViewData.id : -1
            }
            saveNewMetricView={(requestBody, targetType) =>
              this.saveNewMetricView(requestBody, targetType)
            }
            isGroupView={
              this.props.location.pathname.indexOf("/computer-systems/") < 0 && this.props.location.pathname.indexOf("/containers/") < 0
            }
          />
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.openCopyMetricSuccess}
            key={"top center"}
            autoHideDuration={4000}
            onClose={this.closeCopyMetricSnackBar}
          >
            <Alert
              style={{ backgroundColor: "white" }}
              iconMapping={{ success: <CheckCircleIcon fontSize="inherit" /> }}
            >
              Metric views applied successfully.
            </Alert>
          </Snackbar>

          {/* <Menu
            id="actionCopy-menu"
            anchorEl={this.state.menuCopyAnchorEl}
            keepMounted
            onClose={this.handleCopyMenuClose}
            //open={this.state.menuCopyAnchorEl != null}
            open={false}
          //maxHeight={220}
        >
          <TextField
            classes={{ root: "copy-metric-text" }}
            onChange={this.handleCopyMetricName}
            value={this.state.copyMetricName}
            autoFocus={true}
            fullWidth={true}
          />
          <span
            onClick={() => this.copyMetric(this.state.selectedIndex, this.state.viewType)}
            className="apply--btn"
            style={{ display: "block" }}
          >
            Apply
          </span>
          <span
            onClick={() => {
              this.handleCopyClick(this.state.selectedIndex, false, this.state.viewType);

            }}
            className="apply--btn"
          >
            Cancel
          </span>
        </Menu> */}
          <Popover
            id="actionMore-menu"
            anchorEl={this.state.menuMoreAnchorEl}
            onClose={this.handleMoreMenuClose}
            open={Boolean(this.state.menuMoreAnchorEl)}
            PaperProps={{
              style: {
                width: "176px",
                padding:'16px'
              },
            }}
          >
            <div
              className="edit-view-item1"
              style={
                this.state.openDefaultConf ? { pointerEvents: "none",paddingLeft: '0px'} : {paddingLeft: '0px'}}


            >
              <FormControlLabel
                control={
                  <Checkbox autoFocus={true}
                    color="primary"
                    checked={this.state.persistDefaultVal}
                    onChange={(event) => {
                      let index = this.state.selectedIndex;
                      let viewType = this.state.viewType;
                      this.props.singleDevice
                        ? this.setState({
                            fromMngView: true,
                            openDefaultConf: true,
                            cbData: {
                              value: event.target.checked,
                              index,
                              viewType,
                            },
                          })
                        : this.editDefaultChkBox(
                            event,
                            event.target.checked,
                            index,
                            viewType
                          );
                    }}
                  />
                }
                label="Default View"
                color="primary"
              />
            </div>
            <div
              className="edit-view-item2"
              style={
                this.state.openDefaultConf ? { pointerEvents: "none" } : {}
              }
            >
              <RadioButtonGroup
                name="publish_view"
                defaultValue={
                  this.state.selectedViewData &&
                  this.state.selectedViewData.viewType
                }
                onChange={(evt) =>
                  this.handlePublishViewChange(
                    evt,
                    this.state.selectedIndex,
                    this.state.viewType
                  )
                }
              >
                <FormControlLabel
                  value="Private"
                  label="Private"
                  control={
                    <RadioButton
                      type="radio"
                      checked={
                        this.state.selectedViewData &&
                        this.state.selectedViewData.viewType == "Private"
                      }
                    />
                  }
                />
                <FormControlLabel
                  value="Account"
                  label="Account"
                  control={
                    <RadioButton
                      checked={
                        this.state.selectedViewData &&
                        this.state.selectedViewData.viewType == "Account"
                      }
                      type="radio"
                    />
                  }
                />
                {!this.state.accountName ? (<FormControlLabel
                  value="Public"
                  label="Public"
                  control={
                    <RadioButton
                      type="radio"
                      checked={
                        this.state.selectedViewData &&
                        this.state.selectedViewData.viewType == "Public"
                      }
                    />
                  }
                />):null}
              </RadioButtonGroup>
            </div>
            <span
              style={
                this.state.openDefaultConf
                  ? {
                      pointerEvents: "none",
                      margin: "8px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }
                  : {
                      margin: "8px",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }
              }
            >
              <Button variant="contained"
                onClick={() =>
                  this.handleEdit(this.state.selectedIndex, this.state.viewType)
                }
              >
                Apply
              </Button>
              <Button variant="text"
                onClick={() => {
                  this.handleRequestChange(
                    this.state.selectedIndex,
                    false,
                    this.state.viewType
                  );
                }}
              >
                Cancel
              </Button>
            </span>
          </Popover>
        </Drawer>
        {
            this.props.fromUrl ? null : (
              <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography component="h3" variant="h3" style={{ marginLeft:this.props.isFromTreeView && !this.props.isTreeOpen?'40px':'16px'}}>
            {this.props.groupName ? "Group Metrics" : "Device Metrics"}{" "}
            {this.props.name}
          </Typography>
          <div className="right-section">
            <Tooltip title={"New View"}>
              <Button style={{marginRight:'8px'}}
                variant="text"

                startIcon={<AddIcon />}
                onClick={() => this.handleView(1)}
              >
                New View
              </Button>
            </Tooltip>
            <div
              style={{
                width: "0px",
                height: "10px",
                border: "1px solid #DDE3ED",
                marginRight: "8px",
              }}
            ></div>
            <Tooltip title={"Manage Views"}>
              <Button
                variant="text"

                onClick={() => this.handleView(3)}
              >
                Manage Views
              </Button>
            </Tooltip>
          </div>
        </div>
        )}
        <Nav
          isInterface={this.props.isInterface}
          masterId={this.props.masterId}

          toggleSelection={this.toggleDrawer}
          isViewChanged={this.isViewChanged}
          columns={columns}
          account={this.props.account}
          acl={this.props.acl}
          getViewDetails={this.getViewDetails}
          // whichViewClicked={this.whichViewClicked}
          fromGroup={this.props.fromGroup}
          fromCsView={this.props.fromCsView}
          contextValue={this.props.contextValue}
          groupId={this.props.groupId}
          isTreeOpen={this.props.isTreeOpen}
          sid={this.props.sid}
          loadedView={this.loadedView}
          devices={this.props.devices}
          fromUrl={this.props.fromUrl}
          metricId={this.props.metricId}
        />
        {/* <div className="no-metrics empty-view">You have no metric views defined yet.<a className="create-view" onClick={() => this.toggleDrawer()} > Create a view </a>now to get started</div> */}
        {this.props.fromGroup || this.props.fromCsView ? (
          <Chart
            isTreeOpen={this.props.isTreeOpen}
            fromCsView={this.props.fromCsView}
            fromGroup={this.props.fromGroup}
            columns={2}
            toggleDrawer={this.toggleDrawer}
            createNewView = {this.createNewView}
          />
        ) : (this.props.location.pathname.indexOf("/computer-systems/") > -1 || this.props.location.pathname.indexOf("/containers/") > -1 ) ? (
          <Chart
            isTreeOpen={this.props.isTreeOpen}
            fromCsView={true}
            fromGroup={this.props.fromGroup}
            fromUrl={true}
            columns={2}
            toggleDrawer={this.toggleDrawer}
            createNewView = {this.createNewView}
          />
        ) : (
          <MetricsRedux
            columns={columns}
            getMetrics={getMetrics}
            contextValue={contextValue}
          />
        )}
        <Snackbar
          key="snackbar"
          open={this.state.openSnackBar}
          message={this.state.snackBarMessage}
          onClose={this.handleCloseSnackBar}
          autoHideDuration={2000}
        />
        <Dialog
        open={this.state.showDeleteDialog}
        onClose={() => {
          this.setState({showDeleteDialog:false,idToDelete:null,nameToDelete:null});
        }}
        aria-labelledby="alert-dialog-header"
        aria-describedby="alert-dialog-description"
        className="slm_container-dialog"
      >
        <DialogTitle component="div" disableTypography>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <DangerIcon size={'20'} />
              <Typography component="h1" variant="h3" style={{ marginLeft: '10px' }}>
                {//Confirm  Delete
                Resource.get("Confirm Delete")}
              </Typography>
            </div>

          </div>
        </DialogTitle>
        <div style={{height:'0px'}}>
        <Tooltip title="Close">
        <IconButton style={{float:'right',top:'-50px',marginRight:'8px'}}
            //Close
              aria-label={ Resource.get("Close")}
              onClick={() => {
                this.setState({showDeleteDialog:false,idToDelete:null,nameToDelete:null});
              }}
              autoFocus
            >
              <CloseIcon size={'40'} />
            </IconButton>
            </Tooltip>
            </div>
        <DialogContent>
          <p style={{ color: '#333840' }}>
            {//Are you sure you want to delete the metric view
              `${Resource.get('Are you sure you want to delete the metric view with name')} ${this.state.nameToDelete}?`}


          </p>
          <p style={{ color: '#333840' }}>{//This operation is irreversible
            Resource.get("This operation is irreversible")}</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({showDeleteDialog:false,idToDelete:null,nameToDelete:null});
            }}
            variant="text"
            color="primary"
          // style={{ textTransform: 'none', color: '#999999' }}
          >

            {//Cancel
              Resource.get("Cancel")}
          </Button>
          <Button  variant="contained" color="error"
            onClick={() => {
             this.deleteView(this.state.idToDelete)
             this.setState({showDeleteDialog:false,idToDelete:null,nameToDelete:null});
            }}

            style={{ textTransform: 'none' }}
          >
            {//Delete
              Resource.get("Delete")}
          </Button>
        </DialogActions>
      </Dialog>

        <Dialog
          modal={true}
          open={this.state.openDefaultConf}
          onClose={this.handleClose}
          className="defaultConfDialog"
        >
       <DialogTitle component="div" disableTypography>


         <Typography component="h1" variant="h4">
           {"Confirm Action"}
         </Typography>

   </DialogTitle>
   <div style={{height:'0px'}}>

   <Tooltip title={"Close"} style={{float:'right',marginRight:'8px',top:'-50px'}}>
    <IconButton autoFocus  onClick={this.handleClose}>
      <ClearIcon role='figure' />
    </IconButton>
     </Tooltip>
   </div>
          <DialogContent

          >
            Changing this would change the default metrics on device dashboard.
            Do you wish to continue?
          </DialogContent>
          <DialogActions>{actions}</DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetChartData: () => dispatch(resetChartData()),
    fetchAllMetric: (body) => dispatch(fetchAllMetric(body)),
    saveMetric: (data, updateView) => dispatch(saveMetric(data, updateView)),
    copyMetrics: (...data) => dispatch(copyMetrics(...data)),
    //saveNewMetricView: (metricData) => dispatch(saveNewMetricView(metricData)),
  };
};

const mapStateToProps = (state) => {
  const namedMetricList = state.deviceMetrics.named_metric_list;
  const deleteMsg = state.deviceMetrics.deleteMsg;
  const isUpdateView = state.deviceMetrics.isUpdateView;
  const isCopySuccess = state.deviceMetrics.isCopySuccess;
  const isSaving = state.deviceMetrics.isSaving;
  const isCopyDeviceMetricSuccess =
    state.deviceMetrics.copyMetricViewDeviceLevelSuccess;
  return {
    namedMetricList,
    deleteMsg,
    isUpdateView,
    isCopySuccess,
    isSaving,
    isCopyDeviceMetricSuccess,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MetricPaletteViewer));
