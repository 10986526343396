/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  FETCH_GROUPSTACK,
  FETCH_GROUPSTACK_SUCCESS,
  FETCH_GROUPSTACK_FAILURE,
  FETCH_STATE,
  FETCH_STATE_SUCCESS,
  FETCH_STATE_FAILURE,
  FETCH_COUNT_STATE,
  FETCH_COUNT_STATE_SUCCESS,
  FETCH_COUNT_STATE_FAILURE,
  SET_TREE_NODE_SELECTED,
  REFRESH_TREE,
  TOGGLE_ADD_GROUP_DIALOG,
  DELETE_GROUP_DIALOG,
  TOGGLE_ADD_DEVICE_TO_GROUP,
  TOGGLE_EDIT_SUB_GROUP,
} from "./actionTypes";
import { TreeView as treeViewApi } from "./treeView";
import * as IntegrationError from "./../../components/settings/integrations/IntegrationError";

function getResponseError(error) {
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data;
  } else {
    return IntegrationError.getDefaultError();
  }
}

export function fetchGroupData() {
  return {
    type: FETCH_GROUPSTACK,
  };
}
export function fetchGroupSuccess(json, isPoller) {
  return {
    type: FETCH_GROUPSTACK_SUCCESS,
    groups: json,
    isPoller,
  };
}
export function fetchGroupFailure(error) {
  return {
    type: FETCH_GROUPSTACK_FAILURE,
    errorMessage: error.message,
  };
}
export function getGroups(id, isPoller) {
  return (dispatch) => {
    !isPoller && dispatch(fetchGroupData());
    return (
      treeViewApi
        .getAllGroups(id)
        // .then((response) => response.json())
        // .then((response) => response.data._items) // TODO:remove this as it is for mock API
        .then((response) => response.data)
        .then((json) => dispatch(fetchGroupSuccess(json, isPoller)))
        .catch(function (error) {
          !isPoller && dispatch(fetchGroupFailure(getResponseError(error)));
        })
    );
  };
}
export function fetchStateMapping() {
  return {
    type: FETCH_STATE,
  };
}
export function fetchStateSuccess(json, isPoller) {
  return {
    type: FETCH_STATE_SUCCESS,
    stateData: json,
    isPoller,
  };
}
export function fetchStateFailure(error) {
  return {
    type: FETCH_STATE_FAILURE,
    errorMessage: error.message,
  };
}
export function getStateData(id, isPoller) {
  return (dispatch) => {
    !isPoller && dispatch(fetchStateMapping());
    return (
      treeViewApi
        .getAlarmState(id)
        // .then((response) => response.json())
        .then((response) => response.data) // TODO:remove this as it is for mock API
        .then((json) => dispatch(fetchStateSuccess(json, isPoller)))
        .catch(function (error) {
          !isPoller && dispatch(fetchStateFailure(getResponseError(error)));
        })
    );
  };
}
export function fetchCountMapping() {
  return {
    type: FETCH_COUNT_STATE,
  };
}
export function fetchCountSuccess(json, isPoller) {
  return {
    type: FETCH_COUNT_STATE_SUCCESS,
    countData: json,
    isPoller,
  };
}
export function fetchCountFailure(error) {
  return {
    type: FETCH_COUNT_STATE_FAILURE,
    errorMessage: error.message,
  };
}
export function getCountData(id, isPoller) {
  return (dispatch) => {
    !isPoller && dispatch(fetchCountMapping());
    return (
      treeViewApi
        .getCountDetails(id)
        // .then((response) => response.json())
        .then((response) => response.data) // TODO:remove this as it is for mock API
        .then((json) => dispatch(fetchCountSuccess(json, isPoller)))
        .catch(function (error) {
          !isPoller && dispatch(fetchCountFailure(getResponseError(error)));
        })
    );
  };
}
export function setTreeNodeSelected(selectedNode) {
  return {
    type: SET_TREE_NODE_SELECTED,
    treeNode: selectedNode,
  };
}
export function setTreeNode(selectedNode) {
  return (dispatch) => {
    return dispatch(setTreeNodeSelected(selectedNode));
  };
}
export function refreshTree(value, calledfrom) {
  return {
    type: REFRESH_TREE,
    refreshTree: value,
  };
}

export function toggleAddGroupDialog(isToggle) {
  return {
    type: TOGGLE_ADD_GROUP_DIALOG,
    isToggleAddGroupDialog: isToggle
  }
}

export function deleteGroupDialog(isToggle) {
  return {
    type: DELETE_GROUP_DIALOG,
    isDeleteGroupDialog: isToggle
  }
}

export function toggleAddDeviceToGroup(isToggle) {
  return {
    type: TOGGLE_ADD_DEVICE_TO_GROUP,
    toggleAddDeviceToGroup: isToggle
  }
}

export function toggleEditSubGroup(isToggle) {
  return {
    type: TOGGLE_EDIT_SUB_GROUP,
    toggleEditSubGroup: isToggle
  }
}
