/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  FETCH_METRICS,
  FETCH_METRICS_SUCCESS,
  FETCH_METRICS_FAILURE,
  FETCH_METRICS_DEVICES,
  FETCH_METRICS_DEVICES_SUCCESS,
  FETCH_METRICS_DEVICES_FAILURE,
  CLEAR_CHART_DATA,
  SAVE_NAMED_METRICS,
  SAVE_NAMED_METRICS_FAILURE,
  SAVE_NAMED_METRICS_SUCCESS,
  FETCH_NAMED_METRICS,
  FETCH_NAMED_METRICS_FAILURE,
  FETCH_NAMED_METRICS_SUCCESS,
  FETCH_NAMED_METRICS_BY_ID,
  FETCH_NAMED_METRICS_BY_ID_FAILURE,
  FETCH_NAMED_METRICS_BY_ID_SUCCESS,
  DELETE_NAMED_VIEWS,
  DELETE_NAMED_VIEWS_SUCCESS,
  DELETE_NAMED_VIEWS_FAILURE,
  GET_DEVICE,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_FAILURE,
  EXPORT_METRICS,
  EXPORT_METRICS_SUCCESS,
  EXPORT_METRICS_FAILURE,
  COPY_METRICS,
  COPY_METRICS_SUCCESS,
  COPY_METRICS_FAILURE,
  COPY_DEVICE_METRICVIEW,
  COPY_DEVICE_METRICVIEW_SUCCESS,
  COPY_DEVICE_METRICVIEW_FAILURE,
  RESTRICT_METRICS_CHARTS,
  FETCH_METRICS_CUSTOM,
  FETCH_METRICS_SUCCESS_CUSTOM,
  FETCH_METRICS_FAILURE_CUSTOM
} from "./actionTypes";
import { Metric as metricApi } from "./metric";
import { calculateDataPoints } from "../../components/timerange/timerange-util";

export function fetchMetrics() {
  return {
    type: FETCH_METRICS,
  };
}
export function fetchMetricsCustom() {
  return {
    type: FETCH_METRICS_CUSTOM,
  };
}
export function fetchMetricsSuccess(json) {
  return {
    type: FETCH_METRICS_SUCCESS,
    metrics: json,
  };
}
export function fetchMetricsSuccessCustom(json) {
  return {
    type: FETCH_METRICS_SUCCESS_CUSTOM,
    metrics: json,
  };
}
export function fetchMetricsFailure(error) {
  return {
    type: FETCH_METRICS_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}
export function fetchMetricsFailureCustom(error) {
  return {
    type: FETCH_METRICS_FAILURE_CUSTOM,
    errorMessage: error.message ? error.message : "Request failed",
  };
}

export function getMetricsData(deviceIds, isInterface) {
  return (dispatch) => {
    dispatch(fetchMetricsCustom());
    return metricApi
      .getDeviceMetrics(deviceIds, isInterface)
      .then((response) =>
        response.data._items ? response.data._items : response.data
      )
      .then((data) => dispatch(fetchMetricsSuccessCustom(data)))
      .catch(function (error) {
        dispatch(fetchMetricsFailureCustom(error));
      });
  };
}

export function fetchMetricDevices() {
  return {
    type: FETCH_METRICS_DEVICES,
  };
}

export function restrictMetrics(result) {
  return {
    type: RESTRICT_METRICS_CHARTS,
    payload: result
  };
}

export function fetchMetricDeviceSuccess(
  json,
  deviceIds,
  metricTypes,
  hours,
  selectedCIs,
  isInterface,
  endDate,
  aggregationValue,
  aggregateFunction

) {
  return {
    type: FETCH_METRICS_DEVICES_SUCCESS,
    metricData: json,
    deviceIds,
    metricTypes,
    hours,
    selectedCIs,
    isInterface,
    endDate,
    aggregationValue,
    aggregateFunction
    };
}

export function fetchMetricDeviceFailure(
  error,
  deviceIds,
  metricTypes,
  hours,
  selectedCIs,
  isInterface,
  endDate,
  aggregationValue,
  aggregateFunction //dp039841
) {
  return {
    type: FETCH_METRICS_DEVICES_FAILURE,
    errorMessage: error.message,
    deviceIds,
    metricTypes,
    hours,
    selectedCIs,
    isInterface,
    endDate,
    aggregationValue,
    aggregateFunction//dp039841
  };
}

export function getMetricDevices(
  deviceIds,
  metricTypes,
  hours,
  selectedCIs,
  isInterface,
  endDate,
  aggregationValue,
  aggregateFunction, //dp039841
  isInterpolation,
) {
  /*forIn(window.localStorage, (value, objKey) => {
    if (true === startsWith(objKey, 'color_')) {
        window.localStorage.removeItem(objKey);
    }
   })*/
  return (dispatch) => {
    const result = calculateDataPoints(metricTypes, selectedCIs, hours, aggregationValue === -1 ? -1 : aggregationValue/60);
    if(result.restrict) {
      dispatch(restrictMetrics(result));
    } else {
      dispatch(fetchMetricDevices());
      return (
        metricApi
          .getAllMetricDevices(
            deviceIds,
            metricTypes,
            hours,
            selectedCIs,
            isInterface,
            endDate,
            aggregationValue ,
            aggregateFunction,//dp039841
            isInterpolation
          )
          .then((response) => [...response.map((res) => res.data)])
        //   .then((response) => {return [response.data]}) //TODO: Remove it, only for mock
          .then((data) =>
            dispatch(
              fetchMetricDeviceSuccess(
                data,
                deviceIds,
                metricTypes,
                hours,
                selectedCIs,
                isInterface,
                endDate,
                aggregationValue,
                aggregateFunction //dp039841
              )
            )
          )
          .catch(function (error) {
            dispatch(
              fetchMetricDeviceFailure(
                error,
                deviceIds,
                metricTypes,
                hours,
                selectedCIs,
                isInterface,
                endDate,
                aggregationValue,
                aggregateFunction //dp039841
              )
            );
          })
      );
    }
  };
}

export function resetChartData() {
  return {
    type: CLEAR_CHART_DATA,
  };
}

export function saveNamedMetric() {
  return {
    type: SAVE_NAMED_METRICS,
  };
}
export function saveNamedMetricSuccess(json, isUpdateView) {
  return {
    type: SAVE_NAMED_METRICS_SUCCESS,
    named_metric: json,
    isUpdateView: isUpdateView,
  };
}
export function saveNamedMetricFailure(error) {
  console.log("error", error);
  let errMsg = "Request failed";
  if(error?.response?.data?.errorMsg!==undefined){
  console.log("error.response.data", error.response.data);
  //let errMsg = "Request failed";
  if (
    error.response.data.errorMsg &&
    (error.response.data.errorMsg.includes("same name already exists") || error.response.data.errorMsg.includes("ConstraintViolationException"))
  ) {
    errMsg =
      "This view name already exists. Please create a different view name";
  }
}
  return {
    type: SAVE_NAMED_METRICS_FAILURE,
    errorMessage: errMsg,
  };
}

export function saveMetric(data, isUpdateView) {
  return (dispatch) => {
    dispatch(saveNamedMetric());
    return metricApi
      .saveNamedMetrics(data, isUpdateView)
      .then((response) => response.data)
      .then((data) => dispatch(saveNamedMetricSuccess(data, isUpdateView)))
      .catch((error) => {
        dispatch(saveNamedMetricFailure(error));
      });
  };
}

export function fetchNamedMetric() {
  return {
    type: FETCH_NAMED_METRICS,
  };
}
export function fetchNamedMetricSuccess(json) {
  return {
    type: FETCH_NAMED_METRICS_SUCCESS,
    named_metric_list: json,
  };
}
export function fetchNamedMetricFailure(error) {
  return {
    type: FETCH_NAMED_METRICS_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}

export function fetchAllMetric(data) {
  return (dispatch) => {
    dispatch(fetchNamedMetric());
    return metricApi
      .getAllNamedMetrics(data)
      .then((response) => response.data)
      .then((data) => dispatch(fetchNamedMetricSuccess(data)))
      .catch(function (error) {
        dispatch(fetchNamedMetricFailure(error));
      });
  };
}
export function removeNamedView() {
  return {
    type: DELETE_NAMED_VIEWS,
  };
}
export function removeNamedViewSuccess(json) {
  return {
    type: DELETE_NAMED_VIEWS_SUCCESS,
    deleteMsg: json,
  };
}
export function removeNamedViewFailure(error) {
  return {
    type: DELETE_NAMED_VIEWS_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}
export function deleteNamedView(data) {
  return (dispatch) => {
    dispatch(removeNamedView());
    return metricApi
      .deleteNamedViews(data)
      .then((response) => response.data)
      .then((data) => dispatch(removeNamedViewSuccess(data)))
      .catch(function (error) {
        dispatch(removeNamedViewFailure(error));
      });
  };
}
export function fetchNamedMetricByIdInit() {
  return {
    type: FETCH_NAMED_METRICS_BY_ID,
  };
}
export function fetchNamedMetricByIdSuccess(json) {
  console.log("json", json);
  return {
    type: FETCH_NAMED_METRICS_BY_ID_SUCCESS,
    named_metric_data: json,
  };
}
export function fetchNamedMetricByIdFailure(error) {
  return {
    type: FETCH_NAMED_METRICS_BY_ID_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}

export function fetchMetricById(data) {
  return (dispatch) => {
    dispatch(fetchNamedMetricByIdInit());
    return metricApi
      .getNamedMetricsById(data)
      .then((response) => response.data)
      .then((data) => dispatch(fetchNamedMetricByIdSuccess(data)))
      .catch(function (error) {
        dispatch(fetchNamedMetricByIdFailure(error));
      });
  };
}

export function searchDevice() {
  return {
    type: GET_DEVICE,
  };
}
export function getDeviceSuccess(json) {
  return {
    type: GET_DEVICE_SUCCESS,
    devices: json,
  };
}
export function getDeviceFailure(error) {
  return {
    type: GET_DEVICE_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}

export function filterDeviceList(advanceSearchText) {
  return (dispatch) => {
    dispatch(searchDevice());
    return metricApi
      .getDevices(advanceSearchText)
      .then((response) => response.data)
      .then((data) => dispatch(getDeviceSuccess(data)))
      .catch(function (error) {
        dispatch(getDeviceFailure(error));
      });
  };
}

export function exportMetricsInit() {
  return {
    type: EXPORT_METRICS,
  };
}
export function exportMetricsSuccess(json) {
  return {
    type: EXPORT_METRICS_SUCCESS,
    exportData: json,
  };
}
export function exportMetricsFailure(error) {
  return {
    type: EXPORT_METRICS_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}

export function exportMetrics(...data) {
  return (dispatch) => {
    dispatch(exportMetricsInit());
    return (
      metricApi
        .getExportData(...data)
        .then((response) => [...response.map((res) => res.data)])
        // .then((response) => [...response.map((res) => res.data._items[0])]) //TODO: Remove it, only for mock
        .then((data) => dispatch(exportMetricsSuccess(data)))
        .catch(function (error) {
          dispatch(exportMetricsFailure(error));
        })
    );
  };
}

export function copyMetricsInit() {
  return {
    type: COPY_METRICS,
  };
}
export function copyMetricsSuccess() {
  return {
    type: COPY_METRICS_SUCCESS,
  };
}
export function copyMetricsFailure(error) {
  return {
    type: COPY_METRICS_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}

export function copyMetrics(id, name) {
  return (dispatch) => {
    dispatch(copyMetricsInit());
    return metricApi
      .copyMetricsData(id, name)
      .then((response) => response.data)
      .then((data) => dispatch(copyMetricsSuccess(data)))
      .catch(function (error) {
        dispatch(copyMetricsFailure(error));
      });
  };
}

export function saveNewMetricView(metricData) {
  return (dispatch) => {
    dispatch(copyDeviceMetricViewInit());
    return metricApi
      .saveMetricView(metricData)
      .then((response) => response.data)
      .then((data) => dispatch(copyDeviceMetricViewSuccess(data)))
      .catch(function (error) {
        dispatch(copyDeviceMetricViewFailure(error));
      });
  };
}
export function copyDeviceMetricViewInit() {
  return {
    type: COPY_DEVICE_METRICVIEW,
  };
}
export function copyDeviceMetricViewSuccess(response) {
  return {
    type: COPY_DEVICE_METRICVIEW_SUCCESS,
    data: response
  };
}
export function copyDeviceMetricViewFailure(error) {
  return {
    type: COPY_DEVICE_METRICVIEW_FAILURE,
    errorMessage: error.message ? error.message : "Request failed",
  };
}
