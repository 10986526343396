/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import {
  FETCH_METRICS,
  FETCH_METRICS_SUCCESS,
  FETCH_METRICS_FAILURE,
  FETCH_METRICS_DEVICES,
  FETCH_METRICS_DEVICES_SUCCESS,
  FETCH_METRICS_DEVICES_FAILURE,
  CLEAR_CHART_DATA,
  SAVE_NAMED_METRICS,
  SAVE_NAMED_METRICS_FAILURE,
  SAVE_NAMED_METRICS_SUCCESS,
  FETCH_NAMED_METRICS,
  FETCH_NAMED_METRICS_FAILURE,
  FETCH_NAMED_METRICS_SUCCESS,
  FETCH_NAMED_METRICS_BY_ID,
  FETCH_NAMED_METRICS_BY_ID_FAILURE,
  FETCH_NAMED_METRICS_BY_ID_SUCCESS,
  DELETE_NAMED_VIEWS,
  DELETE_NAMED_VIEWS_SUCCESS,
  DELETE_NAMED_VIEWS_FAILURE,
  GET_DEVICE,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_FAILURE,
  EXPORT_METRICS,
  EXPORT_METRICS_SUCCESS,
  EXPORT_METRICS_FAILURE,
  COPY_METRICS,
  COPY_METRICS_SUCCESS,
  COPY_METRICS_FAILURE,
  COPY_DEVICE_METRICVIEW_SUCCESS,
  COPY_DEVICE_METRICVIEW_FAILURE,
  RESTRICT_METRICS_CHARTS,
  FETCH_METRICS_CUSTOM,
  FETCH_METRICS_SUCCESS_CUSTOM,
  FETCH_METRICS_FAILURE_CUSTOM
} from "./actionTypes";

const metric_data = (
  state = {
    isFetching: false,
    didInvalidate: false,
    metrics: [],
    error: "",
    isFetchingDevice: false,
    didInvalidateDevice: false,
    metricsForDevices: [],
    errorDevice: "",
    deviceIds: "",
    selectedMetrics: [],
    hours: "",
    endDate: null,
    selectedCIs: {},
    isInterface: false,
    isSaving: false,
    didInvalidateSave: false,
    errorInSaving: "",
    savedData: {},
    isFetchingAll: false,
    didInvalidateFetchAll: false,
    errorInFetchingAll: "",
    named_metric_list: { MyViews: [], SharedViews: [] },
    isFetchingById: false,
    didInvalidateFetchById: false,
    errorInFetchingById: "",
    named_metric_data: {},
    deleteMsg: "",
    exportData: [],
    isExportFetching: false,
    didInvalidateExportFetch: false,
    errorInExportFetch: "",
    isCopyFetching: false,
    didInvalidateCopyFetch: false,
    errorInCopyFetch: "",
    isCopySuccess: false,
    isNextPage:false,
    copyMetricViewDeviceLevel: [],
    copyMetricViewDeviceLevelErrorMsg: "",
    copyMetricViewDeviceLevelSuccess: false,
    restrictMetrics: false,
    restrictMetricsMsg: '',
    isFetchingSearch: false,
    isFetchingTree: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_METRICS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false,
      });
      case FETCH_METRICS_CUSTOM:
      return Object.assign({}, state, {
        isFetchingTree: true,
        didInvalidate: false,
      });
    case FETCH_METRICS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        metrics: action.metrics,
      });
      case FETCH_METRICS_SUCCESS_CUSTOM:
      return Object.assign({}, state, {
        isFetchingTree: false,
        didInvalidate: false,
        metrics: action.metrics,
      });
    case FETCH_METRICS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.errorMessage,
        metrics: [],
      });
      case FETCH_METRICS_FAILURE_CUSTOM:
      return Object.assign({}, state, {
        isFetchingTree: false,
        didInvalidate: true,
        error: action.errorMessage,
        metrics: [],
      });
    case FETCH_METRICS_DEVICES:
      return Object.assign({}, state, {
        isFetchingDevice: true,
        didInvalidateDevice: false,
        restrictMetrics: false
      });
    case RESTRICT_METRICS_CHARTS:
        return Object.assign({}, state, {
          isFetchingDevice: false,
          restrictMetrics: action.payload?.restrict,
          restrictMetricsMsg: action.payload?.restrictMsg
        });
    case FETCH_METRICS_DEVICES_SUCCESS:
      return Object.assign({}, state, {
        isFetchingDevice: false,
        didInvalidateDevice: false,
        metricsForDevices: action.metricData,
        deviceIds: action.deviceIds,
        selectedMetrics: action.metricTypes,
        hours: action.hours,
        selectedCIs: action.selectedCIs,
        isInterface: action.isInterface,
        endDate: action.endDate,
      });
    case FETCH_METRICS_DEVICES_FAILURE:
      return Object.assign({}, state, {
        isFetchingDevice: false,
        didInvalidateDevice: true,
        errorDevice: action.errorMessage,
        deviceIds: action.deviceIds,
        selectedMetrics: action.metricTypes,
        hours: action.hours,
        selectedCIs: action.selectedCIs,
        isInterface: action.isInterface,
        endDate: action.endDate,
      });
    case CLEAR_CHART_DATA:
      return Object.assign({}, state, {
        metricsForDevices: [],
        deviceIds: "",
        selectedMetrics: [],
        selectedCIs: {},
        hours: 24,
        endDate: null,
      });
    case SAVE_NAMED_METRICS:
      return Object.assign({}, state, {
        isSaving: true,
        didInvalidateSave: false,
        savedData: {},
        isUpdateView: action.isUpdateView,
      });
    case SAVE_NAMED_METRICS_SUCCESS:
      return Object.assign({}, state, {
        isSaving: false,
        didInvalidateSave: false,
        savedData: action.named_metric,
        isUpdateView: action.isUpdateView,
      });
    case SAVE_NAMED_METRICS_FAILURE:
      return Object.assign({}, state, {
        isSaving: false,
        didInvalidateSave: true,
        errorInSaving: action.errorMessage,
        savedData: {},
      });
    case FETCH_NAMED_METRICS:
      return Object.assign({}, state, {
        isFetchingAll: true,
        didInvalidateFetchAll: false,
      });
    case FETCH_NAMED_METRICS_SUCCESS:
      return Object.assign({}, state, {
        isFetchingAll: false,
        didInvalidateFetchAll: false,
        named_metric_list: action.named_metric_list,
      });
    case FETCH_NAMED_METRICS_FAILURE:
      return Object.assign({}, state, {
        isFetchingAll: false,
        didInvalidateFetchAll: true,
        errorInFetchingAll: action.errorMessage,
      });
    case FETCH_NAMED_METRICS_BY_ID:
      return Object.assign({}, state, {
        isFetchingById: true,
        didInvalidateFetchById: false,
      });
    case FETCH_NAMED_METRICS_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        isFetchingById: false,
        didInvalidateFetchById: false,
        named_metric_data: action.named_metric_data,
        savedData: {},
      });
    case FETCH_NAMED_METRICS_BY_ID_FAILURE:
      return Object.assign({}, state, {
        isFetchingById: false,
        didInvalidateFetchById: true,
        errorInFetchingById: action.errorMessage,
      });
    case DELETE_NAMED_VIEWS:
      return Object.assign({}, state, {
        isdeletingView: true,
        deleteMsg: "",
      });
    case DELETE_NAMED_VIEWS_SUCCESS:
      return Object.assign({}, state, {
        isdeletingView: false,
        deleteMsg: action.deleteMsg,
      });
    case DELETE_NAMED_VIEWS_FAILURE:
      return Object.assign({}, state, {
        isdeletingView: false,
        deleteMsg: "",
      });
    case GET_DEVICE:
      return Object.assign({}, state, {
        isFetchingSearch: true,
        searchDeviceResults: "",
      });
    case GET_DEVICE_SUCCESS:
      return Object.assign({}, state, {
        isFetchingSearch: false,
        searchDeviceResults: action.devices.computerSystemList,
        isNextPage:action.devices.nextPage,
      });
    case GET_DEVICE_FAILURE:
      return Object.assign({}, state, {
        isFetchingSearch: false,
        searchDeviceResults: "",
      });
    case EXPORT_METRICS:
      return Object.assign({}, state, {
        isExportFetching: true,
        didInvalidateExportFetch: false,
        errorInExportFetch: "",
      });
    case EXPORT_METRICS_SUCCESS:
      return Object.assign({}, state, {
        exportData: action.exportData,
        isExportFetching: false,
        didInvalidateExportFetch: false,
        errorInExportFetch: "",
      });
    case EXPORT_METRICS_FAILURE:
      return Object.assign({}, state, {
        isExportFetching: false,
        didInvalidateExportFetch: true,
        errorInExportFetch: action.error,
      });
    case COPY_METRICS:
      return Object.assign({}, state, {
        isCopyFetching: true,
        isCopySuccess: false,
        didInvalidateCopyFetch: false,
        errorInCopyFetch: "",
      });
    case COPY_METRICS_SUCCESS:
      return Object.assign({}, state, {
        isCopyFetching: false,
        isCopySuccess: true,
        didInvalidateCopyFetch: false,
        errorInCopyFetch: "",
      });
    case COPY_METRICS_FAILURE:
      return Object.assign({}, state, {
        isCopyFetching: false,
        didInvalidateCopyFetch: true,
        isCopySuccess: false,
        errorInCopyFetch: action.error,
      });
    case COPY_DEVICE_METRICVIEW_SUCCESS:
      return Object.assign({}, state, {
        copyMetricViewDeviceLevel: action.data,
        copyMetricViewDeviceLevelSuccess: true,
        copyMetricViewDeviceLevelErrorMsg: ""
      });
    case COPY_DEVICE_METRICVIEW_FAILURE:
      return Object.assign({}, state, {
        copyMetricViewDeviceLevel: [],
        copyMetricViewDeviceLevelSuccess: false,
        copyMetricViewDeviceLevelErrorMsg: action.errorMessage
      });
    default:
      return state;
  }
};

export default metric_data;
