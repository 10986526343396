// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Please select parent of same account
  content["Please select parent of same account"] =
  "Please select parent of same account";

  //Error
  content["Error"] =
  "Error";

  //Cancel
  content["Cancel"] =
  "Cancel";

  // Ok
  content["Ok"] =
  "Ok";

  // Save
  content["Save"] =
  "Save";

  //Groups
  content["Groups"] =
  "Groups";

  // label
  content["Select a group"] =
  "Select a group";

  // message
  content["No groups available"] =
  "No groups available";

  // group
  content["Group"] =
  "Group";

  //Name
  content["Name"] =
  "Name";

  //Alias
  content["Alias"] =
  "Alias";

  //IP Address
  content["IP Address"] =
  "IP Address";

  //Caption
  content["Caption"] =
  "Caption";

  //Description
  content["Description"] =
  "Description";

  //Dedicated
  content["Dedicated"] =
  "Dedicated";

  //OS Type
  content["OS Type"] =
  "OS Type";

  //OS Name
  content["OS Name"] =
  "OS Name";

  //OS Version
  content["OS Version"] =
  "OS Version";

  //OS Description
  content["OS Description"] =
  "OS Description";

  //Origin
  content["Origin"] =
  "Origin";

  //MAC Address
  content["MAC Address"] =
  "MAC Address";

  //User Tag1
  content["User Tag1"] =
  "User Tag1";

  //User Tag2
  content["User Tag2"] =
  "User Tag2";

  //Removed
  content["Removed"] =
  "Removed";

  //Bus Type
  content["Bus Type"] =
  "Bus Type";

  //Device
  content["Device"] =
  "Device";

  //IfIndex
  content["If Index"] =
  "If Index";

  //IfPhysAddress
  content["If Physical Address"] =
  "If Physical Address";

  //IfType
  content["If Type"] =
  "If Type";

  //instanceId
  content["Instance Id"] =
  "Instance Id";

  //Finding matching devices failed
  content["Finding matching devices failed"] =
  "Finding matching devices failed";

  //Define search criteria and click “Find Devices” button
  content["Define search criteria and click “Find Devices” button"] =
  "Define search criteria and click “Find Devices” button";

  //No devices found. You may define a new criteria to find devices
  content["No devices found. You may define a new criteria to find devices"] =
  "No devices found. You may define a new criteria to find devices";

  //Matching Devices
  content["Matching Devices"] =
  "Matching Devices";

  //Found
  content["Found"] =
  "Found";

  //SearchforComputerSystems message
  content["Search for Computer Systems"] =
  "Search for Computer Systems";

  //Device Filters
  content["Device Filter"] =
  "Device Filter";

  //not
  content["not"] =
  "not";

  //Interface Filters
  content["Interfaces Filter"] =
  "Interfaces Filter";

  //Define Criteria
  content["Define Criteria"] =
  "Define Criteria";

  //Reset
  content["Reset"] =
  "Reset";

  //Hide
  content["Hide"] =
  "Hide";

  //Show
  content["Show"] =
  "Show";

  //Find Devices
  content["Find Devices"] =
  "Find Devices";

  // END OF LOCALIZATION

export default content;
