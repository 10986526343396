// This file was generated by GenerateES6Bundle.
var content = {};

  // LOCALIZE THE SECOND STRING LITERAL IN THE FOLLOWING PAIRS:

  //Metrics Monitoring
  content["Metrics Monitoring"] =
  "Metrics Monitoring";

  //Severity
  content["Severity"] =
  "Severity";

  //Name
  content["Name"] =
  "Name";

  //Information
  content["Information"] =
  "Information";

  //Device
  content["Device"] =
  "Device";

  //Utilization %
  content["Utilization %"] =
  "Utilization %";

  //Errors %
  content["Errors %"] =
  "Errors %";

  //Discards %
  content["Discards %"] =
  "Discards %";

  //Speed
  content["Speed"] =
  "Speed";

  //Index
  content["Index"] =
  "Index";

  //MAC
  content["MAC"] =
  "MAC";

  //Admin Status
  content["Admin Status"] =
  "Admin Status";

  //Oper Status
  content["Oper Status"] =
  "Oper Status";

  //Type
  content["Type"] =
  "Type";

  //MTU
  content["MTU"] =
  "MTU";

  //Description
  content["Description"] =
  "Description";

  //IfName
  content["IfName"] =
  "IfName";

  // Max Alarm Severity
  content["Max Alarm Severity"] =
  "Max Alarm Severity";

  //No Data Found
  content["No Data Found"] =
  "No Data Found";

  //Try different filter options
  content["Try different filter options"] =
  "Try different filter options";

  // No results found
  content["No results found"] =
  "No results found";

  //Interface group members
  content["Interface group members"] =
  "Interface group members";

  // END OF LOCALIZATION

export default content;
