/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { useEffect } from 'react'

import { Snackbar } from '@mineral/core'
import MuiAlert from '@mui/material/Alert'
import { makeStyles } from '@mui/styles'

import ComplianceSlaIcon from '../../assets/icons/complianceSlaIcon'
import NotComplianceSlaIcon from '../../assets/icons/notComplianceSlaIcon'
import InformationIcon from '../../assets/icons/informationIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    background: 'none !important'
  },
  alertBackground: {
    backgroundColor: 'white',
    color: '#333840',
  },
  alertIcon: {
    display: 'flex',
    alignItems: 'center',
  },
}))

function Alert(props) {
  const classes = useStyles()

  return (
    <>
      {props.severity === 'success' ? (
        <MuiAlert role="alert"
          icon={<ComplianceSlaIcon width={'24'} height={'24'} />}
          elevation={10}
          classes={{ standardSuccess: classes.alertBackground, icon: classes.alertIcon }}
          {...props}
        />
      ) : null}
      {props.severity === 'error' ? (
        <MuiAlert role="alert"
          icon={<NotComplianceSlaIcon size={'24'} />}
          elevation={10}
          classes={{ standardError: classes.alertBackground, icon: classes.alertIcon }}
          {...props}
        />
      ) : null}
      {props.severity === 'info' ? (
        <MuiAlert role="alert"
          icon={<InformationIcon width={'24'} height={'24'} />}
          elevation={10}
          classes={{ standardInfo: classes.alertBackground, icon: classes.alertIcon }}
          sx= {{
            backgroundColor: "white"
          }}
          {...props}
        />
      ) : null}
    </>
  )
}

const CustomSnackbar = ({ message = 'no message', severity = 'success', autoHideDuration = 5000, open, close }) => {
  const classes = useStyles()
  useEffect(() => {
    if (open) {
      setAlertScreenReader(severity)
    } else {
      setAlertScreenReader('')
    }
  }, [open])

  const setAlertScreenReader = (message) => {
    setTimeout(() => {
      if (document.querySelector('#alertAnnounceSLM') !== null && document.querySelector('#alertAnnouncerSLM').innerHTML !== message) {
        document.querySelector('#alertAnnouncerSLM').innerHTML = message
      }
    }, 1000)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setAlertScreenReader('')
    close(false)
  }

  return (
    <div className={classes.root}>
      {/* Okta Id: hg044122 Defect Id: DE534272, Desc: VPAT Issue(fixed focus issue) on OP page */}
      <Snackbar
        id="alertAnnouncer"
        aria-live="assertive"
        open={open}
        aria-label={message}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {/* Okta Id: hg044122 Defect Id: DE534272, Desc: VPAT Issue(alert message not read by screen reader) on OP page */}
        <div>
          <Alert
            onClose={handleClose}
            severity={severity}
            // action={
            //   <IconButton
            //     aria-label="close"
            //     color="inherit"
            //     size="small"
            //     onClick={() => {
            //       handleClose()
            //     }}
            //     autoFocus
            //   >
            //     <CloseIcon fontSize="inherit" />
            //   </IconButton>
            // }
          >{severity==='error'?(
           <span role="alert">{message}</span> ):(<span aria-live="assertive" style={{color: '#333840'}}>{message}</span>)}
          </Alert>
        </div>
      </Snackbar>
      {/* <div id="alertAnnouncerSLM" role="alert" aria-live="assertive" style={{ width: 0, height: 0, overflow: 'hidden' }}></div> */}
      {/* <Alert severity="error">{message}</Alert>
      <Alert severity="warning">{message}</Alert>
      <Alert severity="info">{message}</Alert>
      <Alert severity="success">{message}</Alert> */}
    </div>
  )
}

export default CustomSnackbar
