/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import { Route } from "react-router-dom";
import {
  IconButton, ListItem,
  ListItemButton, ListItemIcon,
  ListItemText, Tooltip,
  Menu, MenuItem, Checkbox
} from "@mineral/core";
import { MoreIcon } from "../ui-components/uim-components";
import DialogManager from "./DialogManager";
import entityTypes from "../../api/entityTypes";
import InventorySearch from "./InventorySearch";
import Account from "./TitleBarAccount";
import Resource from "./Resource";
import { get } from "../../utils/lodash-utils";
import GroupDashboardMenu from "./GroupDashboardMenu";
import { connect } from "react-redux";
import {
  deleteGroupDialog,
  toggleAddDeviceToGroup,
  toggleAddGroupDialog,
  toggleEditSubGroup,
} from "../../api/treeView/actions";
//All
export const ALL_TEXT = Resource.get("All");
//Open Alarms
export const OPEN_ALARM_TEXT = Resource.get("Open Alarms");
//Monitored Devices
export const MONITORED_DEVICES_TEXT = Resource.get("Monitored Devices");
//Devices By Roles
export const DEVICES_BY_ROLE_TEXT = Resource.get("Devices By Roles");
//Top Entities
export const TOP_ENTITIES_TEXT = Resource.get("Top 10 Entities");
//Technologies
export const TECHNOLOGIES_TEXT = Resource.get("Technologies");
//Groups
export const GROUPS_TEXT = Resource.get("Groups");
//Devices
export const DEVICES_TEXT = Resource.get("Devices");

const groupListPath = "/groups/:id(\\d+)*";
//Remove device(s) from Group Message
const RemoveDevice = Resource.get("Remove device(s) from Group");
//Edit Group Text
const EDIT_GROUP_TEXT = Resource.get("Edit group");

//Action Menu
const ACTION_MENU=Resource.get("Action Menu");

class TitleBarMenu extends Component {
  state = {
    showMenu: false,
    // subGroupOpen: false,
    // deleteSubGroupOpen: false,
    // updateGroupOpen: false,
    // addDeviceToGroupOpen: false,
    removeDevicesOpen: false,
    deleteDevicesOpen: false,
    installRobotOpen: false,
    errorDialog: false,
    errorType: "none",
    // editSubGroupOpen: false,
    menuActionAnchorEl: null,
    menuMoreAnchorEl: null,
  };
  handleMenuClick = () => {
    this.setState({
      showMenu: false,
    });
  };
  handleDialogClose = (dialogName) => {
    this.setState({
      [dialogName]: false,
      errorType: "none",
    });
    if (dialogName === "subGroupOpen") {
      this.props.toggleAddGroupDialog(false);
    } else if (dialogName === "deleteSubGroupOpen") {
      this.props.toggleDeleteGroupDialog(false);
    } else if (dialogName === "addDeviceToGroupOpen") {
      this.props.toggleAddDeviceToGroup(false);
    } else if (dialogName === "editSubGroupOpen") {
      this.props.toggleEditSubGroup(false);
    }
  };
  handleOnAddGroupMenuClick = () => {
    this.setState({
      showMenu: false,
      // subGroupOpen: true,
      menuActionAnchorEl: null,
    });
    this.props.toggleAddGroupDialog(true);
  };
  handleOnDeleteGroupMenuClick = () => {
    this.setState({
      showMenu: false,
      // deleteSubGroupOpen: true,
      menuActionAnchorEl: null,
    });
    this.props.toggleDeleteGroupDialog(true);
  };
  handleRenameGroupMenuClick = () => {
    this.setState({
      showMenu: false,
      renameSubGroupOpen: true,
      menuActionAnchorEl: null,
    });
  };
  handleEditGroupMenuClick = () => {
    this.setState({
      showMenu: false,
      // editSubGroupOpen: true,
      menuActionAnchorEl: null,
    });
    this.props.toggleEditSubGroup(true);
  };
  handleAddDeviceToGroupClick = () => {
    this.setState({
      showMenu: false,
      // addDeviceToGroupOpen: true,
      menuActionAnchorEl: null,
    });
    this.props.toggleAddDeviceToGroup(true);
  };
  handleRemoveDevicesClick = () => {
    this.setState({
      showMenu: false,
      removeDevicesOpen: true,
    });
  };
  handleDeleteDevicesClick = () => {
    this.setState({
      showMenu: false,
      deleteDevicesOpen: true,
    });
  };
  handleInstallRobotClick = () => {
    this.setState({
      showMenu: false,
      installRobotOpen: true,
    });
  };
  handleRequestChange = (open) => {
    this.setState({
      showMenu: open,
    });
  };
  handleErrorDialogOpen = (type) => {
    this.setState({
      errorDialog: true,
      errorType: type,
    });
  };
  onHomeMenuItemSelected = (event) => {
    let isChecked = event.target.checked;
    let stateObject = this.props.homeScreenComponents;
    if (event.target.id && event.target.id.includes(ALL_TEXT)) {
      if (isChecked) {
        stateObject = {
          ...stateObject,
          showOpenAlarms: true,
          showActiveDevices: true,
          showDevicesByRoles: true,
          showTopEntitiesContainer: true,
          showTopTechnologies: true,
          showTopGroups: true,
          showTopDevices: true,
        };
      } else {
        stateObject = {
          ...stateObject,
          showOpenAlarms: false,
          showActiveDevices: false,
          showDevicesByRoles: false,
          showTopEntitiesContainer: false,
          showTopTechnologies: false,
          showTopGroups: false,
          showTopDevices: false,
        };
      }
    } else if (event.target.id == OPEN_ALARM_TEXT) {
      if (isChecked) {
        stateObject = { ...stateObject, showOpenAlarms: true };
      } else {
        stateObject = { ...stateObject, showOpenAlarms: false };
      }
    } else if (event.target.id == MONITORED_DEVICES_TEXT) {
      if (isChecked) {
        stateObject = { ...stateObject, showActiveDevices: true };
      } else {
        stateObject = { ...stateObject, showActiveDevices: false };
      }
    } else if (event.target.id == DEVICES_BY_ROLE_TEXT) {
      if (isChecked) {
        stateObject = { ...stateObject, showDevicesByRoles: true };
      } else {
        stateObject = { ...stateObject, showDevicesByRoles: false };
      }
    } else if (event.target.id == TOP_ENTITIES_TEXT) {
      if (isChecked) {
        stateObject = {
          ...stateObject,
          showTopEntitiesContainer: true,
          showTopTechnologies: true,
          showTopGroups: true,
          showTopDevices: true,
        };
      } else {
        stateObject = {
          ...stateObject,
          showTopEntitiesContainer: false,
          showTopTechnologies: false,
          showTopGroups: false,
          showTopDevices: false,
        };
      }
    } else if (event.target.id == TECHNOLOGIES_TEXT) {
      if (isChecked) {
        stateObject = {
          ...stateObject,
          showTopTechnologies: true,
          showTopEntitiesContainer: true,
        };
      } else {
        stateObject = {
          ...stateObject,
          showTopTechnologies: false,
          showTopEntitiesContainer:
            stateObject.showTopDevices || stateObject.showTopGroups,
        };
      }
    } else if (event.target.id == GROUPS_TEXT) {
      if (isChecked) {
        stateObject = {
          ...stateObject,
          showTopGroups: true,
          showTopEntitiesContainer: true,
        };
      } else {
        stateObject = {
          ...stateObject,
          showTopGroups: false,
          showTopEntitiesContainer:
            stateObject.showTopDevices || stateObject.showTopTechnologies,
        };
      }
    } else if (event.target.id == DEVICES_TEXT) {
      if (isChecked) {
        stateObject = {
          ...stateObject,
          showTopDevices: true,
          showTopEntitiesContainer: true,
        };
      } else {
        stateObject = {
          ...stateObject,
          showTopDevices: false,
          showTopEntitiesContainer:
            stateObject.showTopGroups || stateObject.showTopTechnologies,
        };
      }
    }

    this.props.onHomeComponentChanged(stateObject);
  };
  handleActionMenuOpen = (event) => {
    this.setState({ menuActionAnchorEl: event.currentTarget });
  };
  handleActionMenuClose = (event) => {
    this.setState({ menuActionAnchorEl: null });
  };
  handleMenuMoreOpen = (event) => {
    this.setState({ menuMoreAnchorEl: event.currentTarget });
  };
  handleMenuMoreClose = (event) => {
    this.setState({ menuMoreAnchorEl: null });
  };

  render() {
    const entityType = this.props.entityType;
    const selectedCsIds = this.props.selectedCsIds;
    const currentView = this.props.currentView;
    const selectedIfIds = this.props.selectedIfIds;
    const setSelectedIfIds = this.props.setSelectedIfIds;
    const selectedNodeType = window.location.pathname.endsWith("/treeviewnew")
      ? this.props.treeView.treeNode.entityType
      : entityType;
    const groupType = window.location.pathname.endsWith("/treeviewnew")
      ? selectedNodeType
      : entityType;
    let parentGroupType = window.location.pathname.endsWith("/treeviewnew")
      ? this.props.treeView.treeNode.parentType
      : "";
    let childrenType = window.location.pathname.endsWith("/treeviewnew")
      ? this.props.treeView.treeNode.childrenType
      : "";
    if (!window.location.pathname.endsWith("/treeviewnew")) {
      parentGroupType = "EMPTY";
      childrenType = "";
      if (
        this.props.entityType === entityTypes.CONTAINER &&
        this.props.entities.length > 0
      ) {
        childrenType = this.props.entities[0].type;
        if (childrenType === "AUTOMATIC") {
          parentGroupType = "AUTOMATIC";
        } else if (
          childrenType === "DYNAMIC" ||
          childrenType === "STATIC" ||
          childrenType === "CONTAINER"
        ) {
          parentGroupType = "NON_REMOVABLE";
        }
      }
    }

    var disableRemoveDevice = true;
    if (selectedIfIds && selectedIfIds.length > 0) {
      disableRemoveDevice = false;
    }
    if (selectedCsIds && selectedCsIds.length > 0) {
      disableRemoveDevice = false;
    }
    let classes = "titlebar__action-menu";
    let clickTarget = this.handleRequestChange;
    let disableMenu = false;
    if (this.props.isFetching) {
      classes += " titleBar__menu--disable";
      clickTarget = null;
      disableMenu = true;
    }
    let canModifyGroup = get(this.props.acl.aclInfo, "roles", "").includes(
      "USM Group Modification"
    );
    let canAddGroup = get(this.props.acl.aclInfo, "roles", "").includes(
      "OC Group Add"
    );
    let canEditGroup = get(this.props.acl.aclInfo, "roles", "").includes(
      "OC Group Edit"
    );
    let canRemoveGroup = get(this.props.acl.aclInfo, "roles", "").includes(
      "OC Group Delete"
    );
    //dp039841
    let canUpdatePassword = get(this.props.acl.aclInfo, "roles", "").includes(
      "Change Password"
    );
    let aclAccountUser = get(this.props.acl.aclInfo, "userType", "").includes(
      "contact"
    );
    let threeDotMenu = (
      <Route
        path="/(groups|inventory)"
        render={(props, saas = this.props.saas) => {
          if (!saas && props.location.pathname === "/inventory") {
            return null; // UNDO this for more menu items in the future.  Inventory currently only has delete devices
          }
          return (
            <span style={{ display: "contents" }}>
              {/* Action Menu Name Message */}
              {currentView == "CABI" && <Tooltip title="Show/Hide Views"><IconButton
                disabled={disableMenu}
               title={ACTION_MENU}
                name={ACTION_MENU}
                aria-controls="menuActionTitle"
                aria-haspopup="true"
                onClick={this.handleActionMenuOpen}
                style={{ marginLeft: "35px" }}
              >
                <MoreIcon />
              </IconButton></Tooltip> }
              <Menu
                id="menuActionTitle"
                anchorEl={this.state.menuActionAnchorEl}
                keepMounted
                onClose={this.handleActionMenuClose}
                open={this.state.menuActionAnchorEl != null}
              >
                {/*<Route
                  path="/groups"
                  render={() => {
                    if (
                      (canModifyGroup || canAddGroup) &&
                      (groupType === entityTypes.CONTAINER ||
                        groupType === entityTypes.ROOT) &&
                      !(
                        parentGroupType === entityTypes.AUTOMATIC &&
                        childrenType === entityTypes.AUTOMATIC
                      ) &&
                      !window.location.pathname.endsWith("/alarms") &&
                      !window.location.pathname.endsWith("/dashboard") &&
                      !window.location.pathname.endsWith("/configuration")
                    ) {
                      //Add group Message
                      return (
                        <MenuItem
                          children={Resource.get("Add group")}
                          onClick={this.handleOnAddGroupMenuClick}
                        />
                      );
                    } else {
                      return null;
                    }
                  }}
                />

                <Route
                  path="/groups"
                  render={() => {
                    if (
                      (canModifyGroup || canEditGroup) &&
                      // this.props.criteria !== null &&
                      // groupType !== entityTypes.ROOT &&
                      groupType !== entityTypes.STATIC &&
                      groupType !== entityTypes.ROOT &&
                      groupType !== entityTypes.AUTOMATIC &&
                      !window.location.pathname.endsWith("/groups/0") &&
                      !window.location.pathname.endsWith("/groups/0/alarms") &&
                      !window.location.pathname.endsWith(
                        "/groups/0/dashboard"
                      ) &&
                      !window.location.pathname.endsWith("/groups/0/list")
                    ) {
                      //edit group Message
                      return (
                        <MenuItem
                          children={EDIT_GROUP_TEXT}
                          onClick={this.handleEditGroupMenuClick}
                        />
                      );
                    } else {
                      return null;
                    }
                  }}
                />

                <Route
                  path="/groups"
                  render={() => {
                    if (
                      (canModifyGroup || canEditGroup) &&
                      groupType !== entityTypes.CONTAINER &&
                      groupType !== entityTypes.ROOT &&
                      groupType === entityTypes.STATIC
                    ) {
                      return (
                        //Edit static group Message
                        <MenuItem
                          children={EDIT_GROUP_TEXT}
                          onClick={this.handleAddDeviceToGroupClick}
                        />
                      );
                    } else {
                      return null;
                    }
                  }}
                />
                <Route
                  path="/groups"
                  render={() => {
                    if (
                      (canModifyGroup || canRemoveGroup) &&
                      groupType !== entityTypes.ROOT &&
                      groupType !== entityTypes.AUTOMATIC &&
                      parentGroupType !== "NON_REMOVABLE" &&
                      !(
                        parentGroupType === entityTypes.AUTOMATIC &&
                        childrenType === entityTypes.AUTOMATIC
                      ) &&
                      !window.location.pathname.endsWith("/groups/0") &&
                      !window.location.pathname.endsWith("/groups/0/alarms") &&
                      !window.location.pathname.endsWith("/groups/0/cabi") &&
                      !window.location.pathname.endsWith("/groups/0/list")
                    ) {
                      // Delete group message
                      return (
                        <MenuItem
                          children={Resource.get("Delete group")}
                          onClick={this.handleOnDeleteGroupMenuClick}
                        />
                      );
                    } else {
                      return null;
                    }
                  }}
                />*/}
                {this.props.saas ? (
                  <Route
                    path={groupListPath}
                    render={() => {
                      return (
                        <MenuItem
                          //Delete device(s) Menu Item title
                          children={Resource.get("Delete device(s)")}
                          onClick={this.handleDeleteDevicesClick}
                          disabled={selectedCsIds.length < 1}
                        />
                      );
                    }}
                  />
                ) : null}
                {this.props.saas ? (
                  <Route
                    path="/inventory"
                    render={() => {
                      return (
                        <MenuItem
                          //Delete device(s) Menu Item title
                          children={Resource.get("Delete device(s)")}
                          onClick={this.handleDeleteDevicesClick}
                          disabled={selectedCsIds.length < 1}
                        />
                      );
                    }}
                  />
                ) : null}
                {this.props.saas ? (
                  <Route
                    path="/inventory"
                    render={() => {
                      return (
                        <MenuItem
                          //Delete device(s) Menu Item title
                          children={Resource.get("Deploy Robot(s)")}
                          onClick={this.handleInstallRobotClick}
                          disabled={selectedCsIds.length < 1}
                        />
                      );
                    }}
                  />
                ) : null}
                {currentView == "CABI" ? (
                  <GroupDashboardMenu {...this.props} />
                ) : null}
              </Menu>
              <DialogManager
                handleDialogClose={this.handleDialogClose}
                deleteSubGroupOpen={this.props.isDeleteGroupDialog}
                subGroupOpen={this.props.isGroupDialogOpen}
                editSubGroupOpen={this.props.isEditSubGroup}
                addDeviceToGroupOpen={this.props.isAddDeviceToGroup}
                renameSubGroupOpen={this.state.renameSubGroupOpen}
                removeDevicesOpen={this.state.removeDevicesOpen}
                deleteDevicesOpen={this.state.deleteDevicesOpen}
                installRobotOpen={this.state.installRobotOpen}
                errorDialog={this.state.errorDialog}
                handleErrorDialogOpen={this.handleErrorDialogOpen}
                errorType={this.state.errorType}
                {...this.props}
              />
            </span>
          );
        }}
      />
    );
    if (
      //(this.props.saas && this.props.acl.acl === AclTypes.OPERATOR) ||
      (this.props.saas &&
        !(canModifyGroup || canAddGroup || canEditGroup || canRemoveGroup)) ||
      (!this.props.saas &&
        (!this.props.groupManagementPermission ||
          !(canModifyGroup || canAddGroup || canEditGroup || canRemoveGroup)))
    ) {
      threeDotMenu = null;
    }
    if (this.props.location.pathname === "/overview") {
      let allMenuSelected =
        this.props.homeScreenComponents.showOpenAlarms &&
        this.props.homeScreenComponents.showActiveDevices &&
        this.props.homeScreenComponents.showDevicesByRoles &&
        this.props.homeScreenComponents.showTopEntitiesContainer &&
        this.props.homeScreenComponents.showTopTechnologies &&
        this.props.homeScreenComponents.showTopGroups &&
        this.props.homeScreenComponents.showTopDevices;

      let topEntityContainer =
        this.props.homeScreenComponents.showTopTechnologies ||
        this.props.homeScreenComponents.showTopGroups ||
        this.props.homeScreenComponents.showTopDevices;
      //Show hide views
       let showHideText = Resource.get("Show / Hide Views");
      threeDotMenu = (
        <div>
            <Tooltip title={showHideText}>
          <IconButton
            aria-controls="menuMoreAction"
            aria-haspopup="true"
            onClick={this.handleMenuMoreOpen}
            style={{ marginLeft: "32px" }}
             name={ACTION_MENU}
          >
            <MoreIcon />
          </IconButton>
          </Tooltip>
          <Menu aria-label="Actions"
            id="menuMoreAction"
            className="homeWidgetsMenu"
            anchorEl={this.state.menuMoreAnchorEl}
            keepMounted
            onClose={this.handleMenuMoreClose}
            open={this.state.menuMoreAnchorEl != null}
            style={{
              display: "flex",
              flexGrow: "1",
              flexDirection: "row-reverse",
              width: "250px",
              maxHeight: "354px",
            }}
            role="group" component="div"
          >
            <div
              className="titlebar_home_menu_subheader"
              autofocus={false}
              tabIndex={-1} style={{marginLeft:'16px'}}
            >
              {showHideText}
            </div>

            <ListItemButton component="div"
              autofocus={true}
              onClick={() =>
                this.onHomeMenuItemSelected({
                  target: {
                    id: ALL_TEXT,
                    checked: !allMenuSelected,
                  },
                })
              }
              style={{ padding: 0 ,marginLeft:'16px'}} role="group"
            >
              <ListItem component="div"
                className="titlebar_home_menu_item"
                disabled={false}
                onClick={this.onHomeMenuItemSelected}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={allMenuSelected}
                    InputProps={{'aria-labelledby':{ALL_TEXT}}}
                    color="primary"
                    tabIndex={0}
                  />
                </ListItemIcon>
                <ListItemText primary={ALL_TEXT} id={ALL_TEXT} />
              </ListItem>
            </ListItemButton>
            <ListItemButton component="div"
              onClick={() =>
                this.onHomeMenuItemSelected({
                  target: {
                    id: OPEN_ALARM_TEXT,
                    checked: !this.props.homeScreenComponents.showOpenAlarms,
                  },
                })
              } role="group"
              style={{ padding: 0 ,marginLeft:'16px'}}
            >
              <ListItem component="div" className="titlebar_home_menu_item" disabled={false}>
                <ListItemIcon>
                  <Checkbox
                    //onChange={this.onHomeMenuItemSelected}
                    checked={this.props.homeScreenComponents.showOpenAlarms}
                    InputProps={{'aria-labelledby':{OPEN_ALARM_TEXT}}}
                    color="primary"
                    tabIndex={0}
                  />
                </ListItemIcon>
                <ListItemText primary={OPEN_ALARM_TEXT} id={OPEN_ALARM_TEXT} />
              </ListItem>
            </ListItemButton>
            <ListItemButton component="div"
              onClick={() =>
                this.onHomeMenuItemSelected({
                  target: {
                    id: MONITORED_DEVICES_TEXT,
                    checked: !this.props.homeScreenComponents.showActiveDevices,
                  },
                })
              }
              role="group"
              style={{ padding: 0 ,marginLeft:'16px'}}
            >
              <ListItem  component="div" className="titlebar_home_menu_item" disabled={false}>
                <ListItemIcon>
                  <Checkbox
                   tabIndex={0}
                    checked={this.props.homeScreenComponents.showActiveDevices}
                    InputProps={{'aria-labelledby':{MONITORED_DEVICES_TEXT}}}
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={MONITORED_DEVICES_TEXT}
                  id={MONITORED_DEVICES_TEXT}
                />
              </ListItem>
            </ListItemButton>
            <ListItemButton component="div"
              onClick={() =>
                this.onHomeMenuItemSelected({
                  target: {
                    id: DEVICES_BY_ROLE_TEXT,
                    checked:
                      !this.props.homeScreenComponents.showDevicesByRoles,
                  },
                })
              }
              role="group"
              style={{ padding: 0 ,marginLeft:'16px'}}
            >
              <ListItem component="div" className="titlebar_home_menu_item" disabled={false}>
                <ListItemIcon>
                  <Checkbox
                   tabIndex={0}
                    checked={this.props.homeScreenComponents.showDevicesByRoles}
                    InputProps={{'aria-labelledby':{DEVICES_BY_ROLE_TEXT}}}
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={DEVICES_BY_ROLE_TEXT}
                  id={DEVICES_BY_ROLE_TEXT}
                />
              </ListItem>
            </ListItemButton>
            <ListItemButton component="div"
              onClick={() =>
                this.onHomeMenuItemSelected({
                  target: {
                    id: TOP_ENTITIES_TEXT,
                    checked: !topEntityContainer,
                  },
                })
              }
              role="group"
              style={{ padding: 0 ,marginLeft:'16px'}}
            >
              <ListItem component="div"
                className="titlebar_home_menu_item"
                //open={true}
                //primaryTogglesNestedList={false}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={topEntityContainer}
                    InputProps={{'aria-labelledby':{TOP_ENTITIES_TEXT}}}
                    tabIndex={0}
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={TOP_ENTITIES_TEXT}
                  id={TOP_ENTITIES_TEXT}
                />
              </ListItem>
            </ListItemButton>
            <ListItemButton component="div"
              onClick={() =>
                this.onHomeMenuItemSelected({
                  target: {
                    id: TECHNOLOGIES_TEXT,
                    checked:
                      !this.props.homeScreenComponents.showTopTechnologies,
                  },
                })
              }
              role="group"
              style={{ padding: 0,marginLeft:'35px' }}
            >
              <ListItem
                key={TECHNOLOGIES_TEXT} component="div"
                className="titlebar_home_menu_item"
              >
                <ListItemIcon>
                  <Checkbox
                   tabIndex={0}
                    checked={
                      this.props.homeScreenComponents.showTopTechnologies
                    }
                    InputProps={{'aria-labelledby':{TECHNOLOGIES_TEXT}}}
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={TECHNOLOGIES_TEXT}
                  id={TECHNOLOGIES_TEXT}
                />
              </ListItem>
            </ListItemButton>
            <ListItemButton role="group" component="div"
              onClick={() =>
                this.onHomeMenuItemSelected({
                  target: {
                    id: GROUPS_TEXT,
                    checked: !this.props.homeScreenComponents.showTopGroups,
                  },
                })
              }
              style={{ padding: 0,marginLeft:'35px' }}
            >
              <ListItem component="div" key={GROUPS_TEXT} className="titlebar_home_menu_item">
                <ListItemIcon>
                  <Checkbox  tabIndex={0}
                    checked={this.props.homeScreenComponents.showTopGroups}
                    InputProps={{'aria-labelledby':{GROUPS_TEXT}}}
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText primary={GROUPS_TEXT} id={GROUPS_TEXT} />
              </ListItem>
            </ListItemButton>
            <ListItemButton role="group" component="div"
              onClick={() =>
                this.onHomeMenuItemSelected({
                  target: {
                    id: DEVICES_TEXT,
                    checked: !this.props.homeScreenComponents.showTopDevices,
                  },
                })
              }
              style={{ padding: 0,marginLeft:'35px' }}
            >
              <ListItem component="div" key={DEVICES_TEXT} className="titlebar_home_menu_item">
                <ListItemIcon>
                  <Checkbox  tabIndex={0}
                    checked={this.props.homeScreenComponents.showTopDevices}
                    InputProps={{'aria-labelledby':{DEVICES_TEXT}}}
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText primary={DEVICES_TEXT} id={DEVICES_TEXT} />
              </ListItem>
            </ListItemButton>
          </Menu>
        </div>
      );
    }
    return (
      <div className="titlebarheader__menu">
        <InventorySearch
          location={this.props.location.pathname}
          setSearchTerm={this.props.setSearchTerm}
          setQuickFilter={this.props.setQuickFilter}
        />
        {threeDotMenu}

        {/*{this.props.isSaas === 'true' ? (*/}
        <Account
          saas={this.props.saas}
          sid={this.props.sid}
          aclAccountUser={aclAccountUser}
          canUpdatePassword={canUpdatePassword}
          isLdapUser={this.props.acl.aclInfo.ldapUser}
          updatePassword={this.props.updatePassword}
          success={this.props.successMessage}
          error={this.props.errorMessage}
          resetResponseMsg={this.props.resetResponseMsg}
          makeThisMyDefaultView={this.props.makeThisMyDefaultView}
          goToPersonalize={this.goToPersonalize}
          userpreferences={this.props.userpreferences}
          fetchGlobalAndPersonalUserPreferences={
            this.props.fetchGlobalAndPersonalUserPreferences
          }
        />

        {/*) : null}*/}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAddGroupDialog: (isToggle) =>
      dispatch(toggleAddGroupDialog(isToggle)),
    toggleDeleteGroupDialog: (isToggle) =>
      dispatch(deleteGroupDialog(isToggle)),
    toggleAddDeviceToGroup: (isToggle) =>
      dispatch(toggleAddDeviceToGroup(isToggle)),
    toggleEditSubGroup: (isToggle) => dispatch(toggleEditSubGroup(isToggle)),
  };
};

const mapStateToProps = (state) => {
  return {
    isGroupDialogOpen: state.tree_view.isToggleAddGroupDialog,
    isDeleteGroupDialog: state.tree_view.isDeleteGroupDialog,
    isAddDeviceToGroup: state.tree_view.toggleAddDeviceToGroup,
    isEditSubGroup: state.tree_view.toggleEditSubGroup,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TitleBarMenu);
