/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import { cloneDeep } from "../../../utils/lodash-utils";
import Resource from "./Resource";
import { TextField, Tooltip } from "@mineral/core";
import { Grid, FilterIcon } from "../../ui-components/uim-components";
import "./dynamicGroupDialog.less";
import BusTypeCellRenderer from "./BusTypeCellRenderer";
import infoIcon from "../../../assets/icons/infomessage.svg";
import { sortColumns } from "../../../utils/tableUtils";

/**
 * @Author namam01
 */
class MatchingDevices extends Component {
  /** Life cycle methods */
  constructor(props) {
    super(props);
    this.intialState = {
      elementType: props.elementType,
      rowData: [],
      deviceSearchQuery: "",
      error: null,
      showDefaultFindText: true,
      dataloadedFromCriteria: false,
    };
    this.clonedInitialState = cloneDeep(this.intialState);
    this.state = this.intialState;
  }
  gridContainer = null;
  MIN_HEIGHT = 200;
  matchingDevicesContainer = null;
  componentDidMount() {
    if (this.gridContainer) {
      this.updateGridSize();
    }
    if (this.gridApi) {
      this.gridApi.hideOverlay();
    }
  }
  componentDidUpdate() {
    if (this.gridContainer) {
      this.updateGridSize();
    }
  }
  updateGridSize() {
    if (
      this.gridContainer &&
      this.matchingDevicesContainer &&
      this.matchingDevicesContainer.parentNode
    ) {
      let container = this.matchingDevicesContainer.parentNode,
        dims = container.getBoundingClientRect(),
        gridDims = this.gridContainer.getBoundingClientRect(),
        value = Math.floor(dims.height - (gridDims.top - dims.top));
      if (value < this.MIN_HEIGHT) {
        value = this.MIN_HEIGHT;
      }
      value = value + "px";
      if (value !== this.gridContainer.style.height) {
        this.gridContainer.style.height = value;
      }
    }
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.elementType !== this.state.elementType) {
      setTimeout(() => {
        if (this.gridApi) {
          let columnDefs =
            newProps.elementType == "INTERFACES"
              ? this.createInterfaceColumnDefs()
              : this.createColumnDefs();
          this.gridApi.setColumnDefs(columnDefs);
        }
        this.setState({
          elementType: newProps.elementType,
        });
      }, 100);
    }
    if (newProps.matchingDevicesInfo) {
      if (newProps.matchingDevicesInfo.updatingFromCriteria) {
        this.setState({
          dataloadedFromCriteria: true,
          deviceSearchQuery: "",
        });
      } else {
        this.setState({
          deviceSearchQuery: "",
        });
      }
    }

    if (newProps.matchingDevicesInfo.error == null) {
      this.setState({
        rowData: newProps.matchingDevicesInfo.matchingDevices,
        error: null,
      });
      setTimeout(() => {
        this.sortColumns("attributes.DisplayName", "asc");
      }, 50);
      if (
        newProps.matchingDevicesInfo &&
        newProps.matchingDevicesInfo.matchingDevices &&
        newProps.matchingDevicesInfo.matchingDevices.length == 0 &&
        newProps.matchingDevicesInfo.updatingFromCriteria
      ) {
        if (this.gridApi) {
          this.gridApi.showNoRowsOverlay();
        }
      } else {
        if (this.gridApi) {
          this.gridApi.hideOverlay();
        }
      }
    } else {
      this.setState({
        error: newProps.matchingDevicesInfo.error,
        rowData: [],
      });
    }
  };
  componentWillUnmount = () => {
    this.setState({
      rowData: [],
      deviceSearchQuery: "",
      error: null,
      showDefaultFindText: true,
    });
  };
  /** create column definitions **/
  createColumnDefs = () => {
    const name = Resource.get("Name"); //Name
    const alias = Resource.get("Alias"); //Alias
    const ipAddress = Resource.get("IP Address"); //IP Address
    const caption = Resource.get("Caption"); //Caption
    const description = Resource.get("Description"); //Description
    const dedicated = Resource.get("Dedicated"); //Dedicated
    const osType = Resource.get("OS Type"); //OS Type
    const osName = Resource.get("OS Name"); //OS Name
    const osVersion = Resource.get("OS Version"); //OS Version
    const osDescription = Resource.get("OS Description"); //OS Description
    const origin = Resource.get("Origin"); //Origin
    const macAddress = Resource.get("MAC Address"); //MAC Address
    const userTag1 = Resource.get("User Tag1"); //User Tag1
    const userTag2 = Resource.get("User Tag2"); //User Tag2
    const removed = Resource.get("Removed"); //Removed
    const busType = Resource.get("Bus Type"); //Bus Type

    const nameCell = {
      headerName: name,
      field: "attributes.DisplayName",
      width: 250,
      suppressSizeToFit: true,
      //headerComponentFramework: CustomIconSortHeaderComponent,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const aliasCell = {
      headerName: alias,
      field: "attributes.DisplayAlias",
      //headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const ipAddressCell = {
      headerName: ipAddress,
      field: "attributes.PrimaryIPV4Address",
      //headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const captionCell = {
      headerName: caption,
      field: "attributes.caption",
      //headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const descriptionCell = {
      headerName: description,
      field: "attributes.description",
      // headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const dedicatedCell = {
      headerName: dedicated,
      field: "attributes.dedicated",
      // headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const osTypeCell = {
      headerName: osType,
      field: "attributes.os_type",
      //headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const osNameCell = {
      headerName: osName,
      field: "attributes.os_name",
      // headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const osVersionCell = {
      headerName: osVersion,
      field: "attributes.os_version",
      // headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const osDescriptionCell = {
      headerName: osDescription,
      field: "attributes.OSDescription",
      // headerComponentFramework: CustomIconSortHeaderComponent,
      width: 160,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const originCell = {
      headerName: origin,
      field: "origins",
      // headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const macAddressCell = {
      headerName: macAddress,
      field: "attributes.PrimaryMacAddress",
      // headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const userTag1Cell = {
      headerName: userTag1,
      field: "attributes.user_tag_1",
      // headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const userTag2Cell = {
      headerName: userTag2,
      field: "attributes.user_tag_2",
      //  headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const removedCell = {
      headerName: removed,
      field: "attributes.removed",
      //  headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      resizable: true,
      sortable: true,
      suppressNavigable:true
    };
    const busTypeCell = {
      headerName: busType,
      field: "attributes.nimbus_type",
      resizable: true,
      suppressNavigable:true,
      // headerComponentFramework: CustomIconSortHeaderComponent,
      cellRendererFramework: BusTypeCellRenderer,
      getQuickFilterText: function (params) {
        if (params.value && params.value[0] === "1") {
          return "Robot";
        } else if (params.value && params.value[0] === "2") {
          return "Hub";
        } else {
          return "-";
        }
      },
      width: 150,
      sortable: true,
    };

    return [
      nameCell,
      aliasCell,
      ipAddressCell,
      captionCell,
      descriptionCell,
      dedicatedCell,
      osTypeCell,
      osNameCell,
      osVersionCell,
      osDescriptionCell,
      originCell,
      macAddressCell,
      userTag1Cell,
      userTag2Cell,
      removedCell,
      busTypeCell,
    ];
  };
  /** create column definitions **/
  createInterfaceColumnDefs = () => {
    const name = Resource.get("Name"); //Name
    const alias = Resource.get("Alias"); //Alias
    const device = Resource.get("Device"); //Device
    const IfIndex = Resource.get("If Index"); //IfIndex
    const IfPhysAddress = Resource.get("If Physical Address"); //IfPhysAddress
    const IfType = Resource.get("If Type"); //IfType
    const Origin = Resource.get("Origin"); //Origin
    const instanceId = Resource.get("Instance Id"); //instanceId

    const nameCell = {
      headerName: name,
      field: "attributes.DisplayName",
      width: 250,
      suppressSizeToFit: true,
      //  headerComponentFramework: CustomIconSortHeaderComponent,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const aliasCell = {
      headerName: alias,
      field: "attributes.DisplayAlias",
      //  headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const deviceCell = {
      headerName: device,
      field: "attributes.name",
      //  headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const IfIndexCell = {
      headerName: IfIndex,
      field: "attributes.IfIndex",
      //  headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const IfPhysAddressCell = {
      headerName: IfPhysAddress,
      field: "attributes.IfPhysAddress",
      //  headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const IfTypeCell = {
      headerName: IfType,
      field: "attributes.IfType",
      //  headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const OriginCell = {
      headerName: Origin,
      field: "attributes.Origin",
      //  headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    const instanceIdCell = {
      headerName: instanceId,
      field: "instanceId",
      //  headerComponentFramework: CustomIconSortHeaderComponent,
      width: 150,
      sortable: true,
      resizable: true,
      suppressNavigable:true
    };
    return [
      nameCell,
      aliasCell,
      deviceCell,
      IfIndexCell,
      IfPhysAddressCell,
      IfTypeCell,
      OriginCell,
      instanceIdCell,
    ];
  };
  /** Grid functionality supporting functions **/
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    if (this.gridApi) {
      let columnDefs =
        this.state.elementType == "INTERFACES"
          ? this.createInterfaceColumnDefs()
          : this.createColumnDefs();
      this.gridApi.setColumnDefs(columnDefs);
    }
    if (
      this.state.error == null ||
      (this.state.rowData && this.state.rowData.length == 0)
    ) {
      if (this.state.showDefaultFindText) {
        this.setState({
          showDefaultFindText: false,
        });
      }
      //this.gridApi.showNoRowsOverlay()
    } else {
      // this.gridApi.hideOverlay()
    }
  };
  handleSearchTextChange = (e) => {
    this.setState({ deviceSearchQuery: e.target.value.replace(/^\s+/g, "") });
  };
  sortColumns = (column, order) => {
    const newSortedData = sortColumns(column, order, this.state.rowData);
    this.setState({
      rowData: newSortedData || [],
    });
  };
  buildErrorMessageTemplate = () => {
    let messageToShow = "";
    if (this.state.error != null) {
      //Finding matching devices failed
      let defaultErrorMessage = Resource.get("Finding matching devices failed");
      messageToShow =
        this.state.error.error == null
          ? defaultErrorMessage
          : this.state.error.error;
    } else {
      if (this.props.mode == "ADD" && this.state.showDefaultFindText) {
        //Define search criteria and click “Find Devices” button
        messageToShow = Resource.get(
          "Define search criteria and click “Find Devices” button"
        );
      } else {
        //No devices found. You may define a new criteria to find devices
        messageToShow = Resource.get(
          "No devices found. You may define a new criteria to find devices"
        );
      }
    }
    if (this.props.mode == "ADD" && this.state.showDefaultFindText) {
      return (
        '<div><div class="error-icon"><a style="float: left"><img src=' +
        infoIcon +
        '></a></div><div class="overlay-template-message-info">' +
        messageToShow +
        "</div></div>"
      );
    }
    return `<div>
                      <div class="error-icon">
                        <a style="float: left">
                          <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <title>outline-error_outline-24px</title>
                            <desc>Created with Sketch.</desc>
                            <defs></defs>
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
                            <g id="17.3-Discover-Devices" transform="translate(-770.000000, -339.000000)">
                            <g id="outline-error_outline-24px" transform="translate(769.000000, 338.000000)" fill="#A11E2E" fillRule="nonzero">
                            <g id="Outline" transform="translate(1.500000, 1.500000)">
                              <path d="M6.75,9.75 L8.25,9.75 L8.25,11.25 L6.75,11.25 L6.75,9.75 Z M6.75,3.75 L8.25,3.75 L8.25,8.25 L6.75,8.25 L6.75,3.75 Z M7.4925,0 C3.3525,0 0,3.36 0,7.5 C0,11.64 3.3525,15 7.4925,15 C11.64,15 15,11.64 15,7.5 C15,3.36 11.64,0 7.4925,0 Z M7.5,13.5 C4.185,13.5 1.5,10.815 1.5,7.5 C1.5,4.185 4.185,1.5 7.5,1.5 C10.815,1.5 13.5,4.185 13.5,7.5 C13.5,10.815 10.815,13.5 7.5,13.5 Z" id="Shape"></path>
                            </g>
                            </g>
                            </g>
                            </g>
                          </svg>
                        </a>
                      </div>
                      <div class="overlay-template-message-error">
                        ${messageToShow}
                      </div>
                    </div>`;
  };
  render() {
    //Matching Devices
    let matchingDevicesLabel = Resource.get("Matching Devices");
    let overlayMessageTemplate = this.buildErrorMessageTemplate();
    let found = Resource.get("Found"); //Found
    let noOfDevices = this.state.rowData ? this.state.rowData.length : 0;
    let countLabel = noOfDevices + " " + found;

    return (
      <div
        id="matching-devices-table"
        ref={(element) => {
          this.matchingDevicesContainer = element;
        }}
        className="matching-devices-table"
      >
        <div
          style={{
            marginTop: "10px",
            marginBottom: "0px",
            marginLeft: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>

            <Tooltip arrow title={"Search for Computer Systems"}>
              <TextField
                name="q"
                //SearchforComputerSystems message
                placeholder={Resource.get("Search for Computer Systems")}
                value={this.state.deviceSearchQuery}
                onChange={this.handleSearchTextChange.bind(this)}
                style={{ width: "300px" }}
                sx={{
   input: {

    "&::placeholder": {  // <----- Add this.
      color: 'black !important',
      opacity:0.8

    },
   }}}
                InputProps={{endAdornment:(<FilterIcon className="addDevice__filterBox--icon" />)}}
              />
            </Tooltip>
          </div>

          {noOfDevices > 0 ? (
            <div>
              <div
                id="matching-devices-label"
                className="section-header"
                style={{
                  display: "inline-block",
                }}
              >
                {matchingDevicesLabel}
              </div>

              <div
                id="matching-devices-count-label"
                className="matching-devices-count-header"
              >
                {countLabel}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* {this.state.dataloadedFromCriteria ? ( */}
        <div
          id="matching-devices-ag-grid"
          ref={(element) => {
            this.gridContainer = element;
          }}
          className="ag-theme-balham"
          style={{
            marginTop: "8px",
            marginLeft: "16px",
            height: "calc(100vh - 498px)",
          }}
        >
          <Grid
            rowData={this.state.rowData}
            onGridReady={this.onGridReady}
            quickFilterText={this.state.deviceSearchQuery}
            rowHeight={32}
            headerHeight={32}
            suppressCellFocus={true}
            overlayNoRowsTemplate={overlayMessageTemplate}
          ></Grid>
        </div>
        {/* ) : (
                            ""
                          )} */}
      </div>
    );
  }
}
export default MatchingDevices;
