/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  TextField, DialogContent, Dialog,
  DialogActions,
  DialogTitle, Typography,
  Divider,
  DialogContentText,
  Button,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Switch
} from "@mineral/core";
import { ThreeBars } from "@mineral/icons";
import CloseIcon from "@material-ui/icons/Close";
import "./QosChartToolbar.less";
import {
  Add,
  Edit as EditIcon
} from "@material-ui/icons";
import Resource from "./Resource";
//DateTimePicker - mui
import CustomSnackbar from '../../../../../../common/CustomSnackbar';

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DateTimePicker from "@mui/lab/DateTimePicker";


import { MineralDateTimePicker } from '../../../../../../common/datepickers/MineralDateTimePicker';

import HelpingText from '../../../../../../common/HelpingText';
import DatePickerIcon from '../../../../../../icons/DatePickerIcon';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import X2JS from "xmljson-converter";
import { PRD } from "../../../../../../../api/performanceReports/prd";
import {
  setAggregationLevel,
  setReportTitle,
  setTimeFrameAction,
  clearQosSelectionData,
  updateSavedReport,
  setStatusMessage,
  setIsInterpolation,
} from "../../../../../../../api/performanceReports/actions";
import QosImportChartDialog from "./QosImportChartDialog";
import QosExportPdfDialog from "./QosExportPdfDialog";
//Snack bar
import Snackbar from "@mui/material/Snackbar";
import {HelpIcon} from "../../../../../../ui-components/uim-components";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

export const timeFrameList = [
  "Last Hour",
  "Last Day",
  "Last Week",
  "Last Month",
  "Custom",
];
export const aggregationOptions = [
  "Automatic",
  "None",
  "1 minute",
  "5 minutes",
  "15 minutes",
  "30 minutes",
  "1 hour",
  "2 hours",
  "4 hours",
  "8 hours",
  "12 hours",
  "1 Day",
];
const QosChartToolbar = (props) => {
  const timeFrameRef = React.useRef();
  const qosChartTitleRef = React.useRef();
  const [openClearConfirmation, setOpenClearConfirmation] = React.useState(false)
  const [showClearSuccess, setShowClearSuccess] = React.useState(false)
  const [openedSnackbar, setOpenedSnackbar] = useState(false);
  const [isInterpolate,setIsInterpolate] = useState(false);

  const dispatch = useDispatch();
  const chartData = useSelector((state) => state.performanceReports.chartData);
  const aggLevel = useSelector(
    (state) => state.performanceReports.chartData.State.aggLevel
  );
  const isInterpolation = useSelector(
    (state) => state.performanceReports.chartData.State.isInterpolation
  );
  const timeFrame = useSelector((state) =>
    state.performanceReports.chartData.State.timeFrame
      ? state.performanceReports.chartData.State.timeFrame
      : "Last Hour"
  );
  const chartTitle = useSelector(
    (state) => state.performanceReports.chartData.State.title
  );
  const [currentTitle,setCurrentTitle]= useState(chartTitle);
  const isSaved = useSelector((state) => state.performanceReports.saved);
  const startDate = useSelector((state) =>
    moment(+state.performanceReports.chartData.State.TimeStart).toDate()
  );
  const endDate = useSelector((state) =>
    moment(+state.performanceReports.chartData.State.TimeStop).toDate()
  );
  const snackMessage = useSelector((state) => state.performanceReports.message);
  const prs = useSelector((state) => state.performanceReports);

  // const [startDate, setStartDate] = React.useState(
  // 	new Date(moment().subtract(1, "hours"))
  // );
  // const [endDate, setEndDate] = React.useState(new Date());
  const [showCustomDate, setShowCustomDate] = React.useState(false);
  const [saveTriggered, setSaveTriggered] = React.useState(false);
  const [isImportDialogOpen, setIsImportDialogOpen] = React.useState(false);
  const [typeDialog, setTypeDialog] = React.useState("import");
  const [isExportDialogOpen, setIsExportDialogOpen] = React.useState(false);
  const [editReportTitle, setEditReportTitle] = React.useState(false);
  //Snack bar
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openSnackBarInterpolate, setOpenSnackBarInterpolate] = useState(false);

  const [startDateError, setStartDateError] = useState(null);

  const [endDateError, setEndDateError] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [qosStartDate, setQosStartDate] = useState(new Date().setHours(new Date().getHours() - 1));
  const [qosEndDate, setQosEndDate] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [isReportTitleChanged, setIsReportTitleChanged] = useState(false);
  const [reportOldName, setReportOldName] = useState("");
  const [isSaveDone,setIsSaveDone]= useState(true);
  const interpolationHelpText = Resource.get("Provides linear interpolation in the QOS series based on the actual samples collected by the probe."); //interpolation help text
  const AggIntervalHelpText = Resource.get("Automatic sets the aggregation interval as: \n1 minute for a time frame <= 1 hour \n5 minutes for a time frame <= 1 day \n30 minutes for a time frame <= 1 week \n2 hours for a time frame over a week"); //Aggregation Interval help text

  const [customSnackbarMessage, setCustomSnackbarMessage] = useState("");
  const [ischeckedrefresh, setIscheckedrefresh]= useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [statdilplayseconds, setStatdilplayseconds] = useState(0);
  const [isshowcount, setIsshowcount] = useState(false);
  const switchClickHandle = (e) => {
    setIscheckedrefresh(e.target.checked)
  }

  const [displayDataLoadedtime, setDisplayDataLoadedtime] = useState("");
  const [autoRefreshInterval, setAutoRefreshInterval] = useState(0);

  let timerID;

  useEffect(async()=>{
    try {
        const resolvedData = await PRD.getChartRefreshInterval();
        setAutoRefreshInterval(resolvedData.data);
    } catch (error) {
      console.log(error);
    }
  },[])

  useEffect(() => {
    updateRefreshTime();
    if (isSaved && ischeckedrefresh) {
      timerID = setInterval(() => {
        refreshCharts();
      }, autoRefreshInterval)
    }
    return () => {
      clearInterval(timerID);
    }

  }, [timeFrame, aggLevel, isSaved, ischeckedrefresh, autoRefreshInterval])

  const refreshCharts = () => {
    if(timeFrame!=="Custom"){
    dispatch(
      setTimeFrameAction(timeFrame, {
        startDate: moment(startDate).valueOf(),
        endDate: moment(endDate).valueOf(),
      })
    );
    }
    updateRefreshTime();
  }

  useEffect(() => {
    // if (!props.isDetailsOpen) {
      let myInterval;  
    if(timeFrame!=='Custom'){  
     myInterval = setInterval(() => {
      setStatdilplayseconds(statdilplayseconds + 1);
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (parseInt(seconds) === 0) {
        if (parseInt(minutes) === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    }
    return () => {
      clearInterval(myInterval);
    };
  });

  const updateRefreshTime = () => {
    const currentdate = new Date();
    let min;
    let sec;
    let hour;
    let ampm = currentdate.getHours() >= 12 ? "PM" : "AM";
    if (currentdate.getMinutes() < 10) min = "0" + currentdate.getMinutes();
    else min = currentdate.getMinutes();

    if (currentdate.getSeconds() < 10) sec = "0" + currentdate.getSeconds();
    else sec = currentdate.getSeconds();

    if (currentdate.getHours() < 10) hour = "0" + currentdate.getHours();
    else hour = currentdate.getHours();
    setDisplayDataLoadedtime(hour + ":" + min + ":" + sec);

    //clearInterval(myInterval);
    var minutes = Math.floor(autoRefreshInterval / 60000);
    var seconds = ((autoRefreshInterval % 60000) / 1000).toFixed(0);
    setSeconds(seconds);
    setMinutes(minutes);
    setStatdilplayseconds(0);
  }
  useEffect(() => {
    setReportOldName(chartTitle);
    setCurrentTitle(chartTitle);
  }, [chartTitle])

  const handleSelectClose = () => {
    setOpen(false);
  };

  const handleSelectOpen = () => {
    setOpen(true);
  };

  const truncateString = (string, limit) => {
    if (string.length > limit) {
      return string.substring(0, limit) + "...";
    } else {
      return string;
    }
  };

  const onSaveReport = () => {
    dispatch(updateSavedReport(true));
    setSaveTriggered(true);
    setOpenSnackBar(true);
    dispatch(setStatusMessage(prs.message));
    setCustomSnackbarMessage("Report saved sucessfully")
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
  //Close Snack Bar

  useEffect(() => {
    if (saveTriggered) {
      (async () => {
        var options = {
          fullTagEmptyElement: true,
          compact: true,
          ignoreComment: true,
          spaces: 0,
          ignoreCdata: true,
          ignoreDoctype: true,
          ignoreDeclaration: true,
        };
        var x2js = new X2JS({ skipEmptyTextNodesForObj: false });
        var result = x2js.json2xml_str(chartData);
        //var result = js2xml(chartData, options);
        var encodedString = Buffer.from(result).toString("base64");
        const pathData = chartData.State.path.includes("/")?chartData.State.path.substring(0, chartData.State.path.lastIndexOf('/')):undefined;
        var currentPath = pathData?(pathData+"/"+currentTitle):currentTitle;
        var oldReportPath = chartData.State.path;
        if (chartData.State.path !== currentPath) {
          const resolvedData = await PRD.listPrdReports();
          const dupReport = resolvedData.data.filter((item) => item.path === currentPath);
          if (dupReport?.length > 0) {
            setIsReportTitleChanged(false);
            dispatch(setReportTitle(reportOldName));
            setOpenedSnackbar(true);
          }
          else {
            setIsReportTitleChanged(true);
            chartData.State.helpPath=currentPath;
            chartData.State.path=currentPath;
            chartData.State.title=currentTitle;
          }
        }
        //console.log("chartData",chartData);
        result = x2js.json2xml_str(chartData);
        encodedString = Buffer.from(result).toString("base64");
        let requestBody = {
          report: currentPath,
          base64EncodedReportStructure: encodedString,
          visibility: chartData.State.visibility.type,
          visibilityIdentifier:
            chartData.State.visibility.type == "account"
              ? chartData.State.visibility.owner
              : 0,
          reportOldName: oldReportPath,
          isNameModified: isReportTitleChanged,
        };
        console.log("requestBody", requestBody);
        if (process.env.NODE_ENV != "development") {
          const resolvedData = await PRD.savePrdReport(requestBody);
          dispatch(setReportTitle(currentTitle));
          dispatch(setStatusMessage(resolvedData.data));
        }
        setSaveTriggered(false);
      })();
    }
  }, [saveTriggered]);

  // useEffect(()=>{
  //     console.log(startDate)
  //     console.log(startTime)
  //     dispatch(setTimeFrameAction(timeFrameList[4],{startDate,endDate}))
  // },[startDate,endDate])

  async function createAndOpenFile() {
    var options = {
      fullTagEmptyElement: true,
      compact: true,
      ignoreComment: true,
      spaces: 0,
      ignoreCdata: true,
      ignoreDoctype: true,
      ignoreDeclaration: true,
    };
    // var result = js2xml(chartData, options);
    var x2js = new X2JS({ skipEmptyTextNodesForObj: false });
    var result = x2js.json2xml_str(chartData);

    function download(filename, text) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

    download(`${chartData.State.title}.xml`, result);
  }

  //Snck Bar Action
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      ></IconButton>
    </React.Fragment>
  );
  // Close snackBar action

  return (
    <div className="chart-controls-toolbar">
      <div className="chart-toolbar-title-bar">
        <div className="toolbar-left-content">
          <Tooltip arrow title="Edit report title">
            <IconButton style={{ marginRight: editReportTitle ? '8px' : '0px' }}
              // className="chart-title-edit-icon"
              aria-label="Edit report title"
              //style={{ padding: 0 }}
              onClick={() => {
                setEditReportTitle(true);
                setTimeout(() => {
                  qosChartTitleRef.current.firstChild?.firstChild?.focus();
                });
              }}
            >
              <EditIcon role="img" title="Edit" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title={currentTitle}>
            {editReportTitle ? (
              <TextField
                ref={qosChartTitleRef}
                onChange={(event) => {
                  setIsReportTitleChanged(true);
                  !event.target.value.includes("/") && setCurrentTitle(event.target.value)
                }}
                onBlur={(event) => {
                  setIsReportTitleChanged(true);
                  setEditReportTitle(false);
                  !event.target.value.includes("/") && setCurrentTitle(event.target.value);
                }}
                size="small"
                value={
                  editReportTitle ? currentTitle : truncateString(currentTitle, 60)
                }
                error={chartTitle.includes("/") ? "/ is not allowed" : null}
                //classes={{ root: "chart-title" }}
                inputProps={{ "aria-label": "PRD title" }}
              />
            ) : (
              <h2
                style={{ margin: 0, marginLeft: "10px", fontSize: "16px", wordBreak: "break-word", width: '450px' }}
                onClick={() => {
                  setEditReportTitle(true);
                  setTimeout(() => {
                    qosChartTitleRef.current.firstChild?.firstChild?.focus();
                  });
                }}
              >
                {editReportTitle ? currentTitle : truncateString(currentTitle, 40)}
              </h2>
            )}
          </Tooltip>
        </div>
        <div>
        <CustomSnackbar
            open={openedSnackbar}
            autoHideDuration={5000}
            close={() => setOpenedSnackbar(false)}
            message="A report with the same name already exists."
            severity="error"
          />
        </div>
        <div className="toolbar-right-content">
          <div className="toolbar-left-action-content">

            <Tooltip arrow title="Add Chart">
              <Button
                variant="contained"
                //OK
                children={"Add Chart"}
                style={{ width: "125px", marginRight: "8px", marginTop: "20px"}}
                color="primary"
                classes={{ containedPrimary: "add-qos-chart-button" }}
                onClick={() => {
                  props.addChart();
                }}
                startIcon={<Add />}
              />
            </Tooltip>

            <FormControl className="timeFrame-dropdown">
              <InputLabel
                id="tame-frame-select-label" htmlFor="time-range-select"

              >
                Time Frame
              </InputLabel>
              <Select role="combobox" size="small"
                labelId="tame-frame-select-label"
                ref={timeFrameRef}
                id="time-range-select"
                open={open}
                onClose={handleSelectClose}
                onOpen={handleSelectOpen}

                aria-label="Time Frame"
                aria-labelledby="tame-frame-select-label"

                inputProps={{
                  'aria-label': " Time Frame",
                  role: 'combobox',
                }}
                MenuProps={{
                  MenuListProps: {
                    'aria-label': `Time Frame options list`,
                    'aria-labelledby': "tame-frame-select-label",

                    title: "Time Frame"
                  }
                }}
                value={timeFrame ? timeFrame : "Last Hour"}
                onChange={(event) => {
                  if (event.target.value == timeFrameList[4]) {
                    // dispatch(
                    //   setTimeFrameAction(event.target.value, {
                    //     startDate: moment(startDate).valueOf(),
                    //     endDate: moment(endDate).valueOf(),
                    //   })
                    // );
                    setShowCustomDate(true);
                  } else {
                    handleSelectClose();
                    dispatch(setTimeFrameAction(event.target.value, ""));
                    setShowCustomDate(false);
                    // setEndDate(new Date());
                    // if (event.target.value == timeFrameList[0]) {
                    // 	setStartDate(new Date(moment().subtract(1, "hours")));
                    // } else if (event.target.value == timeFrameList[1]) {
                    // 	setStartDate(new Date(moment().subtract(24, "hours")));
                    // } else if (event.target.value == timeFrameList[2]) {
                    // 	setStartDate(new Date(moment().subtract(168, "hours")));
                    // } else if (event.target.value == timeFrameList[3]) {
                    // 	setStartDate(new Date(moment().subtract(720, "hours")));
                    // }
                    if (event.target.value == timeFrameList[0] && aggregationOptions.slice(6).includes(aggLevel)) {
                      dispatch(setAggregationLevel("Automatic"));
                    } else if (event.target.value == timeFrameList[1] && aggLevel == "1 Day") {
                      dispatch(setAggregationLevel("Automatic"));
                    } else if (event.target.value == timeFrameList[2] && aggregationOptions.slice(2,4).includes(aggLevel)) {
                      dispatch(setAggregationLevel("Automatic"));
                    } else if (event.target.value == timeFrameList[3] && aggregationOptions.slice(2,4).includes(aggLevel)) {
                      dispatch(setAggregationLevel("Automatic"));
                    } else{
                      dispatch(setAggregationLevel(aggLevel));
                    }
                  }
                  //dispatch(setAggregationLevel(aggLevel));
                  //dispatch(setAggregationLevel("Automatic"));
                }}
                disabled={props.isloading}
              >
                {timeFrameList.map((source, index) => (
                  (source == "Last Month") ? (
                    <MenuItem
                      classes={{ root: "timeFrame-menu-item" }}
                      key={`${source}-${index}-menuitem`}
                      value={source}
                      divider={true}
                    >
                      {source}
                    </MenuItem>)
                    :
                    ((source == "Custom") ?
                      (<MenuItem
                        classes={{ root: "timeFrame-menu-item" }}
                        key={`${source}-${index}-menuitem`}
                        value={source}
                        style={{ pointerEvents: "none" }}
                      >
                        {source}
                      </MenuItem>) : (<MenuItem
                        classes={{ root: "timeFrame-menu-item" }}
                        key={`${source}-${index}-menuitem`}
                        value={source}
                      >
                        {source}
                      </MenuItem>)
                    )
                ))}

                <div style={{ display: "grid", margin: "1px 10px 10px 10px" }}>
                  {(
                    <>
                      <div className="qos-performance-date-picker">
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column nowrap",
                            marginRight: "8px",
                          }}
                        >
                          <MineralDateTimePicker
                            value={startDate?startDate:qosStartDate}
                            onValueChange={(event) => {
                              if (moment(event).isAfter(moment(qosEndDate)))
                                setStartDateError('Start date cannot be after end date')
                              else {
                                setStartDateError(null)
                                setQosStartDate(moment(event).toDate());

                              }
                            }}


                            variant="inline"
                            //maxDateTime={endDate}
                            keyboardIcon={<DatePickerIcon />}
                            label="Start Date"
                            ampm={false}
                          />
                          <HelpingText
                            hint={null}
                            errorMessage={startDateError}
                            disabled={!startDateError}
                          />


                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column nowrap",
                            marginRight: "8px",
                          }}
                        >
                          <MineralDateTimePicker
                            value={endDate?endDate:qosEndDate}
                            onValueChange={(event) => {
                              if (moment(event).isBefore(moment(qosStartDate).add(1, "minute")))
                                setEndDateError('End date cannot be before start date')
                              if (moment(event).isAfter())
                                setEndDateError('End date cannot be in future')
                              else {
                                setEndDateError(null)
                                setQosEndDate(moment(event).toDate());
                              }
                            }}


                            variant="inline"
                            // minDateTime={new Date(moment(startDate).add(1, "minute"))}
                            // maxDateTime={new Date()}
                            keyboardIcon={<Tooltip title="Change date"><DatePickerIcon /></Tooltip>}
                            label="End Date"
                            ampm={false}
                          />
                          <HelpingText
                            hint={null}
                            errorMessage={endDateError}
                            disabled={!endDateError}
                          />

                        </div>

                      </div>
                    </>
                  )}
                </div>
                <Divider />
                <div>
                  <Button
                    variant="text"
                    children="Apply"
                    style={{ float: "right" }}
                    disabled={
                      startDateError || endDateError || qosStartDate == null || qosEndDate == null || !moment(qosStartDate).isBefore(endDate)
                    }
                    onClick={(event) => {
                      handleSelectClose();
                      dispatch(
                        setTimeFrameAction("Custom", {
                          startDate: moment(qosStartDate).valueOf(),
                          endDate: moment(qosEndDate).valueOf(),
                        })
                      );
                    let QosChartTimeDiff = 3600;
                    let startTime = moment(qosStartDate).valueOf();
                    let stopTime = moment(qosEndDate).valueOf();
                    if(stopTime>startTime){
                      QosChartTimeDiff = moment(stopTime).diff(moment(startTime), "seconds");
                    }
                    if (QosChartTimeDiff <= 3600 && aggregationOptions.slice(6).includes(aggLevel)) {
                      dispatch(setAggregationLevel("Automatic"));
                    } else if (QosChartTimeDiff <= 86400 && aggLevel == "1 Day") {
                      dispatch(setAggregationLevel("Automatic"));
                    } else if (QosChartTimeDiff >= 604800 && aggregationOptions.slice(2,4).includes(aggLevel)) {
                      dispatch(setAggregationLevel("Automatic"));
                    } else{
                      dispatch(setAggregationLevel(aggLevel));
                    }
                    }}
                  />
                </div>
              </Select>

            </FormControl>

            <FormControl className="timeFrame-dropdown">
              <InputLabel id="agg-level-select-label" >
                Aggregation Interval
                <div
                  className="aggIntHelpToolTip"
                  style={{ display: "inline-block" }}
                >
                <span tabIndex={0} style={{ cursor: "pointer", marginLeft: "9px" }}>
                <span
                  className="aggInttooltiptext"
                  style={{ marginTop: "1.5rem",marginRight: "2rem" }}
                >
                  <div style={{ float: "left", paddingLeft: "16px", textAlign: "left", whiteSpace: "pre-wrap" }}>
                    <span
                      style={{ color: "#333333", fontFamily: "Roboto", fontSize: "12px" }}
                    >
                      {AggIntervalHelpText}
                    </span>
                  </div>
                </span>
                <HelpIcon className="helpIcon" title="Help" style={{ transform: 'translateY(-4px)' }} />
              </span>
              </div>
              </InputLabel>
              <Select role="combobox" size="small"
                labelId="agg-level-select-label"
                id="agg-level-select"
                aria-label="Aggregation Interval"
                aria-labelledby="agg-level-select-label"

                inputProps={{
                  'aria-label': " Aggregation Interval",
                  role: 'combobox',
                }}
                MenuProps={{
                  MenuListProps: {
                    'aria-label': `Aggregation Interval options list`,
                    'aria-labelledby': "agg-level-select-label",

                    title: "Aggregation Interval"
                  }
                }}

                value={aggLevel ? aggLevel : "Automatic"}
                onChange={(event) => {
                  dispatch(setAggregationLevel(event.target.value));
                  dispatch(
                    setTimeFrameAction(timeFrame, {
                      startDate: moment(startDate).valueOf(),
                      endDate: moment(endDate).valueOf(),
                    })
                  );
                }}
              >
                {aggregationOptions.map((source, index) => (
                  <MenuItem
                    key={`${source}-${index}-agglevel`}
                    value={source}
                    disabled={(timeFrame==="Last Month" && (index===2 || index===3)) && true}
                  >
                    {source}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="interpolate-dropdown">
              <InputLabel id="interpolate-select-label" >
                Interpolate
                <div
                  className="helpToolTip"
                  style={{ display: "inline-block" }}
                >
                <span tabIndex={0} style={{ cursor: "pointer", marginLeft: "9px" }}>
                <span
                  className="tooltiptext"
                  style={{ marginTop: "1.5rem",marginRight: "2rem" }}
                >
                  <div style={{ float: "left", paddingLeft: "16px", textAlign: "left", whiteSpace: "pre-wrap" }}>
                    <span
                      style={{ color: "#333333", fontFamily: "Roboto", fontSize: "12px" }}
                    >
                      {interpolationHelpText}
                    </span>
                  </div>
                </span>
                <HelpIcon className="helpIcon" title="Help" style={{ transform: 'translateY(-4px)' }} />
              </span>
              </div>
              </InputLabel>
              <Select role="combobox" size="small"
                labelId="interpolate-select-label"
                id="interpolate-select"
                aria-label="Interpolate"
                aria-labelledby="interpolate-select-label"

                inputProps={{
                  'aria-label': " Interpolate",
                  role: 'combobox',
                }}
                MenuProps={{
                  MenuListProps: {
                    'aria-label': `Interpolate options list`,
                    'aria-labelledby': "interpolate-select-label",

                    title: "Interpolate"
                  }
                }}
                value={isInterpolation?isInterpolation:false}
                onChange={(event) => {
                      setIsInterpolate(event.target.value);
                      setOpenSnackBarInterpolate(event.target.value);
                      dispatch(setIsInterpolation(event.target.value));
                }}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
            {isSaved && timeFrame!=="Custom" && <FormControl component="fieldset" style={{width: "120px", margin: 0, paddingTop:"10px"}}>
              <FormControlLabel
                control={<Switch size="small" checked={ischeckedrefresh} onChange={switchClickHandle} />}
                label={<Typography style={{fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: '24px',
                margin: '0px;',
                padding: '0px',
                color: "#58606e",
                alignContent: "center",
                }}>Auto-Refresh</Typography>}
                value="Auto-Refresh"
                labelPlacement="start"
                sx={{padding: 0, margin: 0}}
                className="autoRefresh-span"
              />
              <FormHelperText style={{fontStyle:"italic"}}>
              {ischeckedrefresh && <>
                 Refresh In:{" "}
                {minutes < 10 ? `0${minutes}` : minutes} min{" "}
                {seconds < 10 ? `0${seconds}` : seconds} sec
              </>}
              {!ischeckedrefresh && <>Updated At: {displayDataLoadedtime}</>}
              </FormHelperText>
            </FormControl>}
            {!ischeckedrefresh && isSaved && timeFrame!=="Custom" && <FormControl>
            <Button
                variant="contained"
                //OK
                children={"Refresh"}
                style={{ width: "80px", marginRight: "0px", marginTop: "20px"}}
                color="primary"
                classes={{ containedPrimary: "add-qos-chart-button" }}
                onClick={() => {
                  refreshCharts();
                }}
              />
            </FormControl>}
          </div>
          <div className="toolbar-right-action-content">
            {/*
                <Tooltip title="Undo"><IconButton classes={{root:"toolbar-icon"}}>  <UndoIcon className="toolbar-icons-color"/></IconButton></Tooltip>
                <Tooltip title="Redo"><IconButton classes={{root:"toolbar-icon"}}> <RedoIcon className="toolbar-icons-color"/></IconButton></Tooltip>
                <IconButton classes={{root:"toolbar-icon"}} onClick={onSaveReport}> <SaveIcon className="toolbar-icons-color" /></IconButton>
            */}
            <div
              style={{

              }}
            ><Tooltip title="More actions">

                <IconButton
                  aria-controls="menuAction"
                  aria-haspopup="true"
                  onClick={(event) => {
                    setMenuAnchorEl(event.currentTarget)
                  }}

                  aria-label="More Actions"
                >
                  <ThreeBars style={{ color: "black" }} />
                </IconButton>
              </Tooltip>
              <Menu
                id="menuAction"
                anchorEl={menuAnchorEl}
                keepMounted
                onClose={() => setMenuAnchorEl(null)}
                open={menuAnchorEl != null}
                PaperProps={{
                  style: {
                    width: "105px",
                    padding: "16px",
                    display: "table-cell",
                  },
                }}
              >
                <MenuItem
                  id={"import"}
                  children={
                    //Import
                    Resource.get("Open/Import")
                  }
                  disabled={false}
                  onClick={() => {
                    setMenuAnchorEl(null)
                    setTypeDialog("import");
                    setIsImportDialogOpen(true);
                    setIsSaveDone(true);
                  }}
                />
                <MenuItem
                  id={"export"}
                  children={
                    //export
                    Resource.get("Export")
                  }
                  disabled={false}
                  onClick={() => {
                    setMenuAnchorEl(null)
                    setIsExportDialogOpen(true)
                  }} />
                <MenuItem id={"save"}
                  children={
                    //save
                    Resource.get("Save")
                  }
                  disabled={false}
                  onClick={(event) => {
                    setMenuAnchorEl(null)
                    if (isSaved) {
                      onSaveReport();
                    } else {
                      setTypeDialog("save");
                      setIsImportDialogOpen(true);
                    }
                  }} />
                <MenuItem id={"saveAs"}
                  children={
                    //save as
                    Resource.get("Save As")
                  }
                  disabled={!isSaved}
                  onClick={(event) => {
                    setMenuAnchorEl(null)
                    setTypeDialog("save");
                    setIsImportDialogOpen(true);
                  }} />
                <MenuItem id={"clear"}
                  children={
                    //clear
                    Resource.get("New")
                  }
                  disabled={false}
                  onClick={(event) => {
                    setMenuAnchorEl(null)
                    setOpenClearConfirmation(true)
                  }} />
              </Menu>
            </div>

            <CustomSnackbar
              open={openSnackBar}
              autoHideDuration={5000}
              message={snackMessage ? snackMessage : customSnackbarMessage}
              close={() => setOpenSnackBar(false)}
            />
            <Snackbar
              open={openSnackBarInterpolate}
              autoHideDuration={5000}
              message={"Interpolating missing series values for the selected QOS/Target."}
              onClose={() => setOpenSnackBarInterpolate(false)}
            />

            {/*<Tooltip arrow title="Add Chart">
              <Button
                variant="contained"
                //OK
                children={"Add Chart"}
                style={{ width: "125px", marginLeft: "8px" }}
                color="primary"
                classes={{ containedPrimary: "add-qos-chart-button" }}
                onClick={() => {
                  props.addChart();
                }}
                startIcon={<Add />}
              />
              </Tooltip>*/}
            {/* <Tooltip arrow title="Export">
            <Button
              variant="outlined"
              children={"Export"}
              color="primary"
              classes={{ outlinedPrimary: "import-qos-chart-button" }}
              onClick={() => setIsExportDialogOpen(true)}
            />
          </Tooltip>
          <Tooltip arrow title="Import">
            <Button
              variant="outlined"
              //OK
              children={"Import"}
              color="primary"
              classes={{ outlinedPrimary: "import-qos-chart-button" }}
              onClick={() => {
                setTypeDialog("import");
                setIsImportDialogOpen(true);
              }}
            />
          </Tooltip> */}
          </div>

          <QosImportChartDialog
            open={isImportDialogOpen}
            type={typeDialog}
            isSaved={isSaved}
            chartData={chartData}
            handleImportDialogClose={() => {
              setIsImportDialogOpen(false);
            }}
          />
          <QosExportPdfDialog
            open={isExportDialogOpen}
            xml={createAndOpenFile}
            handleExportDialogClose={() => setIsExportDialogOpen(false)}
          />
          <Dialog
            open={openClearConfirmation}
            onClose={() => setOpenClearConfirmation(false)}
            aria-labelledby="alert-chart-clear-dialog-title"
            aria-describedby="alert-chart-clear-dialog-description"
          >
            <DialogTitle component="h1">

              <Typography

                variant="h4"
                component="div"
              >
                {"New report"}
              </Typography>


            </DialogTitle>
            <div style={{ height: '0px' }}>
              <Tooltip title="Close">
                <IconButton style={{ top: '-50px', float: 'right', marginRight: '8px' }}
                  aria-label="Close"
                  autoFocus
                  onClick={() => setOpenClearConfirmation(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
            <DialogContent>
              <DialogContentText id="alert-chart-clear-dialog-description">
                <pre>
                  {`Do you want to load a new report?`}
                  {`\n`}
                  {`This will clear the existing report: ${chartTitle}`}
                </pre>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                onClick={() => setOpenClearConfirmation(false)}
              >
                Cancel
              </Button>
              <Button variant="contained"
                onClick={() => {
                  setOpenClearConfirmation(false);
                  dispatch(clearQosSelectionData());
                  setShowClearSuccess(true);

                }}
              >
                Agree
              </Button>
            </DialogActions>
          </Dialog>
          <CustomSnackbar
            open={showClearSuccess}
            autoHideDuration={5000}
            message={"Successfully cleared report details"}
            close={() => setShowClearSuccess(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default QosChartToolbar;
